import React, { useEffect, useState } from "react";
import MyAccountComponent from "../Component/MyAccountComponent";
import MyAccountComponent2 from "../Component/MyAccountComponent2";
import MyAccountComponent3 from "../Component/MyAccountComponent3";
import LogOut from "../Component/LogOut";
import Account from "../API/API_SERVISES/Account";
import { useNavigate } from "react-router-dom";
import Loader from "../Component/Loader";

const MyAccountPage = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [box1, setBox1] = useState(true);
  const [box2, setBox2] = useState(false);
  const [box3, setBox3] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  const openbox1 = () => {
    setBox1(true);
    setBox2(false);
    setBox3(false);
  };
  const openbox2 = () => {
    setBox1(false);
    setBox2(true);
    setBox3(false);
  };
  const openbox3 = () => {
    setBox1(false);
    setBox2(false);
    setBox3(true);
  };


  const navigate = useNavigate();

  const logoutBtn = () => {
    setLogoutModal(!logoutModal);
  }

  const logout = () => {
    localStorage.removeItem('accountLogin');
    navigate("/");
  };

  const [accountData, setAccountData] = useState([]);

  useEffect(() => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    // console.log(loginData.d1Id);
    const fetchAccountData = async () => {
      try {
        var data = await Account.getAccountById(loginData.d1Id);
        // console.log(data.item1[0]);
        if (data) {
          setLoading(false)
          setAccountData(data.item1[0]);
        }
      }
      catch { }
    };

    fetchAccountData()
  }, []);

  return (
    <div className="myaccountpage">
      <div className="myaccountpage-left">
        <div className="product-category-head"> My Account </div>

        <div
          onClick={openbox1}
          style={{ borderBottom: "1px solid rgb(228, 228, 228)" }}
          className="catogory-box"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className="fa-regular fa-user fs-b"
              style={{ marginRight: "10px" }}
            ></i>
            <p className="product-category"> Personal Info </p>
          </div>
          <i className="fa-solid fa-chevron-right fs-b"></i>
        </div>

        <div
          onClick={openbox2}
          style={{ borderBottom: "1px solid rgb(228, 228, 228)" }}
          className="catogory-box"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className="fa-solid fa-location-dot fs-b"
              style={{ marginRight: "10px" }}
            ></i>
            <p className="product-category">Addresses </p>
          </div>
          <i className="fa-solid fa-chevron-right fs-b"></i>
        </div>

        <div
          onClick={openbox3}
          style={{ borderBottom: "1px solid rgb(228, 228, 228)" }}
          className="catogory-box"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className="fa-solid fa-burger fs-b"
              style={{ marginRight: "10px" }}
            ></i>
            <p className="product-category"> My Orders & Feedback </p>
          </div>
          <i className="fa-solid fa-chevron-right fs-b"></i>
        </div>

        <div
          onClick={logoutBtn}
          style={{ borderBottom: "1px solid rgb(228, 228, 228)" }}
          className="catogory-box"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <i
              className="fa-solid fa-arrow-right-from-bracket fs-b"
              style={{ marginRight: "10px" }}
            ></i>
            <p className="product-category">Log Out </p>
          </div>

          {/* <i className="fa-solid fa-chevron-right fs-b"></i> */}
        </div>
      </div>
      <div className="myaccountpage-right">
        {box1 && <MyAccountComponent accountData={accountData} />}
        {box2 && <MyAccountComponent2 accountData={accountData} />}
        {box3 && <MyAccountComponent3 accountData={accountData} />}

        {logoutModal && <LogOut close={logoutBtn} logout={logout} text="You Want To Logout" />}
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default MyAccountPage;
