import React, { useState, useEffect } from 'react';

const ImageSlider = () => {
  // Define an array of image URLs
  const imgUrls = [
    "https://images.pexels.com/photos/4393021/pexels-photo-4393021.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    // "https://images.pexels.com/photos/8969237/pexels-photo-8969237.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/70497/pexels-photo-70497.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/3184183/pexels-photo-3184183.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  ];

  // State to track the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextSlide = () => {
    // Calculate the index of the next image
    const nextIndex = (currentImageIndex + 1) % imgUrls.length;
    setCurrentImageIndex(nextIndex);
  };

  const previousSlide = () => {
    // Calculate the index of the previous image
    const previousIndex =
      currentImageIndex === 0 ? imgUrls.length - 1 : currentImageIndex - 1;
    setCurrentImageIndex(previousIndex);
  };

  useEffect(() => {
    // Create a timer to automatically advance to the next slide every 3 seconds
    const timer = setInterval(() => {
      // nextSlide();
    }, 3000);
  
    // Clear the timer when the component unmounts to prevent memory leaks
    return () => {
      clearInterval(timer);
    };
  }, [currentImageIndex]);

  // Define a CSS style object to set the background image of the div
  const backgroundImageStyle = {
    backgroundImage: `url(${imgUrls[currentImageIndex]})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    // height: '100%',
  };

  return (
    <div className="image-slider" style={backgroundImageStyle}>
      <div className='image-slider-button-box'>
        <button className='image-slider-button' onClick={previousSlide}><i className="fa-solid fa-chevron-left"></i></button>
        <button className='image-slider-button' onClick={nextSlide}><i className="fa-solid fa-chevron-right"></i></button>
      </div>
    </div>
  );
};

export default ImageSlider;
