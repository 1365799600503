import axios from "../axiosConfig";
import moment from 'moment';
//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Order = {
  insertOrder: async (data, order2, order3) => {
    //debugger;
    console.log(data);
    console.log(order2);
    console.log(order3);

    var loginDataString = localStorage.getItem("accountLogin"); // This is a string
    var loginData = JSON.parse(loginDataString); // Now it's an object

    // Now you can safely access d2Id and d1Id properties
    console.log(loginData.d2Id);
    console.log(loginData.d1Id);

    // var loginData = JSON.stringify(localStorage.getItem("accountLogin"))
    // console.log(loginData.d2Id);
    // console.log(loginData.d1Id);

    const coordinatesString = localStorage.getItem('coordinates');
    var latitude = 0
    var longitude = 0
    // const coordinates = JSON.parse(coordinatesString);
    const coordinates = JSON.parse(coordinatesString) === null || JSON.parse(coordinatesString) === "" ? 0 : JSON.parse(coordinatesString);

    if (JSON.parse(coordinatesString) != null || JSON.parse(coordinatesString) != "") {
      latitude = coordinates.latitude === null || coordinates.latitude === "" ? 0 : coordinates.latitude;
      longitude = coordinates.longitude === null || coordinates.longitude === "" ? 0 : coordinates.longitude;
    }

    const processedOrders = order2.map((order) => ({
      godownId: Number(order.godownId),
      d1ItemId: Number(order.d1ItemId),
      d2ItemId: Number(order.d2ItemId),
      qty: Number(order.qty),
      rate: Number(order.rate),
      totalAmt: Number(order.totalAmt),
      kotStatus: "Pending",//order.kotStatus,
      orderDate: order.orderDate,
      orderTime: order.orderTime,
      preferenceNo: Number(order.preferenceNo),
      itemSuggestion: order.itemSuggestion,
      taxableAmount: Number(order.totalAmt),
      cgstPer: Number(order.cgstPer),
      cgstValue: Number(order.cgstValue),
      sgstPer: Number(order.sgstPer),
      sgstValue: Number(order.sgstValue),
      igstPer: Number(order.igstPer),
      igstValue: Number(order.igstValue),
      cessPer: Number(order.cessPer),
      cessValue: Number(order.cessValue),
      totalTaxableAmt: Number(order.totalTaxableAmt),
      isLocal: Boolean(order.isLocal),
      isInclusive: Boolean(order.isInclusive),
      hasComplementryItem: Boolean(order.hasComplementryItem),
      cookStatus: "Pending",//order.cookStatus,
      itemIncentivePoint: Number(order.itemIncentivePoint),
    }));
    console.log(processedOrders);

    const isFromOrderTaker = localStorage.getItem("OrderTaker");
    var OrderDoneFrom = "";
    if (isFromOrderTaker == "Yes")
      OrderDoneFrom = "App";
    else
      OrderDoneFrom = "Web";

    try {
      const apiUrl = `${WebApiUrl}/api/Order/CreateOrder`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          orderSeriesId: Number(data.orderSeriesId),
          orderNo: data.orderNo.toString(),
          autoOrderNo: Number(data.autoOrderNo),
          //serviceTypeId: 396, //Number(data.serviceTypeId),
          serviceTypeId: Number(data.serviceTypeId) || 0,
          orderStatus: data.orderStatus,
          overallOrderStatus: "Pending",//data.overallOrderStatus === null ? "" : data.overallOrderStatus,
          orderDate: data.orderDate,
          orderTime: data.orderTime,
          orderDoneBy: Number(data.orderDoneBy),
          orderDoneOn: data.orderDoneOn,
          orderConfirmedBy: Number(data.orderConfirmedBy),
          orderConfirmedOn: data.orderConfirmedOn,
          orderDoneFrom: OrderDoneFrom, //data.orderDoneFrom,
          d1AccountId: loginData.d1Id,
          d2AccountId: loginData.d2Id,
          billTypeId: Number(data.billTypeId),
          tableGroupId: Number(data.tableGroupId),
          tableId: Number(data.tableId),
          noOfPerson: Number(data.noOfPerson),
          noOfAdult: Number(data.noOfAdult),
          noOfChild: Number(data.noOfChild),
          couponCode: data.couponCode,
          couponCodeAmount: Number(data.couponCodeAmount),
          deliveryCharges: Number(data.deliveryCharges),
          deliveryDate: data.deliveryDate,
          deliveryTimeId: Number(data.deliveryTimeId) || 0,
          deliveryStatus: data.deliveryStatus,
          deliveryBoyId: Number(data.deliveryBoyId),
          deliveryBoyDispatchTime: data.deliveryBoyDispatchTime,
          deliveryBoyTripNo: Number(data.deliveryBoyTripNo),
          deliveryBoyTripIncentive: Number(data.deliveryBoyTripIncentive),
          deliveryReferenceNo: Number(data.deliveryReferenceNo),
          deliveryPreferenceNo: Number(data.deliveryPreferenceNo),
          deliveryTripStatus: data.deliveryTripStatus,
          orderPrintStatus: Number(data.orderPrintStatus),
          totalAmt: Number(data.grandTotalAmt),
          initialOrderTotalAmt: Number(data.grandTotalAmt),
          grandTotalAmt: Number(data.grandTotalAmt),
          balanceAmt: Number(data.grandTotalAmt) - Number(data.paymentAmount),
          paymentStatus: data.paymentStatus,
          paymentAmount: Number(data.paymentAmount),
          returnAmount: Number(data.returnAmount),
          discountAmount: Number(data.discountAmount),
          paymentDoneBy: Number(data.paymentDoneBy),
          paymentDoneOn: data.paymentDoneOn,
          paymentAttemptedCounter: Number(data.paymentAttemptedCounter),
          paymentMode: Number(data.paymentMode),
          paymentBank: data.paymentBank,
          paymentRemarks: data.paymentRemarks,
          remarks: data.remarks,
          orderSuggestion: data.orderSuggestion,
          kotDispatchTargetTime: data.kotDispatchTargetTime,
          //latitude: data.latitude,
          //longitude: data.longitude,
          latitude: latitude || 0,
          longitude: longitude || 0,
          currency: 0,
          orders2: processedOrders,
          orders3: [
            // {
            //   orderStatus: order3.orderStatus,
            //   orderDateTime: order3.orderDateTime,
            // },
          ],
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      debugger;
      console.log("Response:", response.data.result);
      localStorage.setItem("OrderDataToTrack", JSON.stringify(response.data.result));
      return response.data.result;

    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // *************************** Patch update order partially ***************************
  updateOrderPartially: async (data, ord1Id) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/UpdateOrderPartially?ord1Id=${ord1Id}`;
      console.log(apiUrl);
      const response = await axios.patch(apiUrl, data, {
        withCredentials: true,
        credentials: 'include',
      });
      console.log("Response:", response.data);
      return response.data.isSuccess
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again. updateOrderPartially");
      }
    }
  },
  // ************************* Get Serivce Type ***************************
  getServiceType: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetServiceTypes`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      // console.log('Response:', response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Get Bill Series Counter ***************************
  getBillSeriesCounter: async (billId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetBillSeriesCounter?billSeriesId=${billId}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Get All ***************************
  getOrderList: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderList`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Get All Order List Filter ***************************
  getOrderListFilter: async (filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: Number(data.compareValue)
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderList`;
      const response = await axios.post(
        apiUrl, {
        filter: filteredData,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        pageNumber: 0,
        pageSize: 0
      },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Get table order from table and current date ***************************
  getOrderByTable: async (filterData) => {
    const filteredData = filterData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue,
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderList`;
      const response = await axios.post(
        apiUrl,
        {
          filter: filteredData,
          order: [
            {
              propertyName: "string",
              ascending: true,
            },
          ],
          pageNumber: 0,
          pageSize: 0,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Get table order from table and current date ***************************
  getOrderByTable: async (filterData) => {
    const filteredData = filterData.map((data) => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue,
    }));
    try {
      var WebApiUrl = express_server_url;
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderList`;
      const response = await axios.post(
        apiUrl,
        {
          filter: filteredData,
          order: [
            {
              propertyName: "string",
              ascending: true,
            },
          ],
          pageNumber: 0,
          pageSize: 0,
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // *************************** Put ***************************
  updateOrder: async (data, order2, order3, order1Id, totalamountmain) => {
    debugger;
    const initialDate = data.deliveryDate;
    const formattedDate = moment(initialDate, "DD-MM-YYYY").format('YYYY-MM-DD');
    console.log(data);
    console.log(order2);
    console.log(order3);

    const processedOrders = order2.map(order => ({
      godownId: Number(order.godownId) || 0,
      d1ItemId: Number(order.d1ItemId) || 0,
      d2ItemId: Number(order.d2ItemId) || 0,
      qty: Number(order.qty) || 0,
      rate: Number(order.rate) || 0,
      totalAmt: Number(order.totalAmt) || 0,
      // totalAmt: Number(order.totalAmt) || 0,
      kotStatus: "Pending",//order.kotStatus === null ? "" : order.kotStatus,
      preferenceNo: Number(order.preferenceNo) || 0,
      orderDate: order.orderDate === null ? "" : order.orderDate,
      orderTime: order.orderTime === null ? "" : order.orderTime,
      itemSuggestion: order.itemSuggestion === null ? "" : order.itemSuggestion,
      itemRunningOrderCounter: Number(order.itemRunningOrderCounter),
      orderRunningQty: Number(order.orderRunningQty),
      itemRunningQty: Number(order.itemRunningQty), // Number(data.itemRunningQty
      taxableAmount: Number(order.taxableAmount) || 0,
      cgstPer: Number(order.cgstPer) || 0,
      cgstValue: Number(order.cgstValue) || 0,
      sgstPer: Number(order.sgstPer) || 0,
      sgstValue: Number(order.sgstValue) || 0,
      igstPer: Number(order.igstPer) || 0,
      igstValue: Number(order.igstValue) || 0,
      cessPer: Number(order.cessPer) || 0,
      cessValue: Number(order.cessValue) || 0,
      totalTaxableAmt: Number(order.totalTaxableAmt) || 0,
      isLocal: Boolean(order.isLocal),
      isInclusive: Boolean(order.isInclusive),
      hasComplementryItem: Boolean(order.hasComplementryItem),
      cookStatus: "Pending",//order.cookStatus === null ? "" : order.cookStatus,
      itemIncentivePoint: Number(order.itemIncentivePoint) || 0,
      isActive: true,
    }));
    console.log(processedOrders);
    try {
      const apiUrl = `${WebApiUrl}/api/Order/UpdateOrder?orderId=${order1Id}`;
      console.log(apiUrl);
      const response = await axios.put(
        apiUrl,
        {
          ord1Id: Number(order1Id) || 0,
          serviceTypeId: Number(data.serviceTypeId) || 0,
          tableGroupId: Number(data.tableGroupId) || 0,
          tableId: Number(data.tableId) || 0,
          noOfPerson: Number(data.noOfPerson) || 0,
          noOfAdult: Number(data.noOfAdult) || 0,
          noOfChild: Number(data.noOfChild) || 0,
          couponCode: data.couponCode === null ? "" : data.couponCode,
          couponCodeAmount: Number(data.couponCodeAmount) || 0,
          overallOrderStatus: "Pending", //data.overallOrderStatus === null ? "" : data.overallOrderStatus,
          orderDate: data.orderDate === null ? "" : data.orderDate,
          orderTime: data.orderTime === null ? "" : data.orderTime,
          orderDoneBy: Number(data.orderDoneBy) || 0,
          orderDoneOn: data.orderDoneOn === null ? "" : data.orderDoneOn,
          orderConfirmedBy: Number(data.orderConfirmedBy) || 0,
          orderConfirmedOn:data.orderConfirmedOn === null ? "" : data.orderConfirmedOn,
          deliveryCharges: Number(data.deliveryCharges) || 0,
          deliveryDate: formattedDate, //data.deliveryDate === null ? "" : data.deliveryDate,
          deliveryTimeId: Number(data.deliveryTimeId) || 0,
          deliveryStatus: data.deliveryStatus === null ? "" : data.deliveryStatus,
          deliveryBoyId: Number(data.deliveryBoyId) || 0,
          deliveryBoyDispatchTime: data.deliveryBoyDispatchTime === null ? "" : data.deliveryBoyDispatchTime,
          deliveryBoyTripNo: Number(data.deliveryBoyTripNo) || 0,
          deliveryBoyTripIncentive: Number(data.deliveryBoyTripIncentive) || 0,
          deliveryReferenceNo: Number(data.deliveryReferenceNo) || 0,
          deliveryPreferenceNo: Number(data.deliveryPreferenceNo) || 0,
          deliveryTripStatus: data.deliveryTripStatus === null ? "" : data.deliveryTripStatus,
          orderPrintStatus: Number(data.orderPrintStatus) || 0,
          totalAmt: Number(totalamountmain) || 0,
          grandTotalAmt: Number(totalamountmain) || 0,
          balanceAmt: Number(totalamountmain) - Number(data.paymentAmount) || 0,
          paymentStatus: data.paymentStatus === null ? "" : data.paymentStatus,
          paymentAmount: Number(data.paymentAmount) || 0,
          returnAmount: Number(data.returnAmount) || 0,
          discountAmount: Number(data.discountAmount) || 0,
          paymentDoneBy: Number(data.paymentDoneBy) || 0,
          paymentDoneOn: data.paymentDoneOn === null ? "" : data.paymentDoneOn,
          paymentAttemptedCounter: Number(data.paymentAttemptedCounter) || 0,
          paymentModeId: Number(data.paymentModeId) || 0,
          paymentBank: data.paymentBank === null ? "" : data.paymentBank,
          paymentRemarks:
            data.paymentRemarks === null ? "" : data.paymentRemarks,
          remarks: data.remarks === null ? "" : data.remarks,
          orderSuggestion:
            data.orderSuggestion === null ? "" : data.orderSuggestion,
          kotDispatchTargetTime:
            data.kotDispatchTargetTime === null
              ? ""
              : data.kotDispatchTargetTime,
          latitude: data.latitude === null ? "" : data.latitude,
          longitude: data.longitude === null ? "" : data.longitude,
          currencyId: Number(data.currencyId) || 0,
          hasRunningOrder: Boolean(data.hasRunningOrder),
          runningOrderCounter: Number(data.runningOrderCounter) || 0,
          runningOrderTotalAmt: Number(data.runningOrderTotalAmt) || 0,
          orders2: processedOrders,
          orders3: [
            // {
            //   orderStatus: order3.orderStatus === null ? "" : order3.orderStatus,
            //   orderDateTime: order3.orderDateTime === null ? "" : order3.orderDateTime,
            //   isActive: true,
            // }
          ],
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return response.data.isSuccess;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ************************* Get Order List by order No ***************************
  getOrderListByOrderNo: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderListByOrderNo`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;

    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // ************************* Get Order List by order No ***************************
  getOrderListByOrderNoFilter: async (filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderListByOrderNo`;
      const response = await axios.post(apiUrl, {
        filter: filteredData,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        pageNumber: 0,
        pageSize: 0
      }, {
        withCredentials: true,
      });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  // ************************* Get Order List by user ***************************
  getOrderListByUserFilter: async (userId, filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    debugger;
    try {
      const apiUrl = `${WebApiUrl}/api/Order/GetOrderListByUserId`;
      const response = await axios.post(apiUrl,
        {
          filter: filteredData,
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          masterType: userId,
          pageNumber: 0,
          pageSize: 50000
        },
        {
          withCredentials: true,
        });
      console.log('Response:', response.data.result);
      return response.data.result;

    } catch (error) {
      console.error('Error:', error.message);
    }
  },
};

export default Order;
