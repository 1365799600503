import React, { useEffect, useRef } from 'react'

const SearchPageMobile = (props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <div className="mobile-filter-page">
      <i onClick={props.close} className="search-back-button fa-solid fa-arrow-left-long"></i>
      <div className='searchpanel-input-box' >
        <input className='search-input-searchpanel' value={props.value} onChange={props.searchItem} type="text" placeholder='What are you looking for ?' ref={inputRef} />
      </div>
    </div>
  )
}

export default SearchPageMobile
