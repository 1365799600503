import React, { useContext, useEffect, useState } from "react";
import { Link , useNavigate} from "react-router-dom";
import SearchPageMobile from "./MobileSections/SearchpageMobile";
import MyContext from "./MyContext";
import MobilSideBar from "./MobileSections/MobilSideBar";
import MainModal from "./MainModal";
import itemMaster from "../API/API_SERVISES/ItemMaster";

const NavBar = (props) => {
  //alert(props.cartNumber);
  const navigate = useNavigate();
  const [mainModal, setMainModal] = useState(false);

  const { OrderType, setOrderType, CompanyDetail, setProductList,isInputDisabled} = useContext(MyContext);

  // // console.log(CompanyDetail);

  useEffect(() => {
    if (!window.location.href.includes("?qs=")) {
      if (CompanyDetail.isEnblTmSltPopupInHmPg === true) {
        const timerId = setTimeout(() => {
          setMainModal(true);
          document.body.style.overflow = "hidden";
        }, 1000);
        // Clear the timer to prevent any unexpected behavior if the component unmounts before the timeout
        return () => clearTimeout(timerId);
      }
    }

  }, [CompanyDetail.isEnblTmSltPopupInHmPg]); // Include CompanyDetail.isEnblTmSltPopupInHmPg in the dependency array

  // console.log(OrderType);

  const fetchData = () => {
    var ServiceTypeData = localStorage.getItem("ServiceType");
    if (ServiceTypeData == 752) {
      setOrderType("Home delivery");
    } else if (ServiceTypeData == 753) {
      setOrderType("Pick Up");
    } else if (ServiceTypeData == 751) {
      setOrderType("Table Order");
    } else if (ServiceTypeData == undefined) {
      setOrderType("");
    }
  };

  const closemainmodal = () => {
    setMainModal(false);
    document.body.style.overflow = "auto";
  };

  const openmainmodal = () => {
    setMainModal(true);
    document.body.style.overflow = "auto";
  };

  const { myaccountmodal, userArray } = useContext(MyContext);
  const [showSidebar, setShowSidebar] = useState(false);
  //const [SearchedItem, setSearchedItem] = useState(false);

  const opensidebar = () => {
    setShowSidebar(!showSidebar);
    document.body.style.overflow = showSidebar ? "auto" : "hidden";
  };
  const [searchValue, setSearchValue] = useState("");

  const searchvaluefunction = async (e) => {
    navigate("/OurMenu")
    setSearchValue(e.target.value);

    const filteredData = [
      {
        fieldName: "name",
        operatorName: "contains",
        compareValue: e.target.value || "",
      },
    ];

    const SearchItem = await itemMaster.GetItemMasterFilter(filteredData)
    if (SearchItem) {
      setProductList(SearchItem)
    }
  };
  const [activeLink, setActiveLink] = useState("/");
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const [searchPage, setSearchPage] = useState(false);
  const opensearch = () => {
    setSearchPage(!searchPage);
  };
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const data = localStorage.getItem("accountLogin");
    const parsedData = JSON.parse(data);
    setUserData(parsedData);
  }, []);

  return (
    <div className="navbar">
      <MobilSideBar handleClick={opensidebar} showSidebar={showSidebar} />
      <div className="navbar-mobile">
        <div onClick={opensidebar} className="opennavbar-mobile">
          <i
            style={{ fontSize: "22px" }}
            className="fa-solid fa-bars mobile-nav-icon"
          ></i>
        </div>


        {CompanyDetail.isEnblTmSltPopupInHmPg === true && ( <>
          {isInputDisabled === false && (
            
            <button
            className="mobile-service-selection"
            id=""
            onClick={openmainmodal}
            style={{ width: "22%" }}
          >
            {OrderType === "" ? (
              <p style={{
                margin: "0"
              }}>{OrderType}  <i class="fa-solid fa-angle-down navbar-down-arrow" style={{ marginTop: "3px" }}></i></p>
            ) : (
              <div>
                <p style={{
                margin: "0"
              }}>
                  {OrderType}
                  <i className="fa-solid fa-angle-down navbar-down-arrow" style={{ marginTop: "3px" }}></i>
                </p>
              </div>
            )}
          </button>
      )}
        </>
        )}
        <Link
          to="/"
          className="mobile-logo-box"
          style={{ fontSize: "15px", marginLeft: "15px" }}
        >
          Mother's Hut
        </Link>
        <div className="icon-box-nav">
          <div>
            <div
              onClick={opensearch}
              className="fa-solid fa-search mobile-nav-icon"
            ></div>
          </div>

          <div>
            <i
              onClick={myaccountmodal}
              className="fa-solid fa-user mobile-nav-icon"
            ></i>
          </div>
        </div>
      </div>

      <div className="navbar-content">
        <div className="nav-location-logo-box">
          <div className="navbar-logo">
            <img src="" alt="" />
          </div>
          <div className="navbar-location">
            {/* <div className=""> */}

            {CompanyDetail.isEnblTmSltPopupInHmPg === true && (
              <button
                className="navbar-servicetyp-box"
                id=""
                onClick={openmainmodal}
              >
                {OrderType === "" ? (
                  <p>Select Your Order Type</p>
                ) : (
                  <div>
                    <p>
                      {OrderType}
                      <i className="fa-solid fa-angle-down navbar-down-arrow"></i>
                    </p>
                  </div>
                )}
              </button>
            )}

            <div className="InputContainer">
              <input
                type="text"
                name="text"
                className="inputsearch"
                id="input"
                placeholder="Search"
                value={searchValue}
                onChange={searchvaluefunction}
              />

              <label htmlFor="input" className="labelforsearch">
                <svg viewBox="0 0 512 512" className="searchIcon">
                  <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
                </svg>
              </label>
              <div className="border"></div>
              <button className="micButton">
                <svg viewBox="0 0 384 512" className="micIcon">
                  <path d="M192 0C139 0 96 43 96 96V256c0 53 43 96 96 96s96-43 96-96V96c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 89.1 66.2 162.7 152 174.4V464H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h72 72c13.3 0 24-10.7 24-24s-10.7-24-24-24H216V430.4c85.8-11.7 152-85.3 152-174.4V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 70.7-57.3 128-128 128s-128-57.3-128-128V216z"></path>
                </svg>
              </button>
            </div>
            {/* <i  className="fa-solid fa-location-dot navbar-icon"></i>
                <h1 className='navbar-location-txt'>National Highway 34, NH12, beside Indian Oil Petrol Pump, Bhatjangla, West Bengal 741101</h1> */}
          </div>
        </div>
        <div className="navbat-option-box">
          <Link
            to="/"
            className={`navbar-option ${activeLink === "/" ? "active" : ""}`}
            onClick={() => handleLinkClick("/")}
          >
            <div className="navbar-option-icon">
              <i className="fa-regular fa-compass navbar-icon"></i>
            </div>
            <div className="navbar-option-link">
              <div className="navbar-option-link-txt">Homepage</div>
            </div>
          </Link>

          <Link
            to="/OurMenu"
            className={`navbar-option ${activeLink === "/OurMenu" ? "active" : ""
              }`}
            onClick={() => handleLinkClick("/OurMenu")}
          >
            <div className="navbar-option-icon">
              <i className="fa-solid fa-file-lines navbar-icon"></i>
            </div>
            <div className="navbar-option-link">
              <div className="navbar-option-link-txt">Our Menu</div>
            </div>
          </Link>

          <Link
            to="/MyFavorites"
            className={`navbar-option ${activeLink === "/MyFavorites" ? "active" : ""
              }`}
            onClick={() => handleLinkClick("/MyFavorites")}
          >
            <div className="navbar-option-icon">
              <i className="fa-regular fa-heart navbar-icon"></i>
            </div>
            <div className="navbar-option-link">
              <div className="navbar-option-link-txt">My Favorites</div>
            </div>
          </Link>

          <Link
            to="/MyCart"
            className={`navbar-option ${activeLink === "/MyCart" ? "active" : ""
              }`}
            onClick={() => handleLinkClick("/MyCart")}
          >
            <div className="navbar-option-icon">
              <i className="fa-solid fa-cart-shopping navbar-icon"></i>
            </div>
            <div className="navbar-option-link">
              <div
                className="navbar-option-link-txt"
                style={{ position: "relative" }}
              >
                My Cart
                <div className="cart-number-pc">
                  {props.cartNumber === 0 ? <div></div> : props.cartNumber}
                </div>
              </div>
            </div>
          </Link>

          <div
            onClick={myaccountmodal}
            className={`navbar-option ${activeLink === "/MyAccount" ? "active" : ""
              }`}
          >
            <div className="navbar-option-icon">
              <i className="fa-regular fa-user navbar-icon"></i>
            </div>
            <div className="navbar-option-link">
              <div className="navbar-option-link-txt" to="">
                {userData ? userData.name : "My Account"}
              </div>
            </div>
          </div>
        </div>
      </div>
      {searchPage && (
        <div>
          <SearchPageMobile close={opensearch} value={searchValue}
                searchItem={searchvaluefunction}/>
        </div>
      )}

      {mainModal && (
        <MainModal
          fetchData={fetchData}
          OrderType={OrderType}
          closemainmodal={closemainmodal}
        />
      )}
    </div>
  );
};

export default NavBar;
