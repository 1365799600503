import React from 'react'

const GetOTPPanel = (props) => {
const {handleInputChangeSignIn,
    SignInAccount,
    secondsLeft,
    isDisabled,
    handleClick,
    visibleResendOTP,
    inputValuesForOTP,
    handleInputChangeOTP,
} = props

  return (
    <div className="form-container">
    <p className="title" style={{ margin: "0" }}>
      Verify OTP
    </p>
    <form className="form" style={{ marginTop: "0px" }}>
      <div className="input-group" style={{marginBottom:"5px"}}>
        <label htmlFor="password">OTP</label>
        <input
          type="number"
          onChange={handleInputChangeOTP}
          value={inputValuesForOTP.otp}
          name="otp"
          placeholder=""
        />
      </div>
      {visibleResendOTP && (
        <button onClick={handleClick} style={{ color: isDisabled ? '#947bdd' : 'rgba(156, 163, 175, 1)' }} className="forgot resendotpbtn" disabled={isDisabled}>
          {isDisabled ? `Resend OTP after ${secondsLeft} sec` :
            <span>Haven't received the OTP ?
              <span style={{ color: '#a78bfa', textDecoration: "underline" }}>Resent OTP</span></span>}
        </button>
      )}
      <div
        style={{
          marginTop: "15px",
          cursor: "pointer",
          marginBottom: "10px",
        }}
        className="sign"
        onClick={SignInAccount}
      >
        Sign In
      </div>
    </form>
  </div>
  )
}

export default GetOTPPanel