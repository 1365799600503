import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;


var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Email = {

    SendEmail: async (AccountData , saveOrder) => {
      //debugger
       console.log(AccountData)
       console.log(saveOrder)

        try {
          const apiUrl = `${WebApiUrl}/api/Email/send-email`;
          const response = await axios.post(
            apiUrl,
            {
                 to: AccountData.emailId,
                // to: "shanu88199@gmail.com",
                subject: "Hello Techcherry",
                body: "Hello Techcherry"
            },
            {
              withCredentials: true,
              credentials: "include",
            }
          );
          console.log("Response:", response.data.result);
          return true;
          // setMainDataCallback(response.data.result);
        } catch (error) {
          console.error("Error:", error.message);
        }
      },
    


};

export default Email;