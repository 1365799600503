import React, { useState } from "react";

const VariableProductModal = (props) => {
  // const [selectedOption, setSelectedOption] = useState("option1");

  // const handleOptionChange = (value) => {
  //   setSelectedOption(value);
  // };

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [itemName, setItemName] = useState();

  const handleOptionChange = (optionId, itemId, iName) => {
    setItemName(iName);
    setSelectedOption(optionId);
    setSelectedItemId(itemId);
  };

  const data = props.data;
  const parantData = props.parantData;

  const {product ,handleDecrementValue ,buttonClickCounts ,handleIncrementValue} = props

  // // console.log(parantData);

  return (
    <div className="variableproduct-modal">
      <i
        className="fa-solid fa-xmark variable-cross"
        onClick={props.variableClose}
      ></i>
      <div className="vp-heading-box">
        <i className="fa-solid fa-circle-stop"></i>
        {parantData === undefined ||
          parantData === 0 ||
          parantData === "" ? null : (
          <div className="VP-heading">{parantData.name}</div>
        )}
      </div>
      <div className="vp-modal-content">
        {parantData === undefined ||
          parantData === 0 ||
          parantData === "" ? null : (
          <div className="VP-heading">Choose Your {parantData.name}</div>
        )}

        <div className="vp-modal-content-main">
          {data.map((item, index) => (
            <div key={index}>
              <div style={{width:"60%"}}>
                {/* <input
                  type="radio"
                  name="selectedOption"
                  id={`option${index + 1}`}
                  checked={selectedOption === `option${index + 1}`}
                  onChange={() => handleOptionChange(`option${index + 1}`, item.d2Id, item.name)}
                /> */}
                <label htmlFor={`option${index + 1}`} className="VP-heading-2">
                  {item.name}
                </label>
              </div>
              <div className="quantity-box">
                      <p className="menu-card-price">
                        <div className="quantity">
                          <button
                            style={{ paddingBottom: "4px" }}
                            className="quantity__minus"
                            onClick={() => handleDecrementValue(item.d2Id)}
                          >
                            -
                          </button>
                          <input 
                          style={{margin:"0"}}
                            className="quantity__input"
                            type="text"
                            value={buttonClickCounts[item.d2Id] || 0}
                            readOnly
                          />
                          <button
                            className="quantity__plus"
                            onClick={() =>
                              handleIncrementValue(item.d2Id, item.name ,item.isAttributeItem, item.attributeParentId)
                            }
                          >
                            +
                          </button>
                        </div>
                      
                      </p>
                    </div>
              <div className="vp-price-box">
                <i
                  style={{ marginRight: "3px", marginTop: "2px" }}
                  className="fa-solid fa-indian-rupee-sign"   
                ></i>
                {item.salePrice}{" "}
              </div>
            </div>
          ))}

          {/* <button
            className="vp-add-btn"
            onClick={() => {
              if (selectedItemId) {
                props.handleIncrementValue(selectedItemId, itemName);
              }
            }}
            disabled={!selectedOption}  // Disable button if no option is selected
          >
            Add to Cart
          </button> */}
        </div>

      </div>
    </div>
  );
};

export default VariableProductModal;
