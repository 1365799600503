import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const DeliveryAndTimeSlot = {
  GetDeliveryAndTimeSlot: async () => {
    try {
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot/GetDeliveryAndTimeSlots`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      // console.log(apiUrl);
      console.log("Response:", response.data.result);
      return response.data.result;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


 
  InsertDeliveryAndTimeSlot: async (data , checkedIds) => {
    console.log(data)
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    try {
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot`;
      const response = await axios.post(
        apiUrl,
        {
            deliveryPointName: data.deliveryPointName,
            orderType: data.orderType,
            mobile: data.mobile,
            website: data.website,
            serviceTax: data.serviceTax,
            delvryDistRangeFromKM: data.delvryDistRangeFromKM,
            delvryDistRangeToKM: data.delvryDistRangeToKM,
            minDelvryAmtInCart: data.minDelvryAmtInCart,
            deliveryCharges: data.deliveryCharges,
            estimateDelvryTime: data.estimateDelvryTime,
            noOfDelveriesTakenInPerTimeSlot: data.noOfDelveriesTakenInPerTimeSlot,
            deliveryStatus: parseBoolean(data.deliveryStatus),
            deliveryTimeSlots: checkedIds,
            isActive: true,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return true;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetDeliveryAndTimeSlotById: async (DeliveryAndTimeSlotId) => {
    try {
      console.log("get by id");
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot?DeliveryAndTimeSlotId=${DeliveryAndTimeSlotId}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  // // ************************* Get By Id ****************************
  // GetAgentById: async (id) => {
  //   try {
  //     const apiUrl = `${WebApiUrl}/api/Agent?agentId=${id}`;
  //     const response = await axios.get(apiUrl, {}, {
  //       withCredentials: true,
  //     });
  //     console.log('Response:', response.data.result);
  //     //setEditCallBack(response.data.result);
  //    return response.data.result;
  //   } catch (error) {
  //     console.error('Error:', error.message);
  //   }
  // },

  deleteDeliveryAndTimeSlot: async (DeliveryAndTimeSlotId) => {
    try {
      console.log("delete");
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot?DeliveryAndTimeSlotId=${DeliveryAndTimeSlotId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  updateDeliveryAndTimeSlot: async (data) => {
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }

    try {
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot?DeliveryAndTimeSlotId=${data.id}`;
      const response = await axios.put(
        apiUrl,
        {
          id: data.id,
          name: data.name,
          DeliveryAndTimeSlotId: data.DeliveryAndTimeSlotId,
          DeliveryAndTimeSlotGSTIN: data.DeliveryAndTimeSlotGSTIN,
          address: data.address,
          city: data.city,
          pinCode: data.pinCode,
          phone: data.phone,
          email: data.email,
          state: data.state,
          transportationMode: data.transportationMode,
          vehicleNo: data.vehicleNo,
          vehicleType: data.vehicleType,
          fuelAvgLtrKM: Number(data.fuelAvgLtrKM),
          fuelChargeKM: Number(data.fuelChargeKM),
          docLandingRR_LR: data.docLandingRR_LR,
          extraField1: data.extraField1,
          extraField2: data.extraField2,
          extraField3: data.extraField3,
          extraField4: data.extraField4,
          extraField5: data.extraField5,
          isActive: parseBoolean(data.isActive),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.result);
      return response.data.isSuccess

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


  getAllottedDeliveryTimeSlots: async (DeliveryAndTimeSlotId) => {
    try {
      const apiUrl = `${WebApiUrl}/api/DeliveryAndTimeSlot/getAllottedDeliveryTimeSlots`;
      const response = await axios.post(
        apiUrl,
        {
          deliveryPointName: DeliveryAndTimeSlotId.deliveryPointName,
          orderType: DeliveryAndTimeSlotId.orderType,
          distance: DeliveryAndTimeSlotId.distanceTo,
          //distanceFrom:DeliveryAndTimeSlotId.distanceFrom
        },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


};

export default DeliveryAndTimeSlot;
