import { Route, Routes, useNavigate } from "react-router-dom";
import HomePage from "./MyApp/Pages/HomePage";
import NavBar from "./MyApp/Component/NavBar";
import Footer from "./MyApp/Component/Footer";
import MyAccount from "./MyApp/Pages/MyAccount";
import MyCart from "./MyApp/Pages/MyCart";
import MyFavorites from "./MyApp/Pages/MyFavorites";
import OurMenu from "./MyApp/Pages/OurMenu";
import MyAccountPage from "./MyApp/Pages/MyAccountPage";
import MyContext from "./MyApp/Component/MyContext";
import { useEffect, useState } from "react";
import MobileBottomMentBtn from "./MyApp/Component/MobileBottomMentBtn";
import TermsAndConditions from "./MyApp/Pages/TermsAndConditions";
import ContactUs from "./MyApp/Pages/ContactUs";
import NotFound from "./MyApp/Pages/NotFound";
import CurrencyConverter from "./MyApp/Pages/CurrencyConverter";
import Dropdownnew from "./MyApp/Pages/Dropdownnew";
import Company from "./MyApp/API/API_SERVISES/Company";
import ItemMasterApi from "./MyApp/API/API_SERVISES/ItemMaster";
import itemGroupApi from "./MyApp/API/API_SERVISES/ItemGroup";
import User from "./MyApp/API/API_SERVISES/User";
import TrackOrderStatus from "./MyApp/Pages/TrackOrderStatus";
import Request from "./MyApp/Pages/Request";


function App() {

  const [checkCredentialsPopup, setCheckCredentialsPopup] = useState(false);
  const [EcomToken, setEcomToken] = useState("");
  const [CompanyDetail, setCompanyDetail] = useState({})
  const [currentUser, setCurrentUser] = useState(false)
  const [currentUserData, setCurrentUserData] = useState([])
  const [DefaultAddressChange, setDefaultAddressChange] = useState(false)
  const [ItemGroupMaster, setItemGroupMaster] = useState([]);

  const openCredentialModal = () => {

    setCheckCredentialsPopup(!checkCredentialsPopup);
  };

  const checkCredentials = async () => {
    // debugger
    const data = await User.checkCredentials();
    // console.log(data);
    if (data === false) {
      openCredentialModal();
    }
    else {
      const currentUserData = await User.GetCurrentUser()
      console.log(currentUserData)
      if (currentUserData) {
        setCurrentUserData(currentUserData)
        setCurrentUser(true)
      }
    }
  };

  useEffect(() => {
    checkCredentials();
  }, []);

  const sendToken = async () => {
    if (EcomToken == "" && EcomToken == undefined) {
      alert("Check the Token and try again")
    } else {
      const data = await User.SendToken(EcomToken);
      alert(data)
      if (data == true) {
        window.location.reload()
        alert("Lets go")
      }
    }
  }

  const handletoken = (e) => {
    var token = e.target.value
    setEcomToken(token)
  }


  const [productlist, setProductList] = useState([]);
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  useEffect(() => {
    if (currentUser) {

      const fetchData = async () => {
        setLoading(true); //true
        const filteredData = [
          {
            fieldName: "isActive",
            operatorName: "Equal",
            compareValue: true,
          },
        ];
        try {
          //const categoryName = await ItemMasterApi.GetItemMasterFilter(filteredData);
          const categoryName = await ItemMasterApi.GetItemMasterFilterWithPage();
          // console.log("categoryName");
          // console.log(categoryName);
          setProductList(categoryName);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchData();
    }

  }, [currentUser]);


  useEffect(() => {
    if (currentUser) {
      const CompanyDetail = async () => {
        const CompanyData = await Company.GetCompanyById(currentUserData.branchId)
        if (CompanyData) {
          setCompanyDetail(CompanyData)

        } else {
          alert("error while finding the company")
        }
      }
      CompanyDetail()
    }

  }, [currentUser])


  const fetchDataig = async () => {
    if (currentUser == true) {
      setLoading(true)
      const ItemGroupMaster = await itemGroupApi.GetItemGroup();
      if (ItemGroupMaster) {
        setItemGroupMaster(ItemGroupMaster);
        setLoading(false)
      }
      else {
        console.error("error while finding the item group master")
      }
    }
  }


  useEffect(() => {
    fetchDataig();
  }, [currentUser]);


  const initialOrderList = [];
  const initialOrderList3 = [];
  const initialCartData = [];
  const initialButtonClickCounts = [];

  const storedOrderList = localStorage.getItem("orderList");
  const storedOrderList3 = localStorage.getItem("orderList3");
  const storedCartData = localStorage.getItem("cartData");
  const storedItemCount = localStorage.getItem("itemCount");

  const [orderList, setOrderList] = useState(
    storedOrderList ? JSON.parse(storedOrderList) : initialOrderList
  );
  const [orderList3, setOrderList3] = useState(
    storedOrderList3 ? JSON.parse(storedOrderList3) : initialOrderList3
  );
  const [cartData, setCartData] = useState(
    storedCartData ? JSON.parse(storedCartData) : initialCartData
  );
  const [buttonClickCounts, setButtonClickCounts] = useState(
    storedItemCount ? JSON.parse(storedItemCount) : initialButtonClickCounts
  );

  const [cartNumber, setCartNumber] = useState(0);

  const [serviceTypeShow, setServiceTypeShow] = useState(true)

  const [paymentCart, setPaymentCart] = useState(true)


  // const calculateTotalQty = (data) => {
  //   return data.reduce((total, item) => {
  //     console.log(item.quantity); // Debug individual quantities
  //     return total + Number(item.quantity || 0);
  //   }, 0);
  // };

  // if (cartData && Array.isArray(cartData)) {
  //   console.log("CartData App.js", cartData);
  //   const totalQty = calculateTotalQty(cartData);
  //   setCartNumber(totalQty);
  //   alert(totalQty);
  // setCartNumber(cartData.length);

  useEffect(() => {
    if (currentUser) {

      if (cartData && Array.isArray(cartData)) {
        const totalQuantity = cartData.reduce((total, item) => total + (item.quantity || 0), 0);
        // console.log("CartData App.js", cartData);
        setCartNumber(totalQuantity);
      } else {
        // Handle the case when cartData is undefined or not an array
        setCartNumber(0);
      }
    }
  }, [cartData]);

  const [OrderType, setOrderType] = useState("")
  const [selectedService, setSelectedService] = useState(751);

  // Serialize state and update local storage whenever it changes
  const fetchData = () => {
    var ServiceTypeData = localStorage.getItem("ServiceType")
    if (ServiceTypeData == 752) {
      setOrderType("Home delivery")
      setSelectedService(ServiceTypeData)
    } else if (ServiceTypeData == 753) {
      setOrderType("Pick Up")
      setSelectedService(ServiceTypeData)
    } else if (ServiceTypeData == 751) {
      setOrderType("Table Order")
      setSelectedService(ServiceTypeData)
    } else if (ServiceTypeData == undefined) {
      setOrderType("")
    }
  }

  useEffect(() => {
    fetchData();
  })

  useEffect(() => {

    fetchData()

    const serializedOrder = JSON.stringify(orderList);
    const serializedOrder3 = JSON.stringify(orderList3);
    const serializedCartData = JSON.stringify(cartData);
    const cartNumber1 = JSON.stringify(cartNumber);
    const itemCount1 = JSON.stringify(buttonClickCounts);

    console.log(cartData)

    localStorage.setItem("itemCount", "");
    localStorage.setItem("cartNumber", "");
    localStorage.setItem("orderList", "");
    localStorage.setItem("orderList3", "");
    // if(serializedCartData === "undefined"){
    //   localStorage.setItem("cartData", "");
    // }
    // else{
    //   localStorage.setItem("cartData", serializedCartData);
    // }
    localStorage.setItem("cartData", "");

  }, [orderList, orderList3, cartData, cartNumber, buttonClickCounts]);

  // Rest of your component code

  const [accessToken, setaccessToken] = useState("");
  const [refreshToken, setrefreshToken] = useState("");
  const [, setLoading] = useState(false);

  const AccessToken = { accessToken };
  const RefreshToken = {
    refreshToken,
  };

  //  console.log(AccessToken);
  //  console.log(RefreshToken);

  // Event handler function to prevent right-click
  const disableRightClick = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener("contextmenu", disableRightClick);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  console.log(selectedService)
  const [LoginShow, setLoginShow] = useState(true);
  const [myAccount, setMyAccount] = useState(false);

  const navigate = useNavigate();
  const userLogin = localStorage.getItem("accountLogin");
  const userArray = userLogin ? JSON.parse(userLogin) : null;

  const myaccountmodal = () => {
    const userLogin = localStorage.getItem("accountLogin");
    const userArray = userLogin ? JSON.parse(userLogin) : null;

    if (userArray) {
      // UserArray is present, navigate to "/MyAccountPage"
      // setMyAccount(!myAccount);
      navigate("/MyAccountPage");
    } else {
      // UserArray is not present, continue with your existing logic
      setMyAccount(!myAccount);
      document.body.style.overflow = myAccount ? "auto" : "hidden";
      signupshow();
    }
  };

  const myaccountmodal1 = () => {
    setMyAccount(!myAccount);
    document.body.style.overflow = myAccount ? "auto" : "hidden";
    signupshow1();
  };

  const [loginPanel, setLoginPanel] = useState(true);
  const [signin, setSignin] = useState(false);
  const [getOtp, setgetOtp] = useState(false);
  const [forgot, setforgot] = useState(false);
  const [ResetPassword, setResetPassword] = useState(false);

  const signupshow = () => {
    setLoginPanel(true);
    setSignin(false);
    setgetOtp(false)
    setforgot(false)
    setResetPassword(false)
  };
  const ResetPasswordUI = () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(false)
    setforgot(false)
    setResetPassword(true)
  };

  const GoToOTP = async () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(true)
    setforgot(false)
    setResetPassword(false)

  };
  const signupshow1 = () => {
    setLoginPanel(false);
    setSignin(true);
    setgetOtp(false)
    setforgot(false)
    setResetPassword(false)

  };

  const forgotPaggword = () => {
    setLoginPanel(false);
    setSignin(false);
    setgetOtp(false)
    setforgot(true)
    setResetPassword(false)

  };


  const CheckOtpAndSignIn = () => {

  }
  // const handleKeyDown = (e) => {
  //   // Check if F12 is pressed
  //   if (e.key === 'F12') {
  //     e.preventDefault(); // Prevent the default behavior (opening developer tools)
  //     alert('Developer tools are disabled');
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('keydown', handleKeyDown);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  const imageBaseUrl =
    // "http://localhost/yesbm-api/api/v1/ItemMaster/Image?D1ItemId=";
    "http://122.168.117.143/yesbm-api/api/v1/ItemMaster/Image?D1ItemId=";
  // http://192.168.1.5/YESBM-API/api/v1/ItemMaster/Image?D1ItemId=20
  // http://192.168.1.5/YESBM-API/api/v1/ItemMaster/Image?D1ItemId=13
  console.log("imageurl", imageBaseUrl);

  return (
    <>
      <MyContext.Provider
        value={{
          // constextProduct,
          cartData,
          orderList3,
          orderList,
          setCartData,
          setOrderList3,
          setOrderList,
          myAccount,
          signupshow1,
          setMyAccount,
          myaccountmodal,
          loginPanel,
          signin,
          getOtp,
          GoToOTP,
          signupshow,
          myaccountmodal1,
          setrefreshToken,
          setaccessToken,
          refreshToken,
          accessToken,
          setLoading,
          cartNumber,
          buttonClickCounts,
          setButtonClickCounts,
          userArray,
          LoginShow,
          imageBaseUrl,
          setLoginShow,
          OrderType,
          setOrderType,
          CompanyDetail,
          setProductList,
          productlist,
          selectedService,
          setSelectedService,
          setServiceTypeShow,
          serviceTypeShow,
          paymentCart,
          setPaymentCart,
          isInputDisabled,
          setIsInputDisabled,
          CheckOtpAndSignIn,
          DefaultAddressChange,
          setDefaultAddressChange,
          fetchData,
          ItemGroupMaster, 
          forgotPaggword,
          forgot,
          setforgot,
          ResetPassword,
          ResetPasswordUI,
          setItemGroupMaster
        }}
      >
        <NavBar cartNumber={cartNumber} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/MyAccount" element={<MyAccount />} />
          <Route path="/MyAccountpage" element={<MyAccountPage />} />
          <Route path="/MyCart" element={<MyCart />} />
          <Route path="/MyFavorites" element={<MyFavorites />} />
          <Route path="/OurMenu" element={<OurMenu />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/abc" element={<CurrencyConverter />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/dropdown" element={<Dropdownnew />} />
          <Route path="/TrackOrderStatus" element={<TrackOrderStatus />} />
          <Route path="/Request" element={<Request />} />
        </Routes>
        <MobileBottomMentBtn cartNumber={cartNumber} />
        <Footer />
        {myAccount && <MyAccount close={myaccountmodal} />}
        {checkCredentialsPopup && (
          <div className="modal-back" >
            <div className="account-modal" style={{ height: "120px", flexDirection: "column", paddingBottom: "10px" }}>
              <div className="input-group" style={{ width: "90%", margin: "auto 0" }}>
                <label htmlFor="username">Enter Token</label>
                <input
                  type="text"
                  onChange={(e) => handletoken(e)}
                  id="username"
                  placeholder=""
                  name=""
                />
              </div>
              <button style={{ padding: "5px 20px", border: "none", borderRadius: "5px", backgroundColor: "rgba(167, 139, 250, 1)" }} onClick={sendToken}> Submit </button>
            </div>
          </div>
        )}
      </MyContext.Provider>
    </>
  );
}

export default App;
