import React, { useContext, useEffect, useState } from "react";
import AddAddressModal from "./AddAddressModal";
import AddAddressModalProfle from "./AddAddressModalProfle";
import AddAddress from "../API/API_SERVISES/AddAddress";
import Loader from "./Loader";
import MyContext from "./MyContext";

const MyAccountComponent2Address = (props) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addresses, setAddAddresses] = useState([]);

  const [inputValues, setInputValues] = useState({
    accountId: "",
    name: "",
    mobile: "",
    pincode: "",
    state: "",
    address: "",
    locality: "",
    city: "",
    typeOfAddress: 0,
    isDefault: "",
    isActive: true,
  });

  const { DefaultAddressChange,
    setDefaultAddressChange } = useContext(MyContext)

  const handleCheckboxChange = async (addressIndex, id) => {
    var getaddress = await AddAddress.getAddressById(id, myAccountID);
    if (getaddress.isDefault == false) {
      const updatedValues = {
        ...getaddress,
        "isDefault": true,
      };
      await AddAddress.updateAddress(updatedValues, myAccountID);
    }
    setDefaultAddressChange(!DefaultAddressChange)
    fetchAddress()
  };


  const [myAccountID, setMyAccountID] = useState();

  const [EditData, setEditData] = useState();

  const editaddress = async (id) => {
    var getaddress = await AddAddress.getAddressById(id, myAccountID);
    console.log(getaddress);
    setEditData(getaddress);
    setAddressModal(true);
  };

  const deleteaddress = async (id) => {
    setLoading(true);
    var deletedata = await AddAddress.deleteAddress(id, myAccountID);
    if (deletedata) {
      alert("Address Deleted");
      fetchAddress();
      setLoading(false);
    } else {
      setLoading(false);
      alert("error Occoured");
    }
  };

  const [myAccountdata, setMyAccountdata] = useState([]);

  useEffect(() => {
    // Retrieve data from local storage when the component mounts
    const storedData = localStorage.getItem('accountLogin');

    if (storedData) {
      // If data is found, update the state
      setMyAccountdata(storedData);
    }
  }, []);

  const fetchAddress = async () => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    if (loginData) {
      // console.log(loginData.d1Id);
      setMyAccountID(loginData.d1Id)
      var addresses = await AddAddress.getAddress(loginData.d1Id);
      if (addresses) {
        setAddAddresses(addresses);
     
        // setAddressModal(false);
        setLoading(false);
      } else {
        alert("Error Occoured");
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchAddress = async () => {
      var loginDataString = localStorage.getItem("accountLogin");
      var loginData = JSON.parse(loginDataString);
      if (loginData) {
        // console.log(loginData.d1Id);
        setMyAccountID(loginData.d1Id)
        var addresses = await AddAddress.getAddress(loginData.d1Id);
        if (addresses) {
          setAddAddresses(addresses);
          // debugger
          setAddressModal(false);
          setLoading(false);
        } else {
          alert("Error Occoured");
         setLoading(false);
        }
      }
    };

    fetchAddress();
  }, []);

  // console.log(addresses);

  const [addressModal, setAddressModal] = useState(false);
  const openAddressmodal = () => {
    setAddressModal(!addressModal);
    setEditData("")
  };

  return (
    <div>
      <div>
        <div>
          <button className="add-address-button" onClick={openAddressmodal}>
            Add Address <i className="fa-solid fa-plus"></i>
          </button>

          {addresses && addresses.length > 0 ? (
            addresses.map((address, index) => (
              <div className="address-box" key={index} onClick={() => handleCheckboxChange(index, address.id)}>
                <label className="custom-checkbox address-logo">
                  <input
                    type="checkbox"
                    name="addressCheckbox"
                    value={address.isDefault}
                    checked={address.isDefault}
                    disabled
                    // onChange={() => handleCheckboxChange(index, address.id)}
                  />
                  <span className="checkmark"></span>
                </label>

                <p className="address-text">{address.city}</p>
                <p className="address-text" style={{ fontWeight: "100" }}>{address.address}</p>
                <div className="adress-delete-edit">
                  <i
                    onClick={() => editaddress(address.id)}
                    style={{ color: "#305197" }}
                    className="fa-regular fa-pen-to-square fs-b"
                  ></i>
                  <i
                    onClick={() => deleteaddress(address.id)}
                    style={{ color: "#e02121" }}
                    className="fa-solid fa-trash fs-b "
                  ></i>
                </div>
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>

      {addressModal && (
        <AddAddressModalProfle
          data={EditData}
          modelOpen={true}
          fetchAddress={fetchAddress}
          modalclose={openAddressmodal}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

export default MyAccountComponent2Address;
