import React from 'react'
import { Link } from 'react-router-dom'

const SocialMediaLinks = () => {

  return (    
          <div className="social-menu">
        <ul>
            <li><Link to="https://www.facebook.com/MothersHut/?ref=br_rs" target="blank"><i className="fab fa-facebook-f"></i></Link></li>
            <li><Link to="/" target="blank"><i className="fab fa-instagram"></i></Link></li>
            <li><Link to="/" target="blank"><i className="fab fa-whatsapp"></i></Link></li>
            <li><Link to="/"><i className="fab fa-youtube" target="blank"></i></Link></li>
        </ul>
    </div>
  )
}

export default SocialMediaLinks 