import React, { useEffect, useState } from "react";
// import MyContext from "../Component/MyContext";
import ItemMasterApi from "../API/API_SERVISES/ItemMaster";
import Loader from "../Component/Loader";


const MyFavorites = () => {
  const [loading, setLoading] = useState(false);
  const [constextProduct, setConstextProduct] = useState([]);
  // console.log()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    const storedProductList = localStorage.getItem("productList");
    if (storedProductList) {
      const parsedProductList = JSON.parse(storedProductList);
      setConstextProduct(parsedProductList);
    }

    // Update the state with the array
  }, []);

  // const removeFavourite = (productId) => {

  //   const updatedProductList = constextProduct.filter(
  //     (product) => product.id !== productId
  //   );

  //   setConstextProduct(updatedProductList);

  //   // Save the updated array to local storage
  //   localStorage.setItem("productList", JSON.stringify(updatedProductList));
  // };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const categoryName = await ItemMasterApi.GetItemMaster();
        setConstextProduct(categoryName);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const removeFavourite = (productId) => {
    const updatedProductList = constextProduct.map((product) => {
      if (product.id === productId) {
        // Update only the product with the matching id
        return { ...product, favourite: false };
      }
      return product;
    });

    setConstextProduct(updatedProductList);

    // Save the updated array to local storage
    localStorage.setItem("productList", JSON.stringify(updatedProductList));
  };

  const filteredProducts = constextProduct.filter(
    (item) => item.favourite === true
  );
  // // console.log(constextProduct);
  // // console.log(filteredProducts);

  return (
    <div>
      <div className="product-category-head">
        My Favourite
        <i
          style={{ color: "white", marginLeft: "5px" }}
          className="fa-regular fa-heart"
        ></i>
      </div>

      <div className="master-container">
        <div
          style={{ margin:"auto" , borderRadius: "15px", height: "75vh" }}
          className="card-cart cart">
          <label
            className="title"
            style={{borderBottom: "1px solid rgb(229, 229, 229)",
              paddingBottom: "5px",
              marginBottom: "15px",
            }}
          >
            My Favourite{" "}
          </label>
          {filteredProducts.length === 0 ? (
            <p className="nofavproduct">No products found</p>
          ) : (
            filteredProducts.map((product) => (
              <div key={product.id} className="products">
                <div className="product">
                  <div className="products-left">
                    <img
                      style={{ width: "50px", borderRadius: "8px" }}
                      src={product.image}
                      className="image-for-big"
                      alt=""
                    />
                    <div>
                      <span>{product.name}</span>
                      <p>{product.discription}</p>
                    </div>
                  </div>
                  <div className="products-right">
                    {/* <div className='favourite-add'>Add to Cart</div>   */}
                    <i className="fa-solid fa-cart-shopping favourite-add"></i>
                    <i
                      style={{
                        color: "#ef2626",
                        cursor: "pointer",
                      }}
                      className="fa-solid fa-trash"
                      onClick={() => removeFavourite(product.id)}
                    ></i>
                    <label className="price small">
                      {product.price}
                      <i
                        style={{ marginLeft: "3px" }}
                        className="fa-solid fa-indian-rupee-sign"
                      ></i>
                    </label>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* <table  className='favroit-table'>
        <tr>
        <th>sno</th>
        <th>Product Name</th>
        <th>Product Price </th>
        <th>Date Added</th>
        <th>Add To Cart</th>
        <th>Delete</th>
        </tr>
          
          {filteredProducts.map(product =>(
            <tr>
          <td> <img style={{width:"40px" , marginTop:"7px",   borderRadius:"8px"}} src={product.image} alt={product.name} /></td>
          <td> <p className='myfav-txt' style={{margin:"0"}}>{product.name}</p></td>
          <td> <p className='myfav-txt' style={{margin:"0"}}> {product.price} <i className="fa-solid fa-indian-rupee-sign"></i></p></td>
          <td> <p className='myfav-txt' style={{margin:"0"}}>{product.description}</p>  </td>
          <td style={{width:"25%"}}> <button className='add-to-cart-button-fav'>Add To Cart</button></td>
          <td> <i style={{color:"#f11818"}} className='fa-solid fa-trash'></i></td>
        </tr>
          ))}
      </table> */}
      {loading && <Loader />}

    </div>
  );
};

export default MyFavorites;
