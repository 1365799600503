import React, { useContext, useEffect, useState } from "react";
// import CartProduct from '../Component/CartProduct'
import MyContext from "../Component/MyContext";
import MobilesSection1 from "../Component/MobileSections/MobilesSection1";
import MobilesSection2 from "../Component/MobileSections/MobilesSection2";
import MobilesSection3 from "../Component/MobileSections/MobilesSection3";
import AddCouponModal from "../Component/AddCouponModal";
import AddAddressModal from "../Component/AddAddressModal";
import cod from "../Assets/cash-on-delivery.png";
import pod from "../Assets/payment-method.png";
import Order from "../API/API_SERVISES/Order";
import TableApi from "../API/API_SERVISES/Table";
import TableGroupApi from "../API/API_SERVISES//TableGroup";
import MiscellaneousMaster from "../API/API_SERVISES//MiscellaneousMaster";
import TaxCategoryApi from "../API/API_SERVISES/TaxCategory";
import OrderAPI from "../API/API_SERVISES/Order";
import Loader from "../Component/Loader";
import MyAccountComponent2 from "../Component/MyAccountComponent2";
import AccountApi from "../API/API_SERVISES/Account";
import MyAccountComponent2Address from "../Component/MyAccountComponent2Address";
import DeliveryAndTimeSlot from "../API/API_SERVISES/DeliveryAndTimeSlot";
import BillTypeApi from "../API/API_SERVISES/BillType";
import BillSeriesMasterApi from "../API/API_SERVISES/BillSeriesMaster";
import moment from "moment";
import EmailAPI from "../API/API_SERVISES/Email";
import SMSAPI from "../API/API_SERVISES/SMS";
import { useNavigate } from "react-router-dom";
import Table from "../API/API_SERVISES/Table";
import TableAndAddress from "../Component/TableAndAddress";
import PaymentModeSelection from "../Component/PaymentModeSelection";
import TableGroup from "../API/API_SERVISES//TableGroup";

// import BillTypeApi from "../API/API_SERVISES/BillType"
// import BillSeriesMasterApi from "../API/API_SERVISES/BillSeriesMaster"
// import MyAccount from "./MyAccount";

const MyCart = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const productlist = props.data;
  // // console.log(productlist)
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [RemoveOnMobile, setRemoveOnMobile] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setRemoveOnMobile(false);
      } else {
        setRemoveOnMobile(true);
      }
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [filteredTable, setFilteredTable] = useState({});


  const filterDataFuntn = (tableId) => {
    if (Array.isArray(tableNumberData) && tableNumberData.length > 0) {
      var filteredTableName = tableNumberData.filter(
        (item) => item.id == tableId
      );
      console.log(filteredTableName);
      // alert("if")
      setFilteredTable(filteredTableName);
    } else {
      setFilteredTable("");
      // alert("else")
    }
  }

  const navigate = useNavigate();

  const {
    myaccountmodal,
    myaccountmodal1,
    buttonClickCounts,
    setButtonClickCounts,
    orderList3,
    setOrderList3,
    setOrderList,
    orderList,
    cartData,
    setCartData,
    LoginShow,
    setLoginShow,
    imageBaseUrl,
    CompanyDetail,
    setSelectedService,
    selectedService,
    setOrderType,
    OrderType,
    serviceTypeShow,
    paymentCart,
    isInputDisabled,
    fetchData,
    addressdata
  } = useContext(MyContext);

  useEffect(() => {
    fetchData()
  }, [])



  // console.log(selectedService);
  const [selectedServiceID, setSelectedServiceID] = useState();
  const [AddressChange, setAddressChange] = useState("address1");
  const [payment, setPayment] = useState("0");
  const [, setConstextProduct] = useState([]);
  const [productlist, setproductList] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  // const [orderList, setOrderList] = useState([]);
  // const [orderList3, setOrderList3] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalamountmain, setTotalamountmain] = useState(0);
  const [dateOptions, setDateOptions] = useState([]);
  const [AllottedTimeSlot, setAllottedTimeSlot] = useState([]);

  // useEffect(() => {
  //   let total = 0;
  //   cartData.forEach(item => {
  //     total += item.salePrice * item.quantity;
  //   });

  //   setTotalAmount(total);

  //   const totalWithCharges = total + total / 20 + total / 25;

  //   setTotalamountmain(totalWithCharges);

  //   console.log(totalWithCharges);
  // }, [cartData]);

  useEffect(() => {
    const removedata = () => {
      const userArrayString = localStorage.getItem('accountLogin');
      const userArray = userArrayString ? JSON.parse(userArrayString) : null;
      setLoginShow(userArray !== null ? false : true);
      // The following console.log might not show the updated value immediately
      console.log(LoginShow);
    };
    // Call the removedata function when the component mounts
    removedata();
  }, []);


  useEffect(() => {
    calculateTotalAmount();
  }, [cartData]);

  // const {
  //   RemoveItemInCart,
  //   addingItemInCart,
  // } = props;

  useEffect(() => {
    // Function to get the value from local storage
    const getStoredValue = () => {
      try {
        // Retrieve the value from local storage based on the key
        const value = localStorage.getItem("cartData");

        // Parse the JSON value if it's an object or use the raw value
        const parsedValue = JSON.parse(value) || value;

        // Update the state with the retrieved value
        setproductList(parsedValue);
      } catch (error) {
        // Handle errors if any
        console.error("Error retrieving value from local storage:", error);
      }
    };
    // console.log(cartData);
    // Call the function to get the value from local storage
    getStoredValue();
  }, []);

  useEffect(() => {
    // Get the current date and the next three dates
    const getCurrentAndNextThreeDates = () => {
      const today = new Date();
      const nextThreeDates = Array.from({ length: 3 }, (_, index) => {
        const nextDate = new Date(today);
        nextDate.setDate(today.getDate() + index + 1);
        return {
          formattedDate: formatDate(nextDate),
          isoDate: nextDate.toISOString().split("T")[0], // Get only the date part
        };
      });

      setOrderData((prevValues) => ({
        ...prevValues,
        "deliveryDate": formatDate(today),
      }));

      setInputValues((prevValues) => ({
        ...prevValues,
        "deliveryDate": formatDate(today),
      }));
      return [
        {
          formattedDate: formatDate(today),
          isoDate: today.toISOString().split("T")[0], // Get only the date part
        },
        ...nextThreeDates,
      ];
    };

    // Format date to "yyyy-mm-dd"
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      //return `${year}-${month}-${day}`;
      return `${day}-${month}-${year}`;
    };

    // Set initial dates
    setDateOptions(getCurrentAndNextThreeDates());

    // Update the dates every second
    const intervalId = setInterval(() => {
      setDateOptions(getCurrentAndNextThreeDates());

    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);


  const ChangeDeliveryDate = (e) => {
    const selectedIsoDate = e.target.value;
    setInputValues((prevValues) => ({
      ...prevValues,
      deliveryDate: selectedIsoDate,
    }));
  };

  const ChangeDeliveryTime = (e) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      deliveryTimeId: e.target.value,
    }));
  };

  const [AccountData, setAccountData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      var orderno = await OrderAPI.getBillSeriesCounter(1);
      setInputValues((prevValues) => ({
        ...prevValues,
        orderNo: Number(orderno) + 1,
        autoOrderNo: Number(orderno) + 1,
      }));
    };
    fetchData();

    const fetchData1 = async () => {
      const storedData = localStorage.getItem("accountLogin");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        setAccountData(parsedData);
        console.log(parsedData);
        setInputValues((prevValues) => ({
          ...prevValues,
          "d1AccountId": parsedData ? (parsedData.d1Id || "") : "", // Set default value if parsedData is null or d1Id is undefined
          "d2AccountId": parsedData ? (parsedData.d2Id || "") : "", // Set default value if parsedData is null or d2Id is undefined
        }));

      } else {
        // If data is not found in localStorage, set default values or empty string
        setInputValues((prevValues) => ({
          ...prevValues,
          "d1AccountId": "",
          "d2AccountId": "",
        }));
      }
    };

    // Call the fetchData1 function
    fetchData1();
  }, []);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();

  const [inputValues, setInputValues] = useState({
    ord1Id: 0,
    orderSeriesId: 1,
    orderNo: "", //  from bill series
    autoOrderNo: "", //  from bill series
    serviceTypeId: 0,
    orderStatus: "Pending", //confirm only for Confirm & KOT
    orderDate: "", // current date
    orderTime: "", //  current time
    orderDoneBy: 1,  // login user Id
    orderDoneOn: "", // current time
    orderConfirmedBy: 1, // 
    orderConfirmedOn: formattedDate, // currentDate
    orderDoneFrom: "Web",
    d1AccountId: 0, // get account details id of the user that login(Customer id)
    d2AccountId: 0, // get account details id of the user that login(Customer id)
    billTypeId: 1, // get the bill type
    tableGroupId: "", // table group id
    tableGroupName: "",
    tableName: "",
    tableId: "", // table id
    noOfPerson: 0,
    noOfAdult: 0,
    noOfChild: 0,
    couponCode: "20", // hard coded
    couponCodeAmount: 0, // we have to devide the total amoun by 20
    deliveryCharges: 0, // from delivery time slot setting 
    deliveryDate: "13-04-2024", //  selected date fron dropdown
    deliveryTimeId: 825, // the id of the from time slot from miscele
    deliveryStatus: "Pending", // from time slot
    deliveryBoyId: 0, // from time slot
    deliveryBoyDispatchTime: "00:00:00", // from time slot
    deliveryBoyTripNo: 0, // from time slot
    deliveryBoyTripIncentive: 0, // from time slot
    deliveryReferenceNo: 0, // from time slot
    deliveryPreferenceNo: 0, // from time slot
    deliveryTripStatus: "", // from time slot
    orderPrintStatus: 0,
    totalAmt: "", // of cart totalamount
    initialOrderTotalAmt: "",
    grandTotalAmt: "", // total amount
    paymentStatus: "Pending",
    paymentAmount: 0,
    returnAmount: 0,
    discountAmount: 0, // from coupon api
    paymentDoneBy: 0,
    paymentDoneOn: "2024-02-08T06:05:28.900Z", // time of the payment
    paymentAttemptedCounter: 0,
    paymentMode: 0,   //
    paymentBank: "",
    paymentRemarks: "",
    remarks: "",
    orderSuggestion: "", // over all suggestion
    kotDispatchTargetTime: "", // deliveryTime 
    latitude: 0, // from map
    longitude: 0, // from map
    currency: 0, // set default currency\
    hasRunningOrder: "",
    runningOrderCounter: "",
    runningOrderTotalAmt: "",
  });

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   // console.log(`Changing ${name} to ${value}`);

  //   setInputValues((prevValues) => ({
  //     ...prevValues,
  //     [name]: value,
  //   }));

  // };

  // const addingItemInCart = async (id) => {
  //   handleButtonClick();
  //   const product = cartData.find((item) => item.d2Id === id);
  //   const selectedItem = product;
  //   const currentCount = buttonClickCounts[id] || 0;
  //   setButtonClickCounts({
  //     ...buttonClickCounts,
  //     [id]: currentCount + 1,
  //   });

  //   if (selectedItem) {
  //     const itemIndex = cartData.findIndex((item) => item.d2Id === id);
  //     // console.log(cartData);
  //     if (itemIndex !== -1) {
  //       handleIncrementValue1(id);
  //     } else {
  //       setCartData((prevCartData) => {
  //         const updatedOrderStatus = [...prevCartData];
  //         const updatedItem = {
  //           ...selectedItem,
  //           quantity: 1,
  //           deliveryPreference: 0,
  //         };
  //         updatedOrderStatus.push(updatedItem);
  //         return updatedOrderStatus;
  //       });

  //       setOrderList3({
  //         orderStatus: "Received",
  //         orderDateTime: "2024-02-06T09:54:21.043Z",
  //       });
  //       //  console.log( "tax" ,selectedItem.taxCategory1)

  //       if (
  //         selectedItem.taxCategory1 !== 0 &&
  //         selectedItem.taxCategory1 !== null
  //       ) {
  //         // console.log(quantity * selectedItem.salePrice)
  //         const data = await TaxCategoryApi.getTaxCategoryById(
  //           selectedItem.taxCategory1
  //         );
  //         // console.log(data)
  //         setOrderList([
  //           ...orderList,
  //           {
  //             //ord1Id: order2[0].ord1Id,
  //             // id: id,
  //             godownId: selectedItem.defaultGodown, //will get from Item Master
  //             d1ItemId: selectedItem.d1ItemId, //will get from Item Master
  //             d2ItemId: selectedItem.d2ItemId, //will get from Item Master
  //             qty: selectedItem.quantity,
  //             rate: selectedItem.salePrice,
  //             totalAmt: selectedItem.quantity * selectedItem.salePrice, //selectedItem.quantity
  //             kotStatus: "Received",
  //             preferenceNo: 0, //selectedItem.deliveryPreference,
  //             orderDate: "",
  //             orderTime: "",
  //             itemSuggestion: "",
  //             itemRunningOrderCounter: 0,
  //             orderRunningQty: 0,
  //             itemRunningQty: 0,
  //             taxableAmount: selectedItem.quantity * selectedItem.salePrice,
  //             cgstPer: data.localTaxCGST || 0,
  //             cgstValue:
  //               quantity * selectedItem.salePrice * (data.localTaxCGST / 100),
  //             sgstPer: data.localTaxSGST,
  //             sgstValue:
  //               quantity * selectedItem.salePrice * (data.localTaxSGST / 100),
  //             igstPer: data.centralTaxIGST,
  //             igstValue:
  //               quantity * selectedItem.salePrice * (data.centralTaxIGST / 100),
  //             cessPer: data.cessTax,
  //             cessValue:
  //               quantity * selectedItem.salePrice * (data.cessTax / 100),
  //             totalTaxableAmt: selectedItem.quantity * selectedItem.salePrice,
  //             isLocal: true, //true for table order
  //             isInclusive: false,
  //             hasComplementryItem: selectedItem.addasComplementaryItem,
  //             cookStatus: "",
  //             itemIncentivePoint: selectedItem.salesManItemIncentive,
  //             ord2Id: 0,
  //           },
  //         ]);
  //         // localStorage.setItem("orderList", JSON.stringify(orderList));
  //       } else {
  //         // console.log("else tax category")
  //         // console.log(quantity * selectedItem.salePrice)

  //         setOrderList([
  //           ...orderList,
  //           {
  //             //ord1Id: order2[0].ord1Id,
  //             // id: id,
  //             godownId: selectedItem.defaultGodown, //will get from Item Master
  //             d1ItemId: selectedItem.d1ItemId, //will get from Item Master
  //             d2ItemId: selectedItem.d2ItemId, //will get from Item Master
  //             qty: selectedItem.quantity,
  //             rate: selectedItem.salePrice,
  //             totalAmt: selectedItem.quantity * selectedItem.salePrice, //selectedItem.quantity
  //             kotStatus: "Received",
  //             preferenceNo: 0, //selectedItem.deliveryPreference,
  //             orderDate: "",
  //             orderTime: "",
  //             itemSuggestion: "",
  //             itemRunningOrderCounter: 0,
  //             orderRunningQty: 0,
  //             itemRunningQty: 0,
  //             taxableAmount: selectedItem.quantity * selectedItem.salePrice,
  //             cgstPer: 0,
  //             cgstValue: 0,
  //             sgstPer: 0,
  //             sgstValue: 0,
  //             igstPer: 0,
  //             igstValue: 0,
  //             cessPer: 0,
  //             cessValue: 0,
  //             totalTaxableAmt: quantity * selectedItem.salePrice,
  //             isLocal: false,
  //             isInclusive: false,
  //             hasComplementryItem: false,
  //             cookStatus: "",
  //             itemIncentivePoint: 0,
  //             ord2Id: 0,
  //           },
  //         ]);
  //       }
  //     }
  //     calculateTotalAmount();
  //   }
  // };


  const addingItemInCart = (id) => {
    const currentCount = buttonClickCounts[id] || 0;
    setButtonClickCounts({
      ...buttonClickCounts,
      [id]: currentCount + 1,
    });

    const itemIndex = cartData.findIndex((item) => item.d2Id === id);
    if (cartData[itemIndex].quantity >= 1) {
      if (itemIndex !== -1) {
        const updatedCartData = [...cartData];
        updatedCartData[itemIndex].quantity += 1;
        setCartData(updatedCartData);
        const orderIndex = orderList.findIndex(
          (Order) => Order.d2ItemId === id
        );
        if (orderIndex !== -1) {
          const updatedOrderData = [...orderList];
          updatedOrderData[orderIndex].qty += 1;
          updatedOrderData[orderIndex].totalAmt =
            updatedOrderData[orderIndex].qty * updatedOrderData[orderIndex].rate;
          setOrderList(updatedOrderData);
        }

      }
      // console.log( "dec", cartData)
    }
    calculateTotalAmount();
  }

  const RemoveItemInCart = (id) => {
    const currentCount = buttonClickCounts[id] || 0;
    // Check if currentCount is greater than 0 before decrementing
    if (currentCount > 0) {
      setButtonClickCounts({
        ...buttonClickCounts,
        [id]: currentCount - 1,
      });
    }

    const itemIndex = cartData.findIndex((item) => item.d2Id === id);
    console.log(itemIndex);
    if (itemIndex !== -1) {
      const item = cartData[itemIndex];
      console.log(item.quantity);
      if (("item quantity", item.quantity > 1)) {
        // Decrease quantity by 1

        const updatedCartData = [...cartData];
        updatedCartData[itemIndex].quantity -= 1;
        setCartData(updatedCartData);
        console.log(item.quantity);

        const orderIndex = orderList.findIndex(
          (Order) => Order.d2ItemId === id
        );
        if (orderIndex !== -1) {
          const updatedOrderData = [...orderList];
          updatedOrderData[orderIndex].qty -= 1;
          updatedOrderData[orderIndex].totalAmt =
            updatedOrderData[orderIndex].qty *
            updatedOrderData[orderIndex].rate;
          setOrderList(updatedOrderData);
        }
      } else {
        // Remove item from cart and order list
        console.log(item.quantity);
        const updatedCartData = cartData.filter(
          (item, index) => index !== itemIndex
        );

        setCartData(updatedCartData);

        console.log(item.quantity);

        // Remove item from order list
        const updatedOrderData = orderList.filter(
          (order) => order.d2ItemId !== id
        );
        console.log(updatedOrderData);
        setOrderList(updatedOrderData);

      }
    }
    calculateTotalAmount();
    // console.log(orderList);
  };

  useEffect(() => {
    console.log("this is my cart data", cartData);
  }, [cartData]);

  const [showToast, setShowToast] = useState(false);

  const handleButtonClick = () => {

    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    });
  };


  const deleteCartItemClick = (id) => {
    const updatedCartData = cartData.filter((item) => item.d2Id !== id);
    setCartData(updatedCartData);
  };

  const handleIncrementValue1 = (id) => {
    const itemIndex = cartData.findIndex((item) => item.d2Id === id);
    // console.log(itemIndex);
    if (itemIndex !== -1) {
      const updatedCartData = [...cartData];
      updatedCartData[itemIndex].quantity += 1;
      //updatedCartData[itemIndex].price = (updatedCartData[itemIndex].quantity + 1) * updatedCartData[itemIndex].price;
      setCartData(updatedCartData);
      // **********************************


      // const orderIndex = orderList.findIndex((Order) => Order.d2ItemId === id);
      // console.log(orderIndex);
      // const updatedOrderData = [...orderList];
      // // console.log(updatedOrderData);
      // updatedOrderData[orderIndex].qty += 1;
      // updatedOrderData[orderIndex].totalAmt =
      //   updatedOrderData[orderIndex].qty * updatedOrderData[orderIndex].rate;

      // setOrderList(updatedOrderData);
    }

    calculateTotalAmount();
  };

  const OrderUpdate = (event, id) => {
    const { value } = event.target;
    console.log(`Changing itemSuggestion for d1ItemId ${id} to ${value}`);

    setOrderList((prevOrderList) => {
      const updatedItems = prevOrderList.map((item) => {
        // Check if the item's d1ItemId matches the one you want to update
        if (item.d1ItemId === id) {
          // Update the 'itemSuggestion' property of the item
          return {
            ...item,
            itemSuggestion: value, // Replace 'itemSuggestion' with the actual property you want to update
          };
        }
        console.log(orderList);
        return item;
      });

      // Update the 'orderList' array in the state
      return updatedItems;
    });
    calculateTotalAmount();
  };

  // useEffect(() => {
  //   const storedProductList = localStorage.getItem("cartData");

  //   if (storedProductList) {
  //     const parsedProductList = JSON.parse(storedProductList);
  //     setConstextProduct(parsedProductList);
  //     setproductList(parsedProductList);
  //   }
  //   const orderlist = localStorage.getItem("orderList");
  //   const orderlist3 = localStorage.getItem("orderList3");
  //   if (orderlist) {
  //     const parsedProductList = JSON.parse(orderlist);
  //     setOrderList(parsedProductList);
  //   }
  //   if (orderlist3) {
  //     const parsedProductList = JSON.parse(orderlist3);
  //     setOrderList3(parsedProductList);
  //   }
  // }, []);

  useEffect(() => {
    setLoading(true);
    const fetchdata = async () => {
      const data = await Order.getServiceType();
      console.log(data);
      if (data) {
        setServiceType(data);
        if (data.length > 0 && selectedService === undefined) {
          setSelectedService(`${data[0].id}`);
        } else if (selectedService === 752) {
          setSelectedService(752);
        }

        setSelectedServiceID(`${data[0].id}`);
        setInputValues((prevValues) => ({
          ...prevValues,
          "serviceTypeId": `${data[0].id}`,
        }));
        const updatedValues = {
          ...getDeliveryAndTimeSlotId,
          "orderType": `${data[0].id}`,
          "deliveryPointName": 1,
          "distance": 0
        };
        setAllotedTimeSlot(updatedValues)
        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    fetchdata();
  }, []);

  const [tableGroup, setTableGroup] = useState();
  const [, setOrderData] = useState();
  const [tableNumberData, setTableNumberData] = useState();
  const [timeSlot, setTimeSlot] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const localData = localStorage.getItem("OrderTakerId")
      // console.log("localData", localData)
      var data;
      if (localData != null && localData != undefined && localData.length > 0) {
        data = await Table.getTableAllocationById(localData);
        // alert("if")
      }
      else {
        // alert("else")
        data = await Table.getTable();
      }
      console.log("data", data);
      setTableNumberData(data);
    }
    fetchData();
  }, [])

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await TableGroupApi.getTableGroup();
        // console.log(data);
        if (data) {
          console.log(data)
          setTableGroup(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    const fetchdata1 = async () => {
      const data = await MiscellaneousMaster.GetMiscellaneousMaster(170);
      console.log(data);
      setTimeSlot(data);
    };

    fetchdata1();
  }, []);

  const onFloorSelect = async (id) => {
    const fieldName = "tableGroupId";
    const operatorName = "Equal";
    const compareValue = id;
    // console.log(compareValue);

    let data = [];
    const OrderTakerId = localStorage.getItem("OrderTakerId");
    if (OrderTakerId) {
      const allocatedTable = await Table.getTableAllocationById(OrderTakerId);
      data = allocatedTable.filter(item => item.status === "Free");
      // console.log("tableData if");
      // console.log("tableData", data)
      // console.log("tableData", allocatedTable)
    }
    else {
      data = await TableApi.getTableFromTableGroup(
        fieldName,
        operatorName,
        compareValue
      );
      // console.log("tableData else");
      // console.log("tableData", data)
    }
    console.log("tableData", data)
    setTableNumberData(data);

    const tableData = await TableGroup.getTableGroupById(id);

    setOrderData((prevValues) => ({
      ...prevValues,
      tableGroupId: id,
      tableGroupName: tableData.name,
    }));

    setInputValues((prevValues) => ({
      ...prevValues,
      tableGroupId: id,
      tableGroupName: tableData.name,
    }));
  };

  const onTableSelect = async (e) => {
    const tableId = e.target.value;
    //alert(tableId)
    var tableGroup = 0;
    if (tableId !== 0) {
      tableGroup = await Table.getTableById(tableId);
      //filterDataFuntn(tableId);
    }
    const tableData = await Table.getTableById(tableId);
    console.log("table", tableData)

    setInputValues((prevValues) => ({
      ...prevValues,
      "tableId": tableId,
      "tableGroupId": tableGroup.tableGroupId,
      "tableName": tableData.tableName,
      "tableGroupName": tableData.tableGroup,
    }));

    setOrderData((prevValues) => ({
      ...prevValues,
      "tableId": tableId,
      "tableGroupId": tableGroup.tableGroupId,
      "tableName": tableData.tableName,
      "tableGroupName": tableData.tableGroup,
    }));
  };

  const { onQuantityChange } = props;

  const handleQuantityChange = (productId, newQuantity) => {
    const updatedProductList = productlist.map((product) => {
      if (product.d2Id === productId) {
        return { ...product, quantity: newQuantity };
      }
      return product;
    });
    setproductList(updatedProductList);
    if (updatedProductList === undefined) {
      console.log("if")
      localStorage.setItem("cartData", "");
    }
    else {
      localStorage.setItem("cartData", JSON.stringify(updatedProductList));
    }
    calculateTotalAmount();
  };

  // const handleIncrementValue = (id) => {
  //   addingItemInCart(id);

  // };

  const handleDecrementValue = (id) => {
    RemoveItemInCart(id);
    const currentCount = buttonClickCounts[id] || 0;
    setButtonClickCounts({
      ...buttonClickCounts,
      [id]: currentCount - 1,
    });
    calculateTotalAmount();
  };

  const RemoveCart = (productId) => {
    // handleQuantityChange(productId, 0);
    debugger;
    const updatedCartData = cartData.filter((item, index) => item.d2Id !== productId);
    setCartData(updatedCartData);

    // Also, update the orderList to remove the item
    // const updatedOrderData = orderList.filter((item, index) => index !== itemIndex);
    // setOrderList(updatedOrderData);

    const updatedOrderData = orderList.filter(
      (order) => order.d2ItemId !== productId
    );
    console.log(updatedOrderData);
    setOrderList(updatedOrderData);
  };
  // useEffect(() => {
  //   const calculateTotalAmount = () => {
  //     const totalAmount = productlist.reduce(
  //       (total, product) => total + product.salePrice * product.quantity,
  //       0
  //     );
  //     setTotalAmount(totalAmount);
  //     console.log(totalAmount); // Log the updated totalAmount

  //     setInputValues((prevValues) => ({
  //       ...prevValues,
  //       totalAmt: totalAmount, // Use totalAmount instead of totalamountmain
  //     }));
  //   };
  //   calculateTotalAmount();
  // }, [productlist, setTotalAmount, setInputValues]);


  const calculateTotalAmount = () => {
    console.log("this is my cartData list", cartData);
    console.log("this is my orderlist list", orderList);
    // Calculate the total amount of all items in orderList
    console.log(orderList);
    const totalAmount = orderList.reduce((total, item) => {
      return total + item.qty * item.rate;
    }, 0);

    setTotalAmount(totalAmount);

    let grandTotal1 = 0; // Initialize grandTotal1
    let grandTotal2 = 0; // Initialize grandTotal2

    for (let i = 0; i < cartData.length; i++) {
      // console.log("cartData[i].o2TotalAmt:", cartData[i].o2TotalAmt);

      if (cartData[i].o2TotalAmt) {
        grandTotal1 += cartData[i].o2TotalAmt || 0;
      } else {
        grandTotal2 += cartData[i].salePrice * cartData[i].quantity || 0;
      }
    }

    console.log("Grand Total 1:", grandTotal1);
    console.log("Grand Total 2:", grandTotal2);

    var GrandTotal = grandTotal1 + grandTotal2;
    // console.log("grandTotal:", GrandTotal);
    console.log("GrandTotal", GrandTotal);
    console.log("totalAmount", totalAmount);

    setTotalamountmain(GrandTotal);
  };

  const filteredProducts = cartData.filter((item) => item.quantity > 0);
  // console.log(filteredProducts);

  const handlePayment = (event) => {
    setPayment(event.target.id);
    setInputValues((prevValues) => ({
      ...prevValues,
      paymentMode: event.target.id,
    }));
  };

  const [getDeliveryAndTimeSlotId, setDeliveryAndTimeSlotId] = useState({
    deliveryPointName: 0,
    orderType: "",
    distance: 0,
  });
  useEffect(() => {
    if (OrderType == 752) {
      setOrderType("Home delivery");
    } else if (OrderType == 753) {
      setOrderType("Pick Up");
    } else if (OrderType == 751) {
      setOrderType("Table Order");
    } else if (OrderType == undefined) {
      setOrderType("");
      // setSelectedService()
    }
  }, [OrderType]);

  const handleServiceChange = (event) => {
    const newServiceId = event.target.id;
    setSelectedService(newServiceId);
    setSelectedServiceID(newServiceId);
    localStorage.setItem("ServiceType", newServiceId);
    if (newServiceId == 752) {
      setOrderType("Home delivery");
    } else if (newServiceId == 753) {
      setOrderType("Pick Up");
    } else if (newServiceId == 751) {
      setOrderType("Table Order");
    } else if (newServiceId == undefined) {
      setOrderType("");
      // setSelectedService()
    }

    setInputValues((prevValues) => ({
      ...prevValues,
      "serviceTypeId": event.target.id,
    }));
    console.log(selectedService, inputValues.serviceTypeId)

    var updatedValues = [];
    if (event.target.id === 752) {
      updatedValues = {
        ...getDeliveryAndTimeSlotId,
        "orderType": event.target.id,
        "deliveryPointName": 1,
      };
      setDeliveryAndTimeSlotId((prevState) => ({
        ...prevState,
        "orderType": event.target.id,
        "deliveryPointName": 1,
      }));
    }
    else {
      updatedValues = {
        ...getDeliveryAndTimeSlotId,
        "orderType": event.target.id,
        "deliveryPointName": 1,
        "distance": 0
      };
      setDeliveryAndTimeSlotId((prevState) => ({
        ...prevState,
        "orderType": event.target.id,
        "deliveryPointName": 1,
        "distance": 0
      }));
    }
    setAllotedTimeSlot(updatedValues);

  };
  const handleAddressChange = (event) => {
    setAddressChange(event.target.id);
  };


  // Rest of your component code...

  const [addCoupon, setAddCoupon] = useState(false);
  const [addAddress, setAddAddress] = useState(false);

  const opencoupon = () => {
    setAddCoupon(!addCoupon);
    document.body.style.overflow = addCoupon ? "auto" : "hidden";
  };

  const openaddress = () => {
    setAddAddress(!addAddress);
    document.body.style.overflow = addAddress ? "auto" : "hidden";
  };

  const logindone = () => {
    setLoginShow(false);
    myaccountmodal();
    setMobileSection2(false);
    setMobileSection3(false);
    setMobileSection1(true);
  };

  const [mobilesection1, setMobileSection1] = useState(true);
  const [mobilesection2, setMobileSection2] = useState(false);
  const [mobilesection3, setMobileSection3] = useState(false);

  const showchange = () => {
    setMobileSection1(!mobilesection1);
    setMobileSection2(!mobilesection2);
  };

  const showloginsighin = () => {
    setMobileSection1(!mobilesection1);
    setMobileSection3(!mobilesection3);
  };

  const gotofrist = () => {
    setMobileSection1(true);
    setMobileSection3(false);
    setMobileSection2(false);
  };

  useEffect(() => {
    if (mobilesection2 || mobilesection3) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      // Reset body overflow when the component unmounts
      document.body.style.overflow = "auto";
    };
  }, [mobilesection2, mobilesection3]);

  const [fileNames, setFileNames] = useState({});

  const handleFileChange = (e, id) => {
    const file = e.target.files[0];
    if (file) {
      setFileNames((prevFileNames) => ({
        ...prevFileNames,
        [id]: file.name,
      }));
    }
  };

  const savedata = async (order, order2, order3, totalamountmain) => {

    const tableStatus = await Table.checkTableStatus(order.tableId);

    if (order2.length === 0) {
      setLoading(false);
      alert("Your cart is Empty Add Item First");
      // } else if (order.paymentMode === "" || order.paymentMode === undefined) {
      //   setLoading(false);
      //   alert("Please select the Payment Mode");
    }
    else if (order.deliveryDate === "" || order.deliveryDate === undefined) {
      setLoading(false);
      alert("Please select the Delivery Date");
    } else if (
      order.deliveryTimeId === "" ||
      order.deliveryTimeId === undefined
    ) {
      setLoading(false);
      alert("Please select the Delivery Time");
    } else if (selectedService == 0) {
      alert("Please Select Service Type")
      setLoading(false);
    }
    else if (selectedService == null && selectedService == undefined) {
      alert("Please select Service Type");
      setLoading(false);
    }
    else if (selectedService == 752) {
      const retrievedArrayString = localStorage.getItem('Address');
      const retrievedArray = JSON.parse(retrievedArrayString);
      console.log(retrievedArray);

      const isAvailableForHomeDel = filteredProducts.map((product) => (
        product.availableForHomeDelivery === true // Check if product is available for home delivery
      ));

      if (retrievedArray && retrievedArray.length > 0) {
        const defaultAddress = retrievedArray[0].isDefault;
        const firstAddress = retrievedArray[0].address;
        if (defaultAddress === true) {
          if (firstAddress && typeof firstAddress === 'string' && firstAddress.trim() !== "") {
            createOrder(order, order2, order3);
          } else {
            alert("Address is not available");
          }
        }
        else if (isAvailableForHomeDel.some(value => value === false)) {
          alert("Product not available for home delivery.");
          setLoading(false);
        }
        else {
          alert("Please select default address");
          setLoading(false);
        }
      } else {
        alert("Please Add Address");
        setLoading(false);
      }
    }
    else if (selectedService == 751) {
      if (
        order.tableId === "" ||
        order.tableId === undefined
        // order.tableGroupId === "" ||
        // order.tableGroupId === undefined
      ) {
        setLoading(false);
        alert("Please select the Table");
      }
      else if (tableStatus == "Busy") {
        alert("This table is already Busy. Please select another table")
        setLoading(false);
      }
      else {
        createOrder(order, order2, order3);
      }
    } else {
      createOrder(order, order2, order3);
    }
  };
  const createOrder = async (order, order2, order3) => {
    localStorage.removeItem('Address');
    var isFromOrderTaker = localStorage.getItem("OrderTaker");
    //alert("isFromOrderTaker",isFromOrderTaker);
    const saveOrder = await Order.insertOrder(
      order,
      order2,
      order3,
      totalamountmain
    );

    const filteredData = [{
      operationType: 0,
      path: "tableStatus",
      op: "Add",
      from: "string",
      value: "Busy"
    }]
    if (inputValues.serviceTypeId === 751)
      await Table.updateTablePartially(filteredData, order.tableId);

    if (saveOrder) {
      setCartData([]);
      setOrderList([]);
      setOrderList3([]);
      setPayment([]);
      setAddressChange([]);
      setSelectedServiceID([]);
      setSelectedService([]);
      setTotalAmount([0]);
      const sendEmail = await EmailAPI.SendEmail(AccountData, saveOrder);
      // // console.log("sendEmail",sendEmail);
      // const sendSMS = await SMSAPI.SendSMS(AccountData , saveOrder , CompanyDetail);
      // // console.log("sendSMS",sendSMS)
      // debugger;
      const orderaData1 = saveOrder.orderNo;
      setLoading(false);
      // console.log(orderaData1);
      // navigate("/TrackOrderStatus");
      if (isFromOrderTaker == "Yes") {
        var width = window.screen.width;
        var height = window.screen.height;
        alert(`Thanks for Your Order & Your Order no is ${orderaData1}`);
        localStorage.setItem("OrderTaker", "No");
        localStorage.removeItem("OrderTakerId");
        // window.open(`http://198.168.1.67:44483/OrderTakerView`, '_blank', 'width=' + width + ', height=' + height);
        window.open(`http://122.168.117.143:44483/OrderTakerView`, '_blank', 'width=' + width + ', height=' + height);
      }
      else {
        navigate("/TrackOrderStatus", { state: { orderNo: orderaData1 } });
        alert(`Thanks for Your Order & Your Order no is ${orderaData1}`);
      }
      setInputValues({
        orderSeriesId: "",
        orderNo: "", //  from bill series
        autoOrderNo: "", //  from bill series
        serviceTypeId: 0,
        orderStatus: "pending", //confirm only for Confirm & KOT
        orderDate: "", // current date
        orderTime: "", //  current time
        orderDoneBy: "",
        orderDoneOn: "", // current time
        orderConfirmedBy: "",
        orderConfirmedOn: "", // currentDate
        orderDoneFrom: "Website",
        d1AccountId: "", // get account details
        d2AccountId: "", // get account details
        billTypeId: "", // get the bill type
        tableGroupId: "", // table group id
        tableId: "", // table id
        noOfPerson: "",
        noOfAdult: "",
        noOfChild: "",
        couponCode: "", // hard coded
        couponCodeAmount: "", // we have to devide the total amoun by 20
        deliveryCharges: "", // from time slot
        deliveryDate: "", //  from time slot
        deliveryTimeId: "", // from time slot
        deliveryStatus: "", // from time slot
        deliveryBoyId: "", // from time slot
        deliveryBoyDispatchTime: "00:00:00", // from time slot
        deliveryBoyTripNo: "", // from time slot
        deliveryBoyTripIncentive: "", // from time slot
        deliveryReferenceNo: "", // from time slot
        deliveryPreferenceNo: "", // from time slot
        deliveryTripStatus: "", // from time slot
        orderPrintStatus: "",
        totalAmt: totalamountmain, // of cart totalamount
        initialOrderTotalAmt: "",
        grandTotalAmt: "", // total amount
        paymentStatus: "Pending",
        paymentAmount: "",
        returnAmount: "",
        discountAmount: "",
        paymentDoneBy: "",
        paymentDoneOn: "2024-02-08T06:05:28.900Z", // time of the payment
        paymentAttemptedCounter: "",
        paymentMode: "",
        paymentBank: "",
        paymentRemarks: "",
        remarks: "",
        orderSuggestion: "", // over all suggestion
        kotDispatchTargetTime: "", // deliveryTime
        latitude: 0, // from map
        longitude: 0, // from map
        currency: "",
      });
      localStorage.removeItem("orderList");
      localStorage.removeItem("itemCount");
      localStorage.removeItem("orderList3");
      localStorage.removeItem("cartNumber");
      localStorage.removeItem("cartData");
    }
    else {
      setLoading(false);
    }
  };

  const checkout = async () => {
    //debugger;
    setLoading(true);
    const userLogin = localStorage.getItem("accountLogin");
    const userArray = userLogin ? JSON.parse(userLogin) : null;
    if (userArray) {
      // setLoading(false);
      const currentDate1 = new Date();
      const year = currentDate1.getFullYear();
      const month = (currentDate1.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const day = currentDate1.getDate().toString().padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;

      const currentDate = new Date().toISOString();
      const currentTime = new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
      // ***************************************
      const filteredData = [
        {
          fieldName: "IsDefault",
          operatorName: "Equal",
          compareValue: true,
        },
      ];
      const updatedData = await BillTypeApi.GetFilteredBillType(filteredData);
      const billSeries = await BillSeriesMasterApi.getBillSeriesMasterFiltered(
        filteredData
      );
      const orderNo = await Order.getBillSeriesCounter(billSeries[0].id);
      // console.log("billTypeId-", updatedData[0].id);
      // **************************************
      if (
        !cartData[0] ||
        cartData[0].ord1Id === undefined ||
        cartData[0].ord1Id === ""
      ) {
        setLoading(false);
        alert("Please Add Item In Cart");
      } else {
        const updatedValues2 = orderList.map((order) => ({
          ...order,
          ord1Id: cartData[0].ord1Id,
          orderDate: formattedDate,
          orderTime: currentTime,
        }));

        setOrderList((prevValues) =>
          prevValues.map((order) => ({
            ...order,
            "orderDate": formattedDate,
            "orderTime": currentTime,
          }))
        );

        const updatedValues3 = {
          ...orderList3,
          "orderDateTime": currentDate,
        };
        setOrderList3((prevValues) => ({
          ...prevValues,
          "orderDateTime": currentDate,
        }));

        const updatedValues1 = {
          ...inputValues,
          "orderDate": formattedDate,
          "orderTime": currentTime,
          "orderConfirmedOn": currentDate,
          "deliveryDate": formattedDate,
          "orderDoneOn": currentDate,
          "kotDispatchTargetTime": currentTime,
          "totalAmt": totalamountmain,
          "grandTotalAmt": totalamountmain,

          "billTypeId": updatedData[0].id,
          "orderSeriesId": billSeries[0].id,
          "orderNo": (Number(orderNo) + 1),
          "autoOrderNo": (Number(orderNo) + 1),
          "orderConfirmedBy": userLogin.d1Id,
        };
        setInputValues((prevValues) => ({
          ...prevValues,
          "orderDate": formattedDate,
          "orderTime": currentTime,
          "orderConfirmedOn": currentDate,
          "deliveryDate": formattedDate,
          "orderDoneOn": currentDate,
          "kotDispatchTargetTime": currentTime,
        }));
        console.log("updatedValues1", updatedValues1)

        // ************************** Update Order *********************
        const updatedItems = cartData
          .map((item) => {
            if (item.ord2Id !== 0) {
              return null;
            } else {
              return item;
            }
          })
          .filter(Boolean);
        console.log(updatedValues1);
        console.log(updatedValues2);
        console.log(cartData);

        var lengthOfNewOrder = cartData.filter(item => item.ord1Id === 0)

        const updatedOrderValues1 = {
          ...inputValues,
          orderDate: formattedDate,
          orderTime: currentTime,
          orderConfirmedOn: currentDate,
          deliveryDate: formattedDate,
          orderDoneOn: currentDate,
          kotDispatchTargetTime: currentTime,
          totalAmt: totalamountmain,
          grandTotalAmt: totalamountmain,
          hasRunningOrder: true,
          runningOrderCounter: cartData[0].runningOrderCounter || 0 + lengthOfNewOrder.length,
          runningOrderTotalAmt: totalAmount,
          noOfPerson: cartData[0].noOfPerson,
          noOfAdult: cartData[0].noAdultPerson,
          noOfChild: cartData[0].noChildPerson,

        };

        if (cartData[0].ord1Id !== 0 && cartData[0].ord1Id !== undefined) {
          console.log("update");
          console.log("update", cartData[0].ord1Id);
          console.log(updatedItems);

          console.log(orderList)

          const updatedValues2 = orderList.map(item => ({
            ...item,
            itemRunningOrderCounter: item.itemRunningOrderCounter || 0 + 1,
            itemRunningQty: item.qty,
            preferenceNo: 100
          }));

          console.log("updatedvalue", updatedValues2);
          console.log(updatedOrderValues1);
          const order1Id = cartData[0].ord1Id;
          // console.log(order1Id);
          //return
          //debugger;
          const success = await Order.updateOrder(
            updatedOrderValues1,
            updatedValues2,
            updatedValues3,
            order1Id,
            totalamountmain
          );

          // var width = window.screen.width;
          // var height = window.screen.height;

          if (success === true) {
            alert("Your Order has been Updated");
            setTotalAmount([0]);
            localStorage.removeItem("orderList");
            localStorage.removeItem("itemCount");
            localStorage.removeItem("orderList3");
            localStorage.removeItem("cartNumber");
            localStorage.removeItem("cartData");
            setLoading(false)
            // window.open(`http://122.168.117.143:44483/OrderTakerView`, '_blank', 'width=' + width + ', height=' + height);
            window.location.href = `http://122.168.117.143:44483/OrderTakerView`;
            //alert("close")
            // window.open("http://122.168.117.143:44483/OrderTakerView");
            //window.close()
          }
          return true;
        } else {
          console.log("insert");
          savedata(updatedValues1, updatedValues2, updatedValues3);
        }
      }
    } else {
      setLoading(false);
      myaccountmodal();
    }

  };

  useEffect(() => {
    console.log("cartData");
    console.log(cartData);
    //onFloorSelect(cartData[0].tableGroupId)
    if (cartData && cartData.length > 0) {
      // cartData exists and has at least one element
      onFloorSelect(cartData[0].tableGroupId);
    } else {
      // cartData is either null, undefined, or empty
      // Handle the case when cartData doesn't have any value
    }
    if (cartData && cartData.length > 0) {

      // alert(cartData[0].deliveryDate);

      const initialDate = cartData[0].deliveryDate;
      const formattedDate = moment(initialDate).format('DD-MM-YYYY');

      //const deliveryDate = formatDate();
      console.log("deliveryDate", formattedDate)
      setOrderData((prevValues) => ({
        ...prevValues,
        "tableId": cartData[0].tableId,
        "tableGroupId": cartData[0].tableGroupId,
        "deliveryTimeId": cartData[0].deliveryTimeId,
        "deliveryDate": formattedDate,

      }));
      //  setTableGroup(cartData[0].tableGroupId)


      setInputValues((prevValues) => ({
        ...prevValues,
        "tableId": cartData[0].tableId,
        "tableGroupId": cartData[0].tableGroupId,
        "serviceTypeId": cartData[0].serviceTypeId,
        "deliveryTimeId": cartData[0].deliveryTimeId,
        "deliveryDate": formattedDate,
      }));
      //console.log("cartData.serviceTypeId:" + cartData[0].serviceTypeId);

      //if (cartData && cartData.length > 0) {
      setSelectedService(cartData[0].serviceTypeId);
      setSelectedServiceID(cartData[0].serviceTypeId);
    }

  }, [])

  // **********************************
  const setAllotedTimeSlot = async (updatedValues) => {
    try {
      setLoading(true);
      const data = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(
        updatedValues
      );
      console.log(data);

      if (data) {
        setAllottedTimeSlot(data);

      } else {
        setAllottedTimeSlot("");
        //alert("No data found");
      }
      setLoading(false)
      setOrderData((prevValues) => ({
        ...prevValues,
        "deliveryTimeId": data && data[0] && data[0].id !== undefined ? data[0].id : "",
      }));

      //  setTableGroup(cartData[0].tableGroupId)

      setInputValues((prevValues) => ({
        ...prevValues,
        "deliveryTimeId": data && data[0] && data[0].id !== undefined ? data[0].id : "",
      }));
    } catch (error) {
      setLoading(false)
      console.error("Error fetching time slots:", error);
    }
  }


  const [OpenTableSelection, setOpenTableSelection] = useState(false);
  const [OpenPaymentSelection, setPaymentSelection] = useState(false);

  const GoToPayment = () => {
    setOpenTableSelection(false);
    openPayment();
  };
  const openTable = () => {
    setOpenTableSelection(true);
  };
  const CloseTable = () => {
    setOpenTableSelection(false);
  };
  const openPayment = () => {
    setPaymentSelection(true);
  };
  const ClosePayment = () => {
    setPaymentSelection(false);
  };

  const ourMenuClick = () => {
    navigate("/OurMenu");
  }

  return (
    <div className="cart-page">
      <div className="cartpage-left-section">

        {LoginShow && (
          <div className="cartpage-right-section-2">

            <label style={{ border: "0" }} className="title dis-fl-fl-co">
              Account
            </label>
            <p
              style={{ margin: "0", fontWeight: "600", color: "gray" }}
              className="f-12"
            >
              To place your order now, log in to your existing account or sign
              up.
            </p>
            <div className="cartpage-service-options-box-1">
              <div
                className="cartpage-service-options-2"
                onClick={myaccountmodal}
              >
                <p style={{ margin: "0" }} className="f-14">
                  Have an account?
                </p>
                <p style={{ margin: "0" }} className="f-15">
                  LOG IN
                </p>
              </div>
              <div
                onClick={myaccountmodal1}
                className="cartpage-service-options-2"
              >
                <p style={{ margin: "0" }} className="f-14">
                  New to Mother's hut
                </p>
                <p style={{ margin: "0" }} className="f-15">
                  SIGN UP
                </p>
              </div>
            </div>
          </div>
        )}
        {RemoveOnMobile && (
          <div className="cartpage-right-section-1" id="service-type-in-mycart">
            <label style={{ border: "0" }} className="title dis-fl-fl-co">
              Service type
            </label>
            <div
              className="cartpage-service-options-box"
              style={{ paddingTop: "0" }}
            >
              {CompanyDetail.isEnableHomeDelivery === true ? (
                <div
                  className="cartpage-service-options"
                  onClick={() =>
                    handleServiceChange({
                      target: { id: `${serviceType[1]?.id}` },
                    })
                  }
                >
                  <div className="cartoage-input-box">
                    <input
                      type="radio"
                      name="serviceType"
                      value={inputValues.serviceTypeId}
                      checked={serviceType[1]?.id == selectedService}
                      onClick={() =>
                        handleServiceChange({
                          target: { id: `${serviceType[1]?.id}` },
                        })
                      }
                    />
                    <div>
                      <p style={{ margin: "0" }} className="f-14">
                        {serviceType.length > 0 && <>{serviceType[1]?.name}</>}{" "}
                        {/* Render name if serviceType is available */}
                        <i
                          style={{ marginLeft: "5px" }}
                          className="fa-solid fa-house"
                        ></i>
                      </p>
                      <p className="service-type-paragraph">
                        Delivery amount must be Rs 0 to deliver items
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              {CompanyDetail.isEnablePickUp === true ? (
                <div
                  className="cartpage-service-options"
                  onClick={() =>
                    handleServiceChange({ target: { id: serviceType[2]?.id } })
                  }
                >
                  <div className="cartoage-input-box">
                    <input
                      type="radio"
                      name="serviceType"
                      value={inputValues.serviceTypeId}
                      checked={serviceType[2]?.id == selectedService}
                      onClick={() =>
                        handleServiceChange({
                          target: { id: `${serviceType[2]?.id}` },
                        })
                      }
                    />
                    <div>
                      <p style={{ margin: "0" }} className="f-14">
                        {/* {serviceType[2].name} */}
                        {serviceType.length > 0 && <>{serviceType[2]?.name}</>}
                        <i
                          style={{ marginLeft: "5px" }}
                          className="fa-solid fa-car"
                        ></i>
                      </p>
                      <p className="service-type-paragraph">
                        You will pick up the order yourself at the restaurant
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              {CompanyDetail.isEnableTblOrder === true ? (
                <div
                  className="cartpage-service-options"
                  onClick={() =>
                    handleServiceChange({
                      target: { id: `${serviceType[0]?.id}` },
                    })
                  }
                >
                  <div className="cartoage-input-box">
                    <input
                      type="radio"
                      name="serviceType"
                      value={inputValues.serviceTypeId}
                      checked={serviceType[0]?.id == selectedService}
                      onClick={() =>
                        handleServiceChange({
                          target: { id: `${serviceType[0]?.id}` },
                        })
                      }
                    />
                    <div>
                      <p style={{ margin: "0" }} className="f-14">
                        {/* {serviceType[0].name} */}
                        {serviceType.length > 0 && <>{serviceType[0]?.name}</>}
                        <i
                          style={{ marginLeft: "5px" }}
                          className="fa-solid fa-couch"
                        ></i>
                      </p>
                      <p className="service-type-paragraph">
                        Your order will be delivered to your table
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

        )}

        {RemoveOnMobile && (
          <TableAndAddress
            AllottedTimeSlot={AllottedTimeSlot}
            dateOptions={dateOptions}
            serviceType={serviceType}
            inputValues={inputValues}
            openaddress={openaddress}
            ChangeDeliveryDate={ChangeDeliveryDate}
            CompanyDetail={CompanyDetail}
            isInputDisabled={isInputDisabled}
            tableGroup={tableGroup}
            tableNumberData={tableNumberData}
            onTableSelect={onTableSelect}
            onFloorSelect={onFloorSelect}
            ChangeDeliveryTime={ChangeDeliveryTime}
            totalAmount={totalAmount}
            selectedService={selectedService}

          />
        )}

        {RemoveOnMobile && (
          <PaymentModeSelection
            handlePayment={handlePayment}
            cod={cod}
            pod={pod}
            payment={payment}
          />
        )}
      </div>

      <div className="cartpage-right-section">
        <div className="cartpage-mastar-container">
          <div
            className="card-cart cart w-95"
            style={{
              maxHeight: "515px",
              overflow: "scroll",
            }}
          >
            <label
              className="title"
              style={{ borderBottom: "1px solid #e5e5e5" }}
            >
              Your cart
            </label>

            {filteredProducts.length === 0 ? (
              <div className="mycart-empty-txt-box-1">
                <img
                  className="mycart-empty-image"
                  src="https://mothershut.com/RestoFolders/MOTHERSHUT_Supela_Bhilai/Cart_empty.png"
                  alt=""
                />
                <p className="mycart-empty-txt" onClick={ourMenuClick}>Your Order cart is empty.</p>
                <p className="mycart-empty-txt" onClick={ourMenuClick}>Click to add an item.</p>
                <p className="mycart-empty-txt-2">
                  Cooked by mothers with love and care
                </p>
              </div>
            ) : (
              filteredProducts.map((product) => (
                <div
                  key={product.id}
                  style={{ padding: "5px" }}
                  className="products"
                >
                  <div className="product">
                    <div className="products-cart-box-1">
                      <img
                        style={{ width: "40px" }}
                        className="image-for-big"
                        src={`${imageBaseUrl}${product.images}`}
                        alt=""
                      />

                      <div style={{ marginLeft: "10px" }}>
                        <span className="f-12">{product.name}</span>
                        {/* <p className="menu-card-detail f-11">{product.name}</p> */}
                      </div>
                    </div>

                    <div className="dfjcc">
                      <input
                        className="mycart-suggestion-box"
                        type="text"
                        placeholder="Your suggestion"
                        onChange={(e) => OrderUpdate(e, product.d1Id)}
                        // value={orderList.itemSuggestion}
                        name="itemSuggestion"
                      />
                      <label
                        className="mycart-file-box"
                        htmlFor={`file-input-${product.d1Id}`}
                        style={{ cursor: "pointer" }}
                      >
                        {typeof fileNames[product.d1Id] === "string"
                          ? fileNames[product.d1Id]
                          : "Click to add image"}
                      </label>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id={`file-input-${product.d1Id}`}
                        accept="image/*"
                        onChange={(e) => handleFileChange(e, product.d1Id)}
                      />
                    </div>

                    {selectedService === `${serviceType[1]?.id}` ? (
                      <div className="mycart-quantitybox">
                        {product.availableForHomeDelivery === false && (
                          <p
                            style={{ padding: "10px", textAlign: "center" }}
                            className="mycart-productnotavli"
                          >
                            Not Avaliable for Home delivery
                          </p>
                        )}
                        {product.availableForHomeDelivery === true && (
                          <div
                            className="quantity"
                            style={{ width: "max-width" }}
                          >
                            <button
                              style={{ paddingBottom: "4px" }}
                              className="quantity__minus"
                              onClick={() => RemoveItemInCart(product.d2Id)}
                            >
                              -
                            </button>
                            <input
                              className="quantity__input"
                              type="text"
                              value={product.quantity}
                              readOnly
                            />
                            <button
                              className="quantity__plus"
                              onClick={() => addingItemInCart(product.d1Id)}
                            >
                              +
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="mycart-quantitybox">
                        <div
                          className="quantity"
                          style={{ width: "max-width" }}
                        >
                          <button
                            style={{ paddingBottom: "4px" }}
                            className="quantity__minus"
                            onClick={() => RemoveItemInCart(product.d2Id)}
                          >
                            -
                          </button>
                          <input
                            className="quantity__input"
                            type="text"
                            value={product.quantity}
                            readOnly
                          />
                          <button
                            className="quantity__plus"
                            onClick={() => addingItemInCart(product.d2Id)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    )}

                    <label
                      className="price small f-13"
                      style={{ width: "50px", textAlign: "center" }}
                    >
                      {selectedService === `${serviceType[1]?.id}` ? (
                        <>
                          {product.availableForHomeDelivery === false && (
                            <div>
                              <i
                                className="fa-solid fa-trash-can mycart-deletebtn"
                                onClick={() => RemoveCart(product.d2Id)}
                              ></i>
                            </div>
                          )}
                          {product.availableForHomeDelivery === true && (
                            <>
                              <i
                                style={{ marginRight: "3px" }}
                                className="fa-solid fa-indian-rupee-sign"
                              ></i>
                              {product.salePrice * product.quantity}

                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <i
                            style={{ marginRight: "3px" }}
                            className="fa-solid fa-indian-rupee-sign"
                          ></i>
                          {product.salePrice * product.quantity}

                        </>
                      )}
                    </label>
                  </div>
                </div>
              ))
            )}
          </div>

          {/* {showToast && (
            <div className="toastDiv" >
              Item Added Successfully !!
            </div>
          )} */}
          <div className="card-cart coupons w-95" style={{ display: "none" }}>
            <button className="opencoupon-btn" onClick={opencoupon}>
              Add coupon
            </button>
            <label className="title fs-m">Apply coupons</label>

            <div className="form">
              <input
                type="text"
                placeholder="Apply your coupons here"
                className="input_field"
              />
              <button onClick={opencoupon}>Apply</button>
            </div>
          </div>
          <div
            className="card-cart checkout w-95"
            style={{ minHeight: "150px" }}
          >
            <label className="title">Place Order</label>
            <div className="details">
              <span>Your cart subtotal:</span>
              {/* .toFixed(2) */}
              <span>

                <i
                  style={{ marginRight: "5px" }}
                  className="fa-solid fa-indian-rupee-sign"
                ></i>
                {totalAmount !== undefined ? totalAmount : totalAmount}
              </span>


              <span>Discount through applied coupons:</span>
              <span>

                <i
                  style={{ marginRight: "5px" }}
                  className="fa-solid fa-indian-rupee-sign"
                ></i>
                {/* {totalAmount / 20} */}0
              </span>
              <span>Shipping fees:</span>
              <span>

                <i
                  style={{ marginRight: "5px" }}
                  className="fa-solid fa-indian-rupee-sign"
                ></i>
                {/* {totalAmount / 25}{" "} */}0
              </span>
            </div>

            <div className="checkout--footer price">
              <h4> Total Amount</h4>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <label style={{ fontSize: "16px" }} className="price">
                  <i
                    style={{ marginLeft: "5px" }}
                    className="fa-solid fa-indian-rupee-sign"
                  ></i>
                  {totalAmount !== undefined ? totalAmount : "N/A"}{" "}
                </label> &nbsp; &nbsp;
                {RemoveOnMobile && (
                  <button
                    className="checkout-btn"
                    onClick={checkout}
                    style={{ cursor: "pointer" }}
                  >
                    Place Order
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {OpenPaymentSelection && (
        <PaymentModeSelection
          handlePayment={handlePayment}
          cod={cod}
          pod={pod}
          payment={payment}
          totalAmount={totalAmount}
          ClosePayment={ClosePayment}
        />
      )}

      {OpenTableSelection && (
        <TableAndAddress
          AllottedTimeSlot={AllottedTimeSlot}
          dateOptions={dateOptions}
          serviceType={serviceType}
          inputValues={inputValues}
          openaddress={openaddress}
          ChangeDeliveryDate={ChangeDeliveryDate}
          CompanyDetail={CompanyDetail}
          isInputDisabled={isInputDisabled}
          tableGroup={tableGroup}
          tableNumberData={tableNumberData}
          onTableSelect={onTableSelect}
          onFloorSelect={onFloorSelect}
          ChangeDeliveryTime={ChangeDeliveryTime}
          totalAmount={totalAmount}
          CloseTable={CloseTable}
          GoToPayment={GoToPayment}
          selectedService={selectedService}
        />
      )}

      {mobilesection1 && (
        <MobilesSection1
          OrderType={OrderType}
          totalAmount={totalAmount}
          checkout={checkout}
          selectedService={selectedService}
          inputValues={inputValues}
          tableNumberData={tableNumberData}
          openTable={openTable}
          showchange={showchange}
          AllottedTimeSlot={AllottedTimeSlot}
          filteredTable={filteredTable}
          setFilteredTable={setFilteredTable}
        />
      )}
      {mobilesection2 && (
        <MobilesSection2
          handleServiceChange={handleServiceChange}
          inputValues={inputValues}
          serviceType={serviceType}
          openTable={openTable}
          showchange={showchange}
          gotofrist={gotofrist}
          setInputValues={setInputValues}
        />
      )}
      {mobilesection3 && (
        <MobilesSection3 logindone={logindone} gotofrist={gotofrist} />
      )}

      {addCoupon && <AddCouponModal close={opencoupon} />}
      {addAddress && <AddAddressModal close={openaddress} />}
      {loading && <Loader />}
    </div>
  );
};
export default MyCart;
