import React from "react";
import { Link } from "react-router-dom";

const   ProductCard = (props) => {

  const cardStyle = {
    backgroundImage: `url(${props.imageBaseUrl}${props.Imageid})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <Link to="/ourmenu" className="card">
      <div className="content">
        <div className="back">
          <div className="back-content" style={cardStyle}>
            <svg
              stroke="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              height="50px"
              width="50px"
              fill="#ffffff"
            >
              <g stroke-width="0" id="SVGRepo_bgCarrier"></g>

              <g
                stroke-linejoin="round"
                stroke-linecap="round"
                id="SVGRepo_tracerCarrier"
              ></g>
            </svg>

            <strong className="strong-1">
              {props.heading}
              <i style={{ marginLeft: "5px" }} className={props.icon}></i>
            </strong>
          </div>
        </div>
        <div className="front">
          <div className="img">
            <div className="circle"></div>
            <div className="circle" id="right"></div>
            <div className="circle" id="bottom"></div>
          </div>

          <div className="front-content">
            <small className="badge">
              <i className={props.icon}></i>
            </small>
            <div className="description">
              <div className="title">
                <p className="title" style={{borderBottom: "1px solid #979797"}}>
                  <strong>
                    {props.heading}
                    <i style={{ marginLeft: "2px" }} className={props.icon}></i>
                  </strong>
                </p>
              </div>
              <p className="card-footer">
                {props.timing} &nbsp; | &nbsp; 1 Serving
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
