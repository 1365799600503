import React from "react";
import SocialMediaLinks from "./SocialMediaLinks";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      {/* <div className="footer-top">footer top</div> */}
      <div className="footer-top">
        <div className="footer-top-part">
          <h1 className="footer-text-header">Help</h1>
          <div>
            <Link to="/TermsAndConditions" className="footer-text-link">Terms & Conditions</Link>
            <p className="footer-text-link">Cancellation Policy</p>
            <p className="footer-text-link">Return Policy</p>
            <p className="footer-text-link">Refund Policy</p>
            <p className="footer-text-link">Replacement Policy</p>
            <p className="footer-text-link">Delivery / Shipping Policy</p>
            <p className="footer-text-link">Contact Us</p>
            <p className="footer-text-link">Download App</p>
          </div>
        </div>
        <div className="footer-top-part">
          <h1 className="footer-text-header">Links</h1>
          <div>
            <p className="footer-text-link">Mother's Feedback</p>
            <p className="footer-text-link">COD Feedback</p>
            <p className="footer-text-link">OT Feedback</p>

          </div>
        </div>
        <div className="footer-top-part">
          <h1 className="footer-text-header">Feedback</h1>
          <div>
            <p className="footer-text-link">Homepage</p>
            <p className="footer-text-link">Our Menu</p>
            <p className="footer-text-link">My Favourite</p>
            <p className="footer-text-link"> My Cart</p>
            <p className="footer-text-link">My Account</p>
          </div>
        </div>
        <div className="footer-top-part media-links">
          
          <SocialMediaLinks />
          
        </div>

      </div>
      <div className="footer-bottom">
        Copyright © 2023 Mother,s Hut | All rights reserved. Developed by
        Techcherry
      </div>
    </div>
  );
};

export default Footer;
