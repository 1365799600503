import React, { useEffect, useState } from "react";
import AccountAPI from "../API/API_SERVISES/Account";
import { useNavigate } from "react-router-dom";

const OrderBox = (props) => {

  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState({});

  const toggleClass = (id) => {
    setIsClicked(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };
  const OpenTrackModal = (orderNo) => {

    navigate("/TrackOrderStatus", {
      state: {
        orderNo
      }
    });
  };
  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);

    const fetchData = async () => {
      props.setLoading(true)
      var orderlistData = await AccountAPI.getMyOrderList(loginData.d1Id);
      // console.log(orderlistData);
      setOrderList(orderlistData);
      if (orderlistData) {
        props.setLoading()
      }
      else {
        alert("error")
      }
    };
    fetchData();
  }, []);
  let prevOrderId = null;

  return (
    <div style={{ display: "flex", flexDirection: "column-reverse" }}>
      {/* {orderList.map((order) => ( */}

      {orderList.filter((data) => {
        const isDifferentOrderId = data.ord1Id !== prevOrderId;
        prevOrderId = data.ord1Id;
        return isDifferentOrderId;
      }).map((order) => (
        <div key={order.ord1Id}
          className={` ${isClicked[order.ord1Id] ? "custom-div" : ""} my-order-table`}>
          <div className="order-box-top-top">
            <div className="order-box-top">
              <div className="order-detail-list" style={{ width: "65%" }}>
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <p className="ordertxt-1">Order No : {order.orderNo},&nbsp; &nbsp;
                    {/* <label style={{ color: order.overallOrderStatus === "cancel" ? "darkred" : "darkgreen" }}>{order.overallOrderStatus === "cancel" ? "Order has Cancelled" : order.overallOrderStatus}</label> */}
                  </p>
                  {/* {order.overallOrderStatus == null &&( */}
                  <>
                    {
                      order.overallOrderStatus === "cancel" ? (
                        <div style={{ backgroundColor: "#8b000029", maxWidth: "fitContent", borderRadius: "5px" }}>
                          <label style={{ color: "darkred", fontSize: "11px", fontWeight: "600", display: "flex" }}>&nbsp;Cancelled &nbsp;</label>
                        </div>
                      ) : order.overallOrderStatus === "" ? (
                        <div></div>
                      ) : (
                        <div style={{ backgroundColor: "rgba(104, 189, 104, 0.24)", maxWidth: "fitContent", borderRadius: "5px" }}>
                          <label style={{ color: "darkgreen", fontSize: "11px", fontWeight: "600", display: "flex" }}>&nbsp; {order.overallOrderStatus} &nbsp;</label>
                        </div>
                      )
                    }

                  </>
                  {/* )} */}

                </div>
                <p className="ordertxt-2">Order Date and Time  : {`${new Date(order.orderDate).getDate().toString().padStart(2, '0')}-${(new Date(order.orderDate).getMonth() + 1).toString().padStart(2, '0')}-${new Date(order.orderDate).getFullYear()}`} - - {order.orderTime} </p>
                <p className="ordertxt-2" >
                  Service Type : {order.serviceTypeName}
                  {order.serviceTypeId === 751 && (
                    <>
                      | Table Name : {order.tableName}
                    </>
                  )}
                </p>

                <p className="ordertxt-2">Delivery Date :     {`${new Date(order.deliveryDate).getDate().toString().padStart(2, '0')}-${(new Date(order.deliveryDate).getMonth() + 1).toString().padStart(2, '0')}-${new Date(order.deliveryDate).getFullYear()}`}
                </p>
                <p className="ordertxt-2">
                  Delivery Time Slot : 17:00 To 17:30
                </p>
              </div>

              <div
                style={{ width: "35%", alignItems: "end" }}
                className="order-detail-list"
              >
                <p className="ordertxt-1">Sub Amt : {order.o_TotalAmt}</p>
                <p className="ordertxt-1">Delivery : {order.deliveryCharges}</p>
                <p style={{ marginBottom: "15px" }} className="ordertxt-1">
                  Total Amt : {order.o_TotalAmt}
                </p>
                <p
                  style={{ marginBottom: "0px", color: "rgb(51, 51, 244)", cursor: "pointer" }}
                  className="ordertxt-3"
                  onClick={() => toggleClass(order.ord1Id)}
                >
                  See detail &gt;
                </p>
                <p
                  style={{
                    marginBottom: "0px", color: "rgba(189, 5, 22, 0.74)", cursor: "pointer",
                    display: order.overallOrderStatus === "cancel" ? "none" : "block"
                  }}
                  className="ordertxt-3"
                  onClick={() => OpenTrackModal(order.orderNo)}
                >
                  Track Status &gt;
                </p>
              </div>
            </div>
            <div>
              <div
                style={{
                  borderRadius: "15px",
                  // height: "350px",
                  boxShadow: "none",
                  width: "100%",
                  padding: "0",
                  marginBottom: "0",
                }}
                className="card-cart cart "
              >
                <label className="title" style={{ marginTop: "8px", fontSize: "14px" }}>My Order </label>

                <div style={{ padding: "5px" }} className="products">
                  {orderList
                    .filter((itemdata) => itemdata.ord1Id === order.ord1Id)
                    .map((item) => (
                      // Your JSX mapping logic here

                      <div
                        key={item.id}
                        style={{
                          width: "95%",
                          paddingBottom: "5px",
                          marginBottom: "5px",
                          borderRadius: "0px",
                          height: "40px",
                        }}
                        className="product"
                      >
                        <div className="products-left">
                          <img
                            style={{ width: "40px", borderRadius: "8px" }}
                            // src={product.image}
                            alt=""
                          />
                          <div>
                            <span style={{ fontSize: "12px" }}>
                              {item.d2_ItemName}
                            </span>
                            <p style={{ fontSize: "11px" }}>
                              {" "}
                              Quantity : {item.i_Qty}
                            </p>
                          </div>
                        </div>
                        <div
                          className="products-right"
                          style={{ width: "20%", justifyContent: "end" }}
                        >
                          <label
                            className="price small"
                            style={{ marginRight: "20px" }}
                          >
                            {item.i_Rate}
                            <i className="fa-solid fa-indian-rupee-sign"></i>
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderBox;
