import React, { useState } from "react";

const Dropdownnew = () => {
  //   const showsubcate = () => {};
  const [subcate1, setSubcate1] = useState(false);

  const [childcate1, setChildcate1] = useState(false);

  const [childcate2, setChildcate2] = useState(false);

  const [childcate3, setChildcate3] = useState(false);

  // Event handler for checkbox click
  const handleCheckboxClick = () => {
    setSubcate1(!subcate1);
  };

 
  const openchild1 =()=>{
    setChildcate1(!childcate1)
  }
 
  const openchild2 =()=>{
    setChildcate2(!childcate2)
  }
 
  const openchild3 =()=>{
    setChildcate3(!childcate3)
  }



  return (
    <div className="drop-down-new">
      <div className="category-main">
        <input
          type="checkbox"
          name=""
          id="cate-1"
        />{" "}
        <div onClick={handleCheckboxClick}>Category</div>
      </div>
      {subcate1 && (
        <div className="sub-category-box">
          <div className="sub-category-txt" >
            <input type="checkbox" name="" id="" />
            <div  onClick={openchild1}>subcategory1</div>
          </div>

          {childcate1 && (
            <div className="child-category-box">
              <div className="sub-category-txt">
                <input type="checkbox" name="" id="" />
                <div>child category </div>
              </div>
              <div className="sub-category-txt">
                <input type="checkbox" name="" id="" />
                <div>child category </div>
              </div>
              <div className="sub-category-txt">
                <input type="checkbox" name="" id="" />
                <div>child category </div>
              </div>
            </div>
          )}

          <div className="sub-category-txt" >
            <input type="checkbox" name="" id="" />
            <div onClick={openchild2}>subcategory2</div>
          </div>

          {childcate2 && (
            <div className="child-category-box">
              <div className="sub-category-txt">
                <input type="checkbox" name="" id="" />
                <div>child category </div>
              </div>
              <div className="sub-category-txt">
                <input type="checkbox" name="" id="" />
                <div>child category </div>
              </div>
              <div className="sub-category-txt">
                <input type="checkbox" name="" id="" />
                <div>child category </div>
              </div>
            </div>
          )}

          <div className="sub-category-txt">
            <input type="checkbox" name="" id="" />
            <div  onClick={openchild3}>subcategory3</div>
          </div>

          {childcate3 && (
            <div className="child-category-box">
              <div className="sub-category-txt">
                <input type="checkbox" name="" id="" />
                <div>child category </div>
              </div>
              <div className="sub-category-txt">
                <input type="checkbox" name="" id="" />
                <div>child category </div>
              </div>
              <div className="sub-category-txt">
                <input type="checkbox" name="" id="" />
                <div>child category </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdownnew;
