import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import MainModalComponent2 from "./MainModalComponent2";

const express_server_url = process.env.REACT_APP_SERVER_URL;
const mapStyles = {
  width: '100%',
  height: '400px'
};

function DistanceCalculator({ google,setTriggerUseEffect }) {
  const [selectedAddress, setSelectedAddress] = useState('');
  const [distance, setDistance] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentCoordinates, setCurrentCoordinates] = useState(null);
  const [locationConfirmed, setLocationConfirmed] = useState(false)

  useEffect(() => {
    getLocation();
  },[]);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentCoordinates({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const openGoogleMaps = () => {
    getLocation();
    setShowModal(true);
    fetchDistance(currentCoordinates.lat, currentCoordinates.lng)
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const confirmLocation = () => {
    const totalDistance = distance.rows[0].elements[0].distance.text;
    console.log("distance", distance);
    localStorage.setItem("googleMapData", JSON.stringify(distance));
    if (currentCoordinates) {
      console.log('Selected Coordinates:', currentCoordinates);
      closeModal();
    } else {
      console.error('No location selected.');
    }
    //const latitude_longitude =[{lat}]
    setTriggerUseEffect(true)
    setLocationConfirmed(true);
  };

  const handleSelectedAddressChange = (event) => {
    setSelectedAddress(event.target.value);
  };


  const fetchDistance = async (lat, lng) => {
    //alert(lat+"="+ lng )
    try {
      const apiUrl = `${express_server_url}/api/map/fetchDistance?origin=${currentCoordinates.lat},${currentCoordinates.lng}&destination=${lat},${lng}`;
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log('Response:', response.data);
      setDistance(response.data);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  };

  const onMapClicked = (mapProps, map, clickEvent) => {
    //alert(clickEvent.latLng.lat() +"+"+ clickEvent.latLng.lng())
      const latitude = clickEvent.latLng.lat();
      const longitude = clickEvent.latLng.lng();
      const coordinates = { latitude, longitude };

      const coordinatesString = JSON.stringify(coordinates);
      localStorage.setItem('coordinates', coordinatesString);

    setCurrentCoordinates({ lat: latitude, lng:longitude  });
    fetchDistance(latitude, longitude)
  };

  const [loading, setLoading] = useState(true);

  return (
    <div>
      <div className="input-container">
        <input
          type="text"
          value={selectedAddress}
          className="input-with-image"
          placeholder="⊕ Enter your delivery Address"
          onClick={openGoogleMaps}
          onChange={handleSelectedAddressChange}
        />
        <button className="input-btn" onClick={openGoogleMaps}>
          Change Address
        </button>
      </div>

      <div>
      {showModal && (
        <div className="modal-back1" >
            <div className='map-modal'> 
          <div className="" style={{width:"100%", top:"0%", }}>
             <div className="" onClick={closeModal} style={{width:"auto", display:"flex", justifyContent:"flex-end"}}>
          <i className="fa-solid fa-xmark" style={{color:"black", fontSize:"16px"}}></i>
        </div>
           <div>
              <div>
              {currentCoordinates && (
               <div className="testing" style={{width:"100%", height:"100%"}}>
                 <Map className="map-style"
                    google={google}
                    zoom={14}
                    initialCenter={currentCoordinates}
                    style={{ width: '100%', height: '400px', position:"relative" }}
                    onClick={onMapClicked}
                > 
                    <Marker position={currentCoordinates} />
                    {/* {selectedCoordinates && (
                    )} */}
                </Map>
               </div>
                )}
                </div>
           </div>
           <div>
           {(()=>{
                if (distance !== null) {
                  console.log(distance.rows[0].elements[0].distance.text);
                  const dist = distance.rows[0].elements[0].distance.text.split(" ");
                  console.log(dist[0]);
      
                  return <div style={{backgroundColor:"white", padding:"5px", width:"100%", display:"flex", justifyContent:"center"}}>
                           {/* <label style={{color:"red", fontWeight:"600"}}>Your Addresss : </label> */}
                           <label style={{color:"blue", fontWeight:"600"}}>Distance : {distance.rows[0].elements[0].distance.text}</label>
                        </div>;
                }                    
            })()} 
           </div>
           <div>
                {(()=>{
                    if (distance !== null) {
                        console.log(distance.rows[0].elements[0].distance.text);
                        const dist = distance.rows[0].elements[0].distance.text.split(" ");
                        console.log(dist[0]);
            
                        if (parseInt(dist[0]) >= 10) { 
                            return <div style={{backgroundColor:"white", padding:"5px", width:"100%", display:"flex", justifyContent:"center"}}>
                                <label style={{color:"red", fontWeight:"600"}}>Currently not delivering to this address</label>
                                </div>;
                        }
                        else{
                            return <button onClick={confirmLocation} className="confirmLocation">Confirm Location</button>
                        }
                    }                    
                })()} 
            </div>
          </div>
            </div>
        </div>
      )}
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyA3Z3M4ymJUaQnTnD5ZD8lLbGbXntTKLHI'
})(DistanceCalculator);


// import React, { useState, useEffect } from 'react';
// import $ from 'jquery';
// //import google from 'google-maps-react';

// function GoogleMap() {
//     const [showMap, setShowMap] = useState(false);
//     const [showSearchInput, setShowSearchInput] = useState(false);

//     //var map, marker, infoWindow, markers = [];

//     const openImageViewer = () => {
//         // try {
//         //     Android.statusGPSCheck();
//         // } catch (e) {
//         //     try {
//         //         statusGPSCheck.postMessage();
//         //     } catch (e) { }
//         // }

//         // map = null;
//         // marker = null;
//         // infoWindow = null;
//         // markers = [];
//         addScript();
//     };

//     useEffect(() => {
//         openImageViewer();
//         return () => {
//         };
//     }, []);

//     const addScript = () => {
//         $("#divloader").css("display", "block");

//         if ($("#initMapsrc").length === 0) {
//             try {
//                 const key = "AIzaSyA3Z3M4ymJUaQnTnD5ZD8lLbGbXntTKLHI"; //localStorage.getItem("s_g_api_key");
//                 const src = `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap&libraries=places`;
//                 const tag = document.createElement('script');
//                 tag.async = true;
//                 tag.defer = true;
//                 tag.setAttribute("id", "initMapsrc");
//                 tag.setAttribute('src', src);
//                 $("#mapSettingtcDiv").html(tag);
//             }
//             catch (e) {
//                 initMap();
//             }
//         } else {
//             initMap();
//         }

//         // Hide loader when the map is loaded
//         $("#divloader").css("display", "none");
//     };

//     // Function to initialize the map
//     window.initMap = () => {
//         // Initialize your map here
//     };

//     //const [map, setMap] = useState(null);
//     //const [infoWindow, setInfoWindow] = useState(null);

//     useEffect(() => {
//         initMap();
//     }, []);

//     const [latitude, setLatitude] = useState(null);
//     const [longitude, setLongitude] = useState(null);

//     const getLocation = () => {
//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition((position) => {
//                 setLatitude(position.coords.latitude);
//                 setLongitude(position.coords.longitude);
//             }, (error) => {
//                 console.error('Error getting location:', error);
//             });
//         } else {
//             console.error('Geolocation is not supported by this browser.');
//         }
//     };

//     const initMap = () => {
//         getLocation();
//         const map = new window.google.maps.Map(document.getElementById('map'), {
//             center: { lat: latitude, lng: longitude },
//             zoom: 17,
//             myLocationEnabled: true, // Notice the lowercase 'm' in 'myLocationEnabled'
//             setMyLocationButtonEnabled: true,
//             zoomControl: true,
//             disableDefaultUI: true,
//             mapTypeId: window.google.maps.MapTypeId.ROADMAP
//         });
        
//         setMap(map);
//         initSearchInput(map);
//         setInfoWindow(new window.google.maps.InfoWindow);

//         if (navigator.geolocation) {
//             navigator.geolocation.getCurrentPosition(
//                 position => {
//                     $(".getloadMapViewer").css("display", "none");
//                     $(".loadMapViewer").css("display", "block");

//                     var latitude_longitude = $("#hdn_latitude_longitude").val();
//                     var pos;
//                     var ilatitude_longitude = latitude_longitude.split(',');
//                     if (ilatitude_longitude.length === 2) {
//                         if (ilatitude_longitude[0] !== "" && ilatitude_longitude[1] !== "") {
//                             pos = new window.google.maps.LatLng(ilatitude_longitude[0], ilatitude_longitude[1]);
//                         } else {
//                             pos = { lat: position.coords.latitude, lng: position.coords.longitude };
//                         }
//                     } else {
//                         pos = { lat: position.coords.latitude, lng: position.coords.longitude };
//                     }
//                     addDragableMarker(pos);
//                 },
//                 () => {
//                     handleLocationError(true, infoWindow, map.getCenter());
//                 }
//             );
//         } else {
//             handleLocationError(false, infoWindow, map.getCenter());
//         }
//     };

//     const handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
//         infoWindow.setPosition(pos);
//         infoWindow.setContent(browserHasGeolocation ?
//             'Error: The Geolocation service failed.' :
//             'Error: Your browser doesn\'t support geolocation.');
//         infoWindow.open(map);
//     };

//     const [map, setMap] = useState();
//     const [marker, setMarker] = useState();
//     const [infoWindow, setInfoWindow] = useState();
//     const [geocoder, setGeocoder] = useState();
//     const [markers, setMarkers] = useState([]);
//     const [showLoader, setShowLoader] = useState(false);

//     useEffect(() => {
//         const initMap = () => {
//             // Initialize map
//             setMap(new window.google.maps.Map(document.getElementById("map"), {
//                 center: { lat: 23.3698269, lng: 88.4821275 },
//                 zoom: 8,
//                 mapTypeId: window.google.maps.MapTypeId.ROADMAP
//             }));

//             // Initialize info window
//             setInfoWindow(new window.google.maps.InfoWindow());

//             // Initialize geocoder
//             setGeocoder(new window.google.maps.Geocoder());
//         };

//         // Load Google Maps API
//         const script = document.createElement('script');
//         script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places`;
//         script.async = true;
//         script.defer = true;
//         script.onload = initMap;
//         document.head.appendChild(script);

//         return () => {
//             // Clean up
//             document.head.removeChild(script);
//         };
//     }, []);

//     const addDragableMarker = (pos) => {
//         DeleteMarkers();
//         const newMarker = new window.google.maps.Marker({
//             map: map,
//             draggable: true,
//             animation: window.google.maps.Animation.DROP,
//             position: pos,
//             title: "Drag me!"
//         });
//         setMarker(newMarker);
//         setMarkers([...markers, newMarker]);

//         infoWindow.setPosition(pos);
//         infoWindow.setContent('Drag me to set location.');
//         infoWindow.open(map, newMarker);
//         map.setCenter(pos);

//         if ($("#hdn_latitude_longitude").val() == "") {
//             geocodePosition(pos);
//         } else {
//             geocodePosition(pos);
//         }

//         window.google.maps.event.addListener(newMarker, 'dragend', function () {
//             $("#divloader").css("display", "block");
//             geocodePosition(newMarker.getPosition());
//         });
//     }

//     const geocodePosition = (pos) => {
//         geocoder.geocode({
//             latLng: pos
//         },
//             function (results, status) {
//                 console.log("geocodePosition 1=>" + window.google.maps.GeocoderStatus.OK);
//                 if (status === window.google.maps.GeocoderStatus.OK) {
//                     var formatted_address = results[0].formatted_address;
//                     console.log("geocodePosition 2" + formatted_address);
//                     $("#btnRegistrationSaveAddress").css("display", "none");
//                     // More implementation...
//                 } else {
//                     $("#mapErrorMsg").html('Cannot determine address at this location.' + status).show(100);
//                 }
//                 $("#divloader").css("display", "none");
//             }
//         );
//     }

//     const DeleteMarkers = () => {
//         markers.forEach((m) => {
//             m.setMap(null);
//         });
//         setMarkers([]);
//     }

//     const distance_google_api = (LatLng_source, LatLng_distination, callback) => {
//         const service = new window.google.maps.DistanceMatrixService();
//         service.getDistanceMatrix({
//             origins: [LatLng_source],
//             destinations: [LatLng_distination],
//             travelMode: window.google.maps.TravelMode.DRIVING,
//             unitSystem: window.google.maps.UnitSystem.METRIC,
//             avoidHighways: false,
//             avoidTolls: false
//         },
//             function (response, status) {
//                 if (status === window.google.maps.DistanceMatrixStatus.OK && response.rows[0].elements[0].status !== "ZERO_RESULTS") {
//                     const distance = response.rows[0].elements[0].distance.value / 1000; // Convert meters to kilometers
//                     const duration = response.rows[0].elements[0].duration.text;
//                     // Update state or perform any action with distance and duration
//                     callback(distance, duration);
//                 } else {
//                     alert("Unable to find the distance via road.");
//                 }
//             });
//     }

//     const initSearchInput = () => {
//         const input = document.getElementById('searchInput');
//         map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

//         const autocomplete = new window.google.maps.places.Autocomplete(input);
//         autocomplete.bindTo('bounds', map);

//         autocomplete.addListener('place_changed', function () {
//             const place = autocomplete.getPlace();
//             if (!place.geometry) {
//                 window.alert("Autocomplete's returned place contains no geometry");
//                 return;
//             }

//             const pos = {
//                 lat: place.geometry.location.lat(),
//                 lng: place.geometry.location.lng()
//             };

//             // Handle the new position, possibly updating state
//             addDragableMarker(pos);
//         });
//     }

//     useEffect(() => {
//         initSearchInput();
//     }, []); // Run only once on component mount


//     return (
//         <div>
//             <div className="getloadMapViewer container" style={{ width: '100%' }}>
//                 <div className=" panel panel-default ">
//                     <div className="panel-body" style={{ padding: '80px 17px 17px 17px' }}>
//                         <div align="center">
//                             <div id="btnLocationGps" className="col-sm-12" style={{ display: 'block' }} align="center">
//                                 <div style={{ position: 'relative', width: '100%', border: '1px solid rgb(182, 223, 238)', background: 'rgb(255, 255, 255) none repeat scroll 0% 0%', borderRadius: '3px', display: 'flex', MozBoxAlign: 'center', alignItems: 'center' }} onClick={openImageViewer}>
//                                     <span style={{ margin: '0.5rem 0.8rem' }}>⊕</span>
//                                     <input placeholder="Enter your delivery address" style={{ width: '100%', height: '3rem', fontSize: '1rem', cursor: 'pointer', border: '0px none', borderRadius: '3px', outline: 'unset !important', WebkitWritingMode: 'horizontal-tb !important', textRendering: 'auto', color: '-internal-light-dark(black, white)', letterSpacing: 'normal', wordSpacing: 'normal', textTransform: 'none', textIndent: '0px', textShadow: 'none' }} />
//                                     <div style={{ alignSelf: 'center', position: 'absolute', bottom: '0.45rem', right: '0.45rem', top: '0.10rem' }}>
//                                         <button className="btnLocateMe" style={{ borderRadius: '3px', outline: 'currentcolor none medium', textTransform: 'uppercase', padding: '0.5em 1em', border: 'medium none', cursor: 'pointer', color: 'rgb(255, 255, 255)', backgroundColor: 'rgb(0, 171, 0)' }} >   {/*onClick={handleLocateMe}*/}
//                                             <div style={{ minWidth: '0.3em', minHeight: '0.3em', backgroundRepeat: 'no-repeat', width: '1.3rem', height: '1.3rem', position: 'absolute', top: '0.43rem' }}></div>
//                                             <span style={{ cursor: 'pointer', color: 'rgb(255, 255, 255)', textTransform: 'uppercase' }}>Locate Me</span>
//                                         </button>
//                                     </div>
//                                 </div>
//                                 <div className="clearfix"></div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <input id="searchInput" className="loadMapViewer form-control" type="text" placeholder="Search Area / Locality" style={{ position: 'absolute', top: '15px', left: '40%', border: '2px solid black', display: showSearchInput ? 'block' : 'none' }} />
//             <div id="map" className="loadMapViewer" style={{ display: showMap ? 'block' : 'none' }}></div>
//             <div id="map_service" style={{ display: 'none' }}></div>
//             <ul className="geo-data" style={{ display: 'none' }}>
//                 <li>Full Address: <span id="location"></span></li>
//                 <li>Postal Code: <span id="postal_code"></span></li>
//                 <li>Country: <span id="country"></span></li>
//                 <li>Latitude: <span id="lat"></span></li>
//                 <li>Longitude: <span id="lon"></span></li>
//                 <li>Distance: </li>
//             </ul>
//             <span id="geoDistance"></span>
//             <div id="mapSettingtcDiv"></div>
//             <div>
//                 <div id="map" style={{ height: '500px', width: '100%' }}></div>
//                 {showLoader && <div id="divloader" style={{ display: 'block' }}>Loading...</div>}
//             </div>
//         </div >
//     );
// }

// export default GoogleMap;
