import React from 'react'

const ReserPasswordPanel = (props) => {
    const {handleInputChangeSignIn ,
        ChangePassword,
        handleTogglePassword,
        handleTogglePasswordhide,
        showPassword,
        handleTogglePasswordhide1,
        handleTogglePassword1,
        inputValuesForSignIn,
        showPassword1,
    } = props

  return (
    <div className="form-container">
    <p className="title" style={{ margin: "0" }}>
      Change password
    </p>
    <form className="form" style={{ marginTop: "0px" }}>
      <div className="input-group">
        <label htmlFor="password">New Password</label>
        <input
          type={showPassword1 ? "text" : "password"}
          onChange={handleInputChangeSignIn}
          value={inputValuesForSignIn.password}
          name="password"
          id="password1"
          placeholder=""
        />
        {!showPassword1 && (
          <i
            onClick={handleTogglePassword1}
            className="fa-regular ShowHideIcon fa-eye"
          ></i>
        )}
        {showPassword1 && (
          <i
            onClick={handleTogglePasswordhide1}
            className="fa-regular ShowHideIcon fa-eye-slash"
          ></i>
        )}
      </div>
      <div className="input-group">
        <label htmlFor="password">Confirm Password</label>
        <input
          type={showPassword ? "text" : "password"}
          onChange={handleInputChangeSignIn}
          value={inputValuesForSignIn.reEnterpassword}
          name="reEnterpassword"
          id="password1"
          placeholder=""
        />

        {!showPassword && (
          <i
            onClick={handleTogglePassword}
            className="fa-regular ShowHideIcon fa-eye"
          ></i>
        )}
        {showPassword && (
          <i
            onClick={handleTogglePasswordhide}
            className="fa-regular ShowHideIcon fa-eye-slash"
          ></i>
        )}
      </div>
      <div
        style={{
          marginTop: "15px",
          cursor: "pointer",
          marginBottom: "10px",
        }}
        className="sign"
        onClick={ChangePassword}
      >
        Change Password
      </div>
    </form>
  </div>
  )
}

export default ReserPasswordPanel