import React, { useContext, useEffect, useState } from "react";
import VariableProductModal from "./VariableProductModal";
import MyContext from "./MyContext";
import ItemMasterApi from "../API/API_SERVISES/ItemMaster";
import { format } from "date-fns";

const ProductCardMenu = (props) => {
  const {
    product,
    onQuantityChange,
    onFavChange,
    onFavChangefalse,
    setCartData,
    cartData,
    // orderList3,
    orderList,
    setOrderList,
    setOrderList3,
    imageBaseUrl,
    addingItemInCart,
    RemoveItemInCart,
    productlist1,
  } = props;
  // console.log(product)

   


  const { selectedService } = useContext(MyContext);
  // console.log(selectedService)

  const [currentTimeforService, setCurrentTimeforService] = useState(
    getCurrentTime()
  );

  const [filteredArray, setFilteredArray] = useState([]);

  useEffect(() => {
    if (Array.isArray(product)) {
      const filteredResult = product.filter((item) => {
        var time = item.restoAvailableTime.length;
        // // console.log(time);

        if (time > 0) {
          let foundMatch = false;
          for (var i = 0; i < time; i++) {
            // // console.log(typeof(item.restoAvailableTime[i].availableTimeTo))
            var AvltimeFrom = item.restoAvailableTime[i].availableTimefrom; //? item.restoAvailableTime[i].availableTimeTo === null :""
            var AvltimeTo = item.restoAvailableTime[i].availableTimeTo; // ? item.restoAvailableTime[i].availableTimeTo === null :""
            // // console.log(AvltimeTo,"-------" , AvltimeFrom)

            const formattedTimeFrom = getCurrentTime1(AvltimeFrom);
            const formattedTimeTo = getCurrentTime1(AvltimeTo);

            // const currentTime = new Date(`1970-01-01T${currentTimeforService}`);
            // // console.log(  formattedTimeFrom, formattedTimeTo,currentTime);
            // // console.log(  formattedTimeFrom ,"---", formattedTimeTo,"---",currentTimeforService);

            // const availableTimeFrom = new Date(`1970-01-01T${formattedTimeFrom}`);
            // const availableTimeTo = new Date(`1970-01-01T${formattedTimeTo}`);
            // // console.log(  availableTimeFrom ,"---", availableTimeTo,"---",currentTime);

            if (
              currentTimeforService >= formattedTimeFrom &&
              currentTimeforService <= formattedTimeTo
            ) {
              foundMatch = true;
              break; // Exit the loop once a match is found
            }
          }

          return foundMatch; // Return true if a match was found, false otherwise
        } else {
          // console.log("Else");
          // Handle the case where time is 0 (or any other specific logic)
          return true; // No match if time is 0
        }
      });

      setFilteredArray(filteredResult);
      // // console.log(filteredArray);
    }
  }, [product, currentTimeforService]);

  //  useEffect(()=>{
  //   // console.log(currentTimeforService)

  //  const FilteredTimeslotData = product

  //  // console.log(FilteredTimeslotData)

  //  } ,[])

  // // console.log(filteredArray);
  // // console.log(currentTimeforService);

  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }

  // Sample time string
  // const timeString = "09:30:00"; // Format: HH:MM:SS
  function getCurrentTime1(AvalTime) {
    // Check if AvalTime is a string
    // // console.log(AvalTime)
    if (typeof AvalTime !== "string") {
      return "Invalid input: AvalTime must be a string";
    }

    // Split the time string by ":" to get individual components
    const timeComponents = AvalTime.split(":");
    const hours = parseInt(timeComponents[0], 10);
    const minutes = parseInt(timeComponents[1], 10);
    const seconds = parseInt(timeComponents[2], 10);

    // Construct a new Date object with today's date and the extracted time components
    const timeObject = new Date();
    timeObject.setHours(hours);
    timeObject.setMinutes(minutes);
    timeObject.setSeconds(seconds);

    return `${hours}:${minutes}:${seconds}`;
  }

  // // console.log(timeObject); // Output the time object

  // const [currentTimeforService, setCurrentTimeforService] = useState(new Date());

  // useEffect(() => {
  //   // Update the current time every second
  //   const intervalId = setInterval(() => {
  //     setCurrentTimeforService(new Date());
  //   }, 1000);

  //   // Clear the interval when the component is unmounted
  //   return () => clearInterval(intervalId);
  // }, []); // Empty dependency array ensures the effect runs only once on mount

  //  const formattedTimeForServiceCheck = currentTimeforService.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' });

  //  // console.log(formattedTimeForServiceCheck)

  //  // console.log(productlist1);

  const { buttonClickCounts, setButtonClickCounts } = useContext(MyContext);
  // useEffect(()=>{
  //   console.log(buttonClickCounts)
  // }, [buttonClickCounts])
  const [accId, setAccID] = useState();
  const [VariableItemData, setVariableItemData] = useState(false);
  const [parantData, setParantData] = useState();
  const [DataToSend, setDataToSend] = useState({});

  const handleGetId = (d1Id) => {
    setAccID(d1Id);
    // // console.log(accId);
  };
  // // console.log(product)
  // // console.log(product);
  useEffect(() => {
    // handleGetId()

    // // console.log("productlist");
    // // console.log(product.d1Id);
    setAccID(product.d1Id);
    // const fetchImage= async()=>{
    //   var imageId = await ItemMasterApi.ImageDetail(1 , accId)
    //   // console.log(imageId)
    // }

    // fetchImage()
  }, []);

  const [itemName, setItemName] = useState();
  const [showToast, setShowToast] = useState(false);

  const handleButtonClick = () => {
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };

  const handleIncrementValue = (id, Item, isAttrbute, ParentId) => {
    // console.log(isAttrbute);
    // console.log(ParentId);
    // console.log(id);


    const parantData = productlist1.find((item) => id === item.d2Id);
    if (parantData.attributeParentId === 0 &&
      parantData.isAttributeItem === true) {
      OpenVariableitem(id);

    } else {
      addingItemInCart(id);
      const currentCount = buttonClickCounts[id] || 0;
      const currentCount1 = ParentId !== 0 ? buttonClickCounts[ParentId] || 0 : null;
    
      // Prepare the updated state object
      const updatedCounts = {
        ...buttonClickCounts,
        [id]: currentCount + 1,
      };
    
      // Only add ParentId if it's not 0
      if (ParentId !== 0) {
        updatedCounts[ParentId] = currentCount1 + 1;
      }
    
      // Update the state with new counts
      setButtonClickCounts(updatedCounts);
    
      // Set the item name and handle other button click related functionality
      setItemName(Item);
      handleButtonClick();
      // variableClose(); // Uncomment or adjust if this needs to be called
    }
  }
  const handleDecrementValue = (id) => {
    RemoveItemInCart(id);
    const currentCount = buttonClickCounts[id] || 0;
    // Check if currentCount is greater than 0 before decrementing
    if (currentCount > 0) {
      setButtonClickCounts({
        ...buttonClickCounts,
        [id]: currentCount - 1,
      });
    }
  };

  useEffect(() => {
    if (cartData === undefined) {
      // // // console.log("if");
      localStorage.setItem("cartData", "");
    } else {
      localStorage.setItem("cartData", JSON.stringify(cartData));
    }
  }, [cartData]);

  const handleIncrement1 = (productId, currentQuantity) => {
    const updatedQuantity = Number(currentQuantity) + 1;
    onQuantityChange(productId, updatedQuantity);
    setVariableItemData(false);
  };

  const handleClicktrue = (productId) => {
    const productArray = Array.isArray(product) ? product : [product];
    const updatedProducts = productArray.map((p) => {
      if (p.id === productId) {
        return { ...p, favourite: true };
      }
      return p;
    });
    onFavChange(productId, updatedProducts);
  };

  const OpenVariableitem = (productId) => {
    // // console.log(productlist1);
    const filteredArray = productlist1.filter(
      (item) => productId === item.attributeParentId
    );
    const parantData = productlist1.find((item) => productId === item.d2Id);
    // // console.log(filteredArray);
    // // console.log(parantData)
    setDataToSend(filteredArray);
    setParantData(parantData);
    setVariableItemData(true);
  };

  const variableClose = () => {
    setVariableItemData(false);
  };

  const handleClickfalse = (productId) => {
    const productArray = Array.isArray(product) ? product : [product];

    const updatedProducts = productArray.map((p) => {
      if (p.id === productId) {
        return { ...p, favourite: false };
      }
      return p;
    });
    onFavChangefalse(productId, updatedProducts);
  };

  return (
    <>
      {/* <div key={product.id} className="card-menu">
        {product && product.isFavorite ? (
          <i
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handleClickfalse(product.d2Id)}
            className="fa-solid fa-heart card-menu-favorite"
          ></i>
        ) : (
          <i
            style={{ color: "black", cursor: "pointer" }}
            onClick={() => handleClicktrue(product.d2Id)}
            className="fa-regular fa-heart card-menu-favorite"
          ></i>
        )} */}

      <div className="card-menu">
        {product ? (
          product.isFavorite ? (
            <i
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => handleClickfalse(product.d2Id)}
              className="fa-solid fa-heart card-menu-favorite"
            ></i>
          ) : (
            <i
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => handleClicktrue(product.d2Id)}
              className="fa-regular fa-heart card-menu-favorite"
            ></i>
          )
        ) : (
          <span>No product data available</span>
        )}

        {/* if (product && product.images) { */}
        {/* // Your code to access product.images */}

        <div className="card-menu-1">
          <div className="menu-image-box">
            {/* <img
              className="card-menu-image"
              src={`http://122.168.117.143/yesbm-api/api/v1/ItemMaster/Image?D1ItemId=${product.images}`}
              alt=""
            /> */}
            <img
              className="card-menu-image"
              src={`${imageBaseUrl}${product.images}`}
              alt=""
            />
          </div>
          <div className="menu-image-box-1">
            <div>
              <div className="">
                <p className="menu-card-title-name">{product.name}</p>
              </div>
              <div className="">
                <p className="menu-card-detail">{product.name}</p>
              </div>
              <div className="">
                <p className="menu-card-price">
                  price : <i
                    style={{ marginRight: "3px" }}
                    className="fa-solid fa-indian-rupee-sign rupees-icon"
                  ></i>
                  {product.salePrice}
                </p>
              </div>
            </div>
            <div>
              {selectedService == "752" ? (
                <>
                  {product.availableForHomeDelivery === false && (
                    <p className="ourmenu-productnotavli">Not Available</p>
                  )}
                  {product.availableForHomeDelivery === true && (
                    <div className="quantity-box">
                      <p className="menu-card-price">
                        <div className="quantity">
                          <button
                            style={{ paddingBottom: "4px" }}
                            className="quantity__minus"
                            onClick={() => handleDecrementValue(product.d2Id)}
                          >
                            -
                          </button>
                          <input
                            className="quantity__input"
                            type="text"
                            value={buttonClickCounts[product.d2Id] || 0}
                            readOnly
                          />
                          <button
                            className="quantity__plus"
                            onClick={() =>
                              handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId)
                            }
                          >
                            +
                          </button>
                        </div>
                        {product.isAttributeItem ? (
                          <span
                            style={{
                              marginLeft: "8px",
                              marginTop: "2px",
                              fontSize: "10px",
                              color: "gray",
                            }}
                            className="menu-card-detail"
                          >
                            Customisable
                          </span>
                        ) : (
                          <span></span>
                        )}
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {/* {product.availableForHomeDelivery === true && ( */}
                  <div className="quantity-box">
                    <p className="menu-card-price">
                      <div className="quantity">
                        <button
                          style={{ paddingBottom: "4px" }}
                          className="quantity__minus"
                          onClick={() => handleDecrementValue(product.d2Id)}
                        >
                          -
                        </button>
                        <input
                          className="quantity__input"
                          type="text"
                          value={buttonClickCounts[product.d2Id] || 0}
                          readOnly
                        />
                        <button
                          className="quantity__plus"
                          onClick={() =>
                            handleIncrementValue(product.d2Id, product.name, product.isAttributeItem, product.attributeParentId)
                          }
                        >
                          +
                        </button>
                      </div>
                      {product.isAttributeItem ? (
                        <span
                          style={{
                            marginLeft: "8px",
                            marginTop: "2px",
                            fontSize: "10px",
                            color: "gray",
                          }}
                          className="menu-card-detail"
                        >
                          Customisable
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </p>
                  </div>
                  {/* )} */}
                </>
              )}
            </div>
          </div>
        </div>
        {/* } else {
          console.error("Product or product images are undefined.")
        } */}
      </div>
      {showToast && (
        <div className="toastDiv">{itemName} Added Successfully !!</div>
      )}
      {VariableItemData && (
        <div className="variableproduct-modal-back">
          <VariableProductModal
            parantData={parantData}
            product={product}
            data={DataToSend}
            onClick={OpenVariableitem}
            handleIncrementValue={handleIncrementValue}
            variableClose={variableClose}
            handleDecrementValue={handleDecrementValue}
            buttonClickCounts={buttonClickCounts}
          />
        </div>
      )}
    </>
  );
};

export default ProductCardMenu;




