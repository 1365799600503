import React, { useContext } from "react";
import MyContext from "./MyContext";
import { useLocation } from "react-router-dom";
// import image from ""

const CartProduct = (props) => {
  const { product, addingItemInCart, RemoveItemInCart } = props;
  const { buttonClickCounts, setButtonClickCounts, imageBaseUrl } = useContext(MyContext);
  // console.log(imageBaseUrl)

  const navigate = useLocation();

  const handleIncrementValue = (id) => {
    addingItemInCart(id);
    const currentCount = buttonClickCounts[id] || 0;
    setButtonClickCounts({
      ...buttonClickCounts,
      [id]: currentCount + 1,
    });
  };

  const handleDecrementValue = (id) => {
    RemoveItemInCart(id);
    const currentCount = buttonClickCounts[id] || 0;
    setButtonClickCounts({
      ...buttonClickCounts,
      [id]: currentCount - 1,
    });
  };

  const filteredProducts = product.filter((item) => item.quantity > 0);
  // console.log(product);

  const ourMenuClick = () => {
    navigate("/OurMenu");
  }

  return (
    <>
      {filteredProducts.length === 0 ? (
        <div className="mycart-empty-txt-box">
          <img className="mycart-empty-image" src="https://mothershut.com/RestoFolders/MOTHERSHUT_Supela_Bhilai/Cart_empty.png" alt="" />
          <p className="mycart-empty-txt" onClick={ourMenuClick}>Your Order cart is empty.</p>
          <p className="mycart-empty-txt" onClick={ourMenuClick}>Click to add an item.</p>
          <p className="mycart-empty-txt-2">Cooked by mothers with love and care</p>
        </div>
      ) : (
        <>
          {filteredProducts.map((product) => (
            <div key={product.id} style={{ padding: props.padding, width: props.width2, color: product.color }} className="card-cart-product  cart-image">
              {/* <i className="fa-solid fa-trash card-cart-delete"></i> */}
              <div className="card-cartimage-div">
                {/* <img style={{ width: props.width }} className="cart-menu-image" src={product.image} alt="" /> */}
                {/* <img className="card-menu-image-1" src={`http://192.168.1.9/YESBM-API/api/v1/ItemMaster/Image?D1ItemId=${product.images}`} alt="" /> */}
                <img className="card-menu-image-1" src={`${imageBaseUrl}${product.images}`} alt="" />

              </div>
              <div className="card-cart-content-div">
                <div className="cart-card-detail-box">
                  <p className="card-cart-txt-1" style={{ margin: "0" }}>
                    {product.name}
                  </p>
                  <p className="card-cart-txt-2" style={{ margin: "0" }}>
                    {product.salePrice}
                    <i
                      style={{ marginLeft: "3px" }}
                      className="fa-solid fa-indian-rupee-sign rupees-icon"
                    ></i>
                  </p>
                </div>
                <div className="quantity">
                  <button
                    style={{ paddingBottom: "4px" }}
                    className="quantity__minus"
                    onClick={() => handleDecrementValue(product.d2Id)}
                  >
                    -
                  </button>
                  <input
                    className="quantity__input"
                    type="text"
                    value={product.quantity}
                    readOnly
                  />
                  <button
                    className="quantity__plus"
                    onClick={() => handleIncrementValue(product.d2Id)}
                  >
                    +
                  </button>
                </div>
                <div className="cart-card-detail-box-2">
                  <p className="card-cart-txt-3" style={{ margin: "0" }}>
                    {product.salePrice * product.quantity}
                    <i
                      style={{ marginLeft: "3px" }}
                      className="fa-solid fa-indian-rupee-sign rupees-icon"
                    ></i>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  )
};

export default CartProduct;
