import React from 'react'
import { Link } from 'react-router-dom';
import lowericonbox from "../Assets/grid.png"



const MobileBottomMentBtn = (props) => {
  const handleGoBack = () => {
    window.history.back();
  };


  return (
    <div className="button-container-bottom">
      <Link to="/" className="button-bottom-nav">
        <div className='mobileMenu'>
          {/* <svg xmlns="http//www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 1024 1024" strokeWidth="0" fill="currentColor" stroke="currentColor" className="icon-bottom-nav">
            <path d="M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 0 0-44.4 0L77.5 505a63.9 63.9 0 0 0-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0 0 18.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z"></path>
          </svg> */}
          <i class="fa-solid fa-house" style={{ textAlign: "center" }}></i>
          <label style={{ fontSize: "10px" }}>Home</label>
        </div>
      </Link>
      <Link to="/Ourmenu" className="button-bottom-nav">
        {/* <i style={{fontSize:"20px"}} className="mobile-back-btn fa-solid fa-grip"></i> */}
        {/* <img style={{width:"18px"}} src={lowericonbox} alt="" /> */}
        <div className='mobileMenu'>
          <i class="fa-solid fa-list" style={{ textAlign: "center" }}></i>
          <label style={{ fontSize: "10px" }}>Menu</label>
        </div>
      </Link>

      <Link to="/Mycart" className="button-bottom-nav" style={{ position: "relative" }}>
        <div className='mobileMenu'>
          <div>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" strokeLinejoin="round" strokeLinecap="round" viewBox="0 0 24 24" strokeWidth="2" fill="none" stroke="currentColor" className="icon-bottom-nav">
              <circle r="1" cy="21" cx="9"></circle>
              <circle r="1" cy="21" cx="20"></circle>
              <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
            </svg> */}
            <i class="fa-solid fa-cart-shopping" style={{ textAlign: "center" }}></i>
            <div className='cart-number-phone'>
              {props.cartNumber === 0 ? <div></div> : props.cartNumber}
            </div>
          </div>
          <label style={{ fontSize: "10px" }}>Cart</label>
        </div>
      </Link>

      <div onClick={handleGoBack} className="button-bottom-nav">
        <div className='mobileMenu'>
          <i className="mobile-back-btn fa-solid fa-arrow-left-long" style={{ textAlign: "center", fontSize: "18px" }}></i>
          <label style={{ fontSize: "10px" }}>Back</label>
        </div>
      </div>

    </div>
  )
}

export default MobileBottomMentBtn