import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;
var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Table = {

    insertTable: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Table/Table`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                tableName: data.tableName,
                tableGroup: Number(data.tableGroup),
                tableNumber: data.tableNumber,
                tablePriority: Number(data.tablePriority),
                tableCapacity: Number(data.tableCapacity),
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getTable: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/Table/GetTables`;
            const response = await axios.post(apiUrl, {
                filter: [
                    {
                        fieldName: "tableStatus",
                        operatorName: "Equal",
                        compareValue: "Free"
                    }
                ],
                order: [],
                pageNumber: 0,
                pageSize: 0
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteTable: async (tableId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Table?tableId=${tableId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getTableById: async (tableId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Table/Table?tableId=${tableId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************

    updateTable: async (data) => {
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/Table?tableId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                tableName: data.tableName,
                tableGroup: Number(data.tableGroup),
                tableNumber: data.tableNumber,
                tablePriority: Number(data.tablePriority),
                tableCapacity: Number(data.tableCapacity),
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get Table from Table Group ***************************
    getTableFromTableGroup: async (fieldName, operatorName, compareValue) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Table/GetTables`;
            const response = await axios.post(apiUrl, {
                filter: [
                    {
                        fieldName: fieldName,
                        operatorName: operatorName,
                        compareValue: Number(compareValue)
                    },
                    {
                        fieldName: "tableStatus",
                        operatorName: "Equal",
                        compareValue: "Free"
                    }
                ],
                order: [
                    {
                        propertyName: "string",
                        ascending: true
                    }
                ],
                pageNumber: 0,
                pageSize: 0
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By user Id ****************************
    getTableAllocationById: async (userId) => {
        //debugger;
        try {
            const apiUrl = `${WebApiUrl}/api/TableAllocation/GetTableAllocations?userId=${userId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // *************************** Patch update table Partially ***************************
    updateTablePartially: async (data, tableId) => {
        debugger;
        try {
            const apiUrl = `${WebApiUrl}/api/Table/UpdateTablePartially?tableId=${tableId}`;
            console.log(apiUrl);
            const response = await axios.patch(apiUrl, data, {
                withCredentials: true,
                credentials: 'include',
            });
            console.log("Response:", response.data);
            return response.data.isSuccess
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Check Table Status****************************
    checkTableStatus: async (tableId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Table/CheckTableStatus?tableId=${tableId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

};

export default Table;
