import React from 'react'

const Loader = () => {
  return (
     <div className='modal-back-loader'>
        <div className='loader-box'>
    <svg  className='loader' viewBox="25 25 50 50">
    <circle className='loader-circle' r="20" cy="50" cx="50"></circle>
  </svg>
    <p>Loading....</p>
        </div>
    </div>
  )
}

export default Loader