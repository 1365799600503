import React from 'react'

const LogOut = (props) => {
  return (
    <div className="modal-back">
      <div className='logout-modal'>

        <i onClick={props.close} style={{ color: "white", cursor: "pointer" }} className="fa-solid fa-xmark card-menu-favorite"></i>
        <div className="form-container">
          <form style={{ marginTop: "0px", marginBottom: '20px' }} className="form">
            <p className='fs-b' style={{ textAlign: "center" }}>Are You Sure <br /> {props.text} </p>
            <div className='buttonbox-logout'>
              <div onClick={props.logout} className="sign">YES</div>
              <div onClick={props.close} className="sign-no">NO</div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LogOut