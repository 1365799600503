import { useState } from 'react';
import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);


const Account = {

    insertAccount: async (data, optionalData) => {
    //    debugger
        let optionalDataValues = [];
        for (let key in optionalData) {
            optionalDataValues.push({
                optionalFieldName: optionalData[key].optionalFieldName,
                optionalFieldValue: optionalData[key].optionalFieldValue,
                isDeleted: false,
                isActive: Boolean(true)
            });
        }
        try {
            const apiUrl = `${WebApiUrl}/api/Account/Account`;
            const response = await axios.post(apiUrl, {
                namePrefix: data.namePrefix,
                name: data.name,
                printName: data.printName,
                alias: data.alias,
                accountType: Number(data.accountType),
                parentGrp: Number(data.parentGrp),
                aState: Number(data.aState),
                aStation: Number(data.aStation),
                aDefaultSalePerson: Number(data.aDefaultSalePerson),
                adob: data.adob, // "2024-01-31",
                aAge: Number(data.aAge),
                adom: data.adom,  //"2024-01-31",
                aGender: data.aGender,
                aDepartment: Number(data.aDepartment),
                aDesignation: Number(data.aDesignation),
                aDrugLicenseNo: data.aDrugLicenseNo,
                aOpeningBal: Number(data.aOpeningBal),
                aOpeningCrDr: data.aOpeningCrDr,               //number but pass value as string;
                aContactperson: data.aContactperson,
                contactpersonMbl: data.contactpersonMbl,
                aCreditlimit: Number(data.aCreditlimit),
                aCreditperiod: Number(data.aCreditperiod),
                aAccountCategory: Number(data.aAccountCategory),
                aEnableLoyalty: Boolean(data.aEnableLoyalty),
                aLoyaltyCardNo: data.aLoyaltyCardNo,
                referralBy: Number(data.referralBy),
                assignUser: Number(data.assignUser),
                aAddress: data.aAddress,
                aEnableEmail: data.aEnableEmail,
                aEmail: data.aEmail,
                aMobileNo: data.aMobileNo,
                aPincode: data.aPincode,
                aLocation: data.aLocation,
                aDistance: data.aDistance,
                accountDealerType: data.accountDealerType,
                agStINNo: data.agStINNo,
                aitPan: data.aitPan,
                alstNo: data.alstNo,
                acstNo: data.acstNo,
                aAadharNo: data.aAadharNo,
                doNotDisturb: data.doNotDisturb,
                aDefaultTranspoter: Number(data.aDefaultTranspoter),
                openingCrDr: data.openingCrDr,
                system_defined: Boolean(data.system_defined),
                createdBy: data.createdBy,
                createdOn: "2024-02-01", //data.createdOn,
                isActive: Boolean(data.isActive),
                accOptFieldValues: optionalDataValues
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            // console.log("Response:", response.data);
            return response.data;

        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getAccount: async () => {
        try {

            const apiUrl = `${WebApiUrl}/api/Account/GetAccounts`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            // console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },


    getAccountWithFilter: async (filteredData) => {
        const filteredData1 = filteredData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
          }));
        try {

            const apiUrl = `${WebApiUrl}/api/Account/GetAccounts`;
            const response = await axios.post(apiUrl, 
                {
                    "filter":filteredData1,
                    "order": [
                      {
                        "propertyName": "string",
                        "ascending": true
                      }
                    ],
                    "pageNumber": 0,
                    "pageSize": 0
                  }
                , {
                withCredentials: true,
            });
            // console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteAccount: async (accountId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Account?accountId=${accountId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            // console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getAccountById: async (accountId) => {
        try {
        
            const apiUrl = `${WebApiUrl}/api/Account?accountId=${accountId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            // console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************
    updateAccount: async (data, optionalData) => {
        let optionalDataValues = [];
        for (let key in optionalData) {
            optionalDataValues.push({
                optionalFieldName: optionalData[key].optionalFieldName,
                optionalFieldValue: optionalData[key].optionalFieldValue,
                isDeleted: false,
                isActive: Boolean(true)
            });
        }
        try {
          
            const apiUrl = `${WebApiUrl}/api/Account?accountId=${data.id}`;
            // console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                namePrefix: data.namePrefix,
                name: data.name,
                printName: data.printName,
                alias: data.alias,
                accountType: Number(data.accountType),
                parentGrp: Number(data.parentGrp),
                aState: Number(data.aState),
                aStation: Number(data.aStation),
                aDefaultSalePerson: Number(data.aDefaultSalePerson),
                adob: data.adob, // "2024-01-31",
                aAge: Number(data.aAge),
                adom: data.adom,  //"2024-01-31",
                aGender: data.aGender,
                aDepartment: Number(data.aDepartment),
                aDesignation: Number(data.aDesignation),
                aDrugLicenseNo: data.aDrugLicenseNo,
                aOpeningBal: Number(data.aOpeningBal),
                aOpeningCrDr: data.aOpeningCrDr,
                aContactperson: data.aContactperson,
                contactpersonMbl: data.contactpersonMbl,
                aCreditlimit: Number(data.aCreditlimit),
                aCreditperiod: Number(data.aCreditperiod),
                aAccountCategory: Number(data.aAccountCategory),
                aEnableLoyalty: Boolean(data.aEnableLoyalty),
                aLoyaltyCardNo: data.aLoyaltyCardNo,
                referralBy: Number(data.referralBy),
                assignUser: Number(data.assignUser),
                aAddress: data.aAddress,
                aEnableEmail: data.aEnableEmail,
                aEmail: data.aEmail,
                aMobileNo: data.aMobileNo,
                aPincode: data.aPincode,
                aLocation: data.aLocation,
                aDistance: data.aDistance,
                accountDealerType: data.accountDealerType,
                agStINNo: data.agStINNo,
                aitPan: data.aitPan,
                alstNo: data.alstNo,
                acstNo: data.acstNo,
                aAadharNo: data.aAadharNo,
                doNotDisturb: data.doNotDisturb,
                aDefaultTranspoter: Number(data.aDefaultTranspoter),
                openingCrDr: data.openingCrDr,
                system_defined: Boolean(data.system_defined),
                createdBy: (data.createdBy).toString(),
                createdOn: data.createdOn,
                isActive: Boolean(data.isActive),
                accOptFieldValues: optionalDataValues
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            // console.log("Response:", response.data);
            return response.data.isSuccess
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ************************* Get Account Types ***************************
    getAccountTypes: async () => {
        try {
         
            const apiUrl = `${WebApiUrl}/api/Account/GetAccountTypes`;
            const response = await axios.get(apiUrl, {
                withCredentials: true,
            });
            // console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Create Account Master Setting ***************************    
    insertAccountMasterSetting: async (settingData, optionalData) => {
        let data = [];
        for (var i = 0; i < optionalData.length; i++) {
            data.push({
                optionalFieldName: optionalData[i].optionalFieldName,
                priority: optionalData[i].priority,
                isDeleted: false,
                isActive: true
            });
        }
        try {
        
            const apiUrl = `${WebApiUrl}/api/Account/CreateAccountMasterSetting`;
            // console.log(apiUrl);
            // console.log(settingData);
            const response = await axios.post(apiUrl, {
                accountDetails: Boolean(settingData.accountDetails),
                aliasNPrintDetails: Boolean(settingData.aliasNPrintDetails),
                documentDetails: Boolean(settingData.documentDetails),
                transportDetails: Boolean(settingData.transportDetails),
                defaultSalesPerson: Boolean(settingData.defaultSalesPerson),
                optionalField: Boolean(settingData.optionalField),
                isActive: true,
                accountOptionalFields: data //previous data + latest updated data;
            }, {
                withCredentials: true,
                credentials: 'include',
            });
            // console.log("Response:", response.data);
            return true;

        } catch (error) {
            console.error("Error:", error.message);
            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get Account Master Setting ***************************
    getAccountMasterSetting: async () => {
        try {
          
            const apiUrl = `${WebApiUrl}/api/Account/GetAccountMasterSetting`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            // console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Get Account Optional Field ***************************
    getAccountOptionalField: async () => {
        try {
     
            const apiUrl = `${WebApiUrl}/api/Account/GetAccountOptionalField`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            // console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    getMyOrderList : async (AccountId)=>{
        try {
          const apiUrl = `${WebApiUrl}/api/Account/getOrderByAccount?AccountId=${AccountId}`;
          const response = await axios.get(apiUrl, {}, {
            withCredentials: true,
          });
          // console.log('Response:', response.data.result);
          //setEditCallBack(response.data.result);
          return response.data.result;
        } catch (error) {
          console.error('Error:', error.message);
        }
      }
,      

    PartiallyUpdateAccount: async (data) => {
        
        var loginDataString = localStorage.getItem("accountLogin"); 
        var loginData = JSON.parse(loginDataString); 
        // console.log(loginData.d1Id);
        // console.log(loginData.d2Id);
        ;
        try {
    
           const apiUrl = `${WebApiUrl}/api/Account/UpdateAccountPartially?d1Id=${loginData.d1Id}&d2Id=${loginData.d2Id}`;
            
           const response = await axios.patch(apiUrl, data,{
                withCredentials: true,
                credentials: 'include', 
            });
    
           // console.log("Response:", response.data);
            return response.data;
    
        } catch (error) {
            console.error("Error:", error.message);
    
            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    
    PartiallyUpdateAccountForOTP: async (data, accData) => {
        debugger
        console.log(data);
        console.log(accData);
        try {
    
           const apiUrl = `${WebApiUrl}/api/Account/UpdateAccountPartially?d1Id=${accData[0].d1Id}&d2Id=${accData[0].d2Id}`;
            
           const response = await axios.patch(apiUrl, data,{
                withCredentials: true,
                credentials: 'include', 
            });
    
           // console.log("Response:", response.data);
            return response.data;
    
        } catch (error) {
            console.error("Error:", error.message);
    
            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
};

export default Account;
