import React from "react";
import coupon from "../Assets/location-pin.png";

const AddAddressModal = (props) => {
  return (
    <div className="modal-back">
      <div className="form-container-2">
        <div className="coupon-searchbox">
          <div className="inputBox_container">
            <svg
              className="search_icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              alt="search icon">
              <path d="M46.599 46.599a4.498 4.498 0 0 1-6.363 0l-7.941-7.941C29.028 40.749 25.167 42 21 42 9.402 42 0 32.598 0 21S9.402 0 21 0s21 9.402 21 21c0 4.167-1.251 8.028-3.342 11.295l7.941 7.941a4.498 4.498 0 0 1 0 6.363zM21 6C12.717 6 6 12.714 6 21s6.717 15 15 15c8.286 0 15-6.714 15-15S29.286 6 21 6z"></path>
            </svg>
            <input
              className="inputBox"
              id="inputBox"
              type="text"
              placeholder="Search For Products"
            />
          </div>
          <button className="search-coupon-btn-1">Add Address</button>
        </div>
        <i
          style={{
            position: "absolute",
            cursor: "pointer",
            fontSize: "16px",
            top: "5px",
            right: "8px",
          }}
          onClick={props.close}
          className="fa-solid fa-xmark modal-cross"
        ></i>
        <div className="coupon-box-container">
          <div className="coupon-box-2">
            {" "}
            <img className="coupon-image-3" src={coupon} alt="" />
            <div className="coupon-txt-box-2">
              {/* <p className="coupon-off-button">Flat 1000 </p> */}
              <p style={{ margin: "0" }}>q, q, National Highway 34, Beside Indian Oil Petrol Pump, Bhatjangla, West Bengal 741101, India Delivery Point: MothersHut</p>
            </div>
            <button className="coupon-apply-btn-1">APPLY</button>
          </div>
          <div className="coupon-box-2">
            {" "}
            <img className="coupon-image-3" src={coupon} alt="" />
            <div className="coupon-txt-box-2">
              {/* <p className="coupon-off-button">Flat 500 </p> */}
              <p style={{ margin: "0" }}>q, q, National Highway 34, Beside Indian Oil Petrol Pump, Bhatjangla, West Bengal 741101, India Delivery Point: MothersHut</p>
            </div>
            <button className="coupon-apply-btn-1">APPLY</button>
          </div>
          <div className="coupon-box-2">
            {" "}
            <img className="coupon-image-3" src={coupon} alt="" />
            <div className="coupon-txt-box-2">
              {/* <p className="coupon-off-button">Flat 300 </p> */}
              <p style={{ margin: "0" }}>q, q, National Highway 34, Beside Indian Oil Petrol Pump, Bhatjangla, West Bengal 741101, India Delivery Point: MothersHut</p>
            </div>
            <button className="coupon-apply-btn-1">APPLY</button>
          </div>
          <div className="coupon-box-2">
            {" "}
            <img className="coupon-image-3" src={coupon} alt="" />
            <div className="coupon-txt-box-2">
              {/* <p className="coupon-off-button">Flat 200 </p> */}
              <p style={{ margin: "0" }}>q, q, National Highway 34, Beside Indian Oil Petrol Pump, Bhatjangla, West Bengal 741101, India Delivery Point: MothersHut</p>
            </div>
            <button className="coupon-apply-btn-1">APPLY</button>
          </div>
          <div className="coupon-box-2">
            {" "}
            <img className="coupon-image-3" src={coupon} alt="" />
            <div className="coupon-txt-box-2">
              {/* <p className="coupon-off-button">Flat 2000 </p> */}
              <p style={{ margin: "0" }}>q, q, National Highway 34, Beside Indian Oil Petrol Pump, Bhatjangla, West Bengal 741101, India Delivery Point: MothersHut</p>
            </div>
            <button className="coupon-apply-btn-1">APPLY</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAddressModal;
