import React from 'react'

const SignInPanel = (props) => {
    const {
        handleInputChangeSignIn ,
        showPassword1,
        inputValuesForSignIn,
        signupshow,
        SendOtp,
        handleTogglePasswordhide,
        showPassword,
        handleTogglePasswordhide1,
        handleTogglePassword,
        handleTogglePassword1
    } = props

  return (
    <div className="form-container">
    <p className="title">Sign up</p>
    <form className="form">
      <div className="input-group">
        <label htmlFor="username">Name</label>
        <input
          type="text"
          id="name-signin"
          onChange={handleInputChangeSignIn}
          value={inputValuesForSignIn.name}
          name="name"
        />
      </div>
      <div className="input-group">
        <label htmlFor="username">E-mail</label>
        <input
          type="text"
          onChange={handleInputChangeSignIn}
          value={inputValuesForSignIn.aEmail}
          name="aEmail"
          id="email-signin"
        />
      </div>
      <div className="input-group">
        <label htmlFor="username">Mobile</label>
        <input
          type="number"
          onChange={handleInputChangeSignIn}
          value={inputValuesForSignIn.aMobileNo}
          name="aMobileNo"
          id="mobile-signin"
        />
      </div>
      <div className="input-group">
        <label htmlFor="password">Password</label>
        <input
          type={showPassword1 ? "text" : "password"}
          onChange={handleInputChangeSignIn}
          value={inputValuesForSignIn.password}
          name="password"
          id="password1"
          placeholder=""
        />
        {!showPassword1 && (
          <i
            onClick={handleTogglePassword1}
            className="fa-regular ShowHideIcon fa-eye"
          ></i>
        )}
        {showPassword1 && (
          <i
            onClick={handleTogglePasswordhide1}
            className="fa-regular ShowHideIcon fa-eye-slash"
          ></i>
        )}
      </div>
      <div className="input-group">
        <label htmlFor="password">Re-Enter Password</label>
        <input
          type={showPassword ? "text" : "password"}
          onChange={handleInputChangeSignIn}
          value={inputValuesForSignIn.reEnterpassword}
          name="reEnterpassword"
          id="password1"
          placeholder=""
        />

        {!showPassword && (
          <i
            onClick={handleTogglePassword}
            className="fa-regular ShowHideIcon fa-eye"
          ></i>
        )}
        {showPassword && (
          <i
            onClick={handleTogglePasswordhide}
            className="fa-regular ShowHideIcon fa-eye-slash"
          ></i>
        )}
      </div>

      <div
        style={{ marginTop: "15px", cursor: "pointer" }}
        onClick={SendOtp}
        className="sign"
      >
        Get OTP
      </div>
    </form>

    <p className="signup">
      Don't have an account?
      <div
        rel="noopener noreferrer"
        className=""
        style={{
          cursor: "pointer",
          marginTop: "5px",
          fontSize: "14px",
        }}
        onClick={signupshow}
      >
        Log in
      </div>
    </p>
  </div>
  )
}

export default SignInPanel