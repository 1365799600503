import React from 'react'

const MobilesSection3 = (props) => {
  return (
    <div className='modal-back'>
        <div className="myordercart-mobile-section-3">
          <div className="xmark-ms-2" onClick={props.gotofrist}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          <h4 className="f-17">  <i style={{marginRight:"3px"}} className="fa-solid fa-user-tie"></i>  Account</h4>
          <p className="f-13">
            To place your order now , login your existing account or sign up.
          </p>
          <div className="mobile-cart-button-box">
            <div className="mobile-cart-button" onClick={props.logindone}>
              <p >Have an account?</p>
              <p className="f-15 fw-600" >LOG IN</p>
            </div>
            <div className="mobile-cart-button" onClick={props.showsignin}>
              <p>New to Mother's hut</p>
              <p className="f-15 fw-600">SIGN IN</p>
            </div>
          </div>
        </div>
    </div>

  )
}

export default MobilesSection3