import React, { useState } from 'react';

const CurrencyConverter = () => {
  const [amount, setAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [convertedAmount, setConvertedAmount] = useState(null);

  const exchangeRates = {
    USD: 0.014, // Replace with real exchange rates
    EUR: 0.012,
    GBP: 0.010,
    INR: 1, // Base currency
    AUD: 0.019,
    CAD: 0.017,
    JPY: 1.48,
    CNY: 0.088,
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
    convertCurrency()
};
  const convertCurrency = () => {
    const inputAmount = parseFloat(amount);
    if (!isNaN(inputAmount)) {
      const conversionRate = exchangeRates[selectedCurrency];
      const result = inputAmount * conversionRate;
      setConvertedAmount(result.toFixed(2));
    } else {
      setConvertedAmount(null);
    }
  };

  return (
    <div>
      <h2>Currency Converter</h2>
      <div>
        <label>
          Amount:
          <input type="number" value={amount} onChange={handleAmountChange} />
        </label>
      </div>
      <div>
        <label>
          From Currency:
          <select value={selectedCurrency} onChange={handleCurrencyChange}>
            {Object.keys(exchangeRates).map(currency => (
              <option key={currency} value={currency}>{currency}</option>
            ))}
          </select>
        </label>
      </div>
      <div>
      </div>
      {convertedAmount !== null && (
        <div>
          <p>Converted Amount: {convertedAmount} {selectedCurrency}</p>
        </div>
      )}
    </div>
  );
};

export default CurrencyConverter;
