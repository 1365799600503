import axios from '../axiosConfig'; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const TableGroup = {

    insertTableGroup: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/TableGroup/TableGroup`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                name: data.name,
                isActive:data.isActive,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getTableGroup: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/TableGroup/GetTableGroups`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
//            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteTableGroup: async (tableGroupId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/TableGroup?tableGroupId=${tableGroupId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getTableGroupById: async (tableGroupId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/TableGroup/TableGroup?tableGroupId=${tableGroupId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************

    updateTableGroup: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/TableGroup?tableGroupId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                name: data.name,
                isActive:data.isActive,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

};

export default TableGroup;
