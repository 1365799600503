import React, { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="terms-and-conditions">
        <div>
      <h1 >Terms & Condition :</h1>
      <p>
        THESE TERMS AND CONDITIONS APPLY TO THE WEB SITE WWW.MOTHERSHUT.COM (THE
        "SITE"). PLEASE READ THIS STATEMENT CAREFULLY. IF YOU DO NOT WISH TO BE
        BOUND BY THESE TERMS AND CONDITIONS THEN YOU SHOULD NOT ACCESS THIS
        SITE. ACCESS OF THIS SITE BY YOU SHALL BE DEEMED TO BE YOUR ACCEPTANCE
        OF THESE TERMS AND CONDITIONS.
      </p>

      <p>
        Privacy Policy MOTHER’S HUT (hereinafter referred to as either, We,
        Us,or Our) is committed to protecting the privacy of personal
        information you may provide Us on this Website (the Site). We believe it
        is important for you to know how We treat your personal information..
        The terms of this Privacy Policy apply to all users of this Site. If you
        do not agree with the terms of this Privacy Policy, you should
        immediately cease the use of this Site. Below you will find answers to
        several questions regarding Our Privacy Policy.
      </p>
        </div>

        <div>

      <h2>WHAT INFORMATION DOES MOTHER’S HUT COLLECT ABOUT ME ON THIS SITE?</h2>
      <p>
        MOTHER’S HUT collects personally identifiable information that you may
        voluntarily provide on online forms, which may include: user
        registration, contact requests, guest comments, online surveys, and
        other online activities. The personally identifiable information
        (Personal Information) collected on this Site can include some or all of
        the following: your name, address, telephone number, email addresses,
        demographic information, and any other information you may voluntarily
        provide. You will have the choice whether or not to disclose such
        Personal Information in the above activities; however, some parts of the
        Site and some services may be more difficult or impossible to use if you
        choose not to disclose Personal Information. At the time of
        registration, you will have the option to receive future offers and
        updates from the MOTHER’S HUT by checking the appropriate opt-in box. If
        you choose to opt-in, your Personal Information will be kept in a
        secured database and We will alert you via email to new features,
        special offers, updated information and new services.
      </p>
      <p>
        In addition to the Personal Information identified above, Our Web
        servers automatically identify computers by their IP addresses. MOTHER’S
        HUT may use IP addresses to analyze trends, administer the Site, track
        users movement and gather broad demographic information for aggregate
        use. To emphasize, IP addresses are not linked to Personal Information.
      </p>
      <p>
        Further, Our Site may use technologies such as cookies to provide
        visitors with tailored information upon each visit. Cookies are a common
        part of many commercial Websites that allow small text files to be sent
        by a Website, accepted by a Web browser and then placed on your hard
        drive as recognition for repeat visits to the Site. Every time you visit
        Our Site, Our servers, through cookies, pixels and/or GIF files, collect
        basic technical information such as your domain name, the address of the
        last URL visited prior to clicking through to the Site, and your browser
        and operating system. You do not need to enable cookies to visit Our
        Site; however, some parts of the Site and some services may be more
        difficult or impossible to use if cookies are disabled. To emphasize,
        cookies are not linked to any Personal Information while on this Site.
        It does not contain your name, address, telephone number, or email
        address.
      </p>

      </div>
      <div>
      <h2>WHAT DOES MOTHER’S HUT DO WITH THE INFORMATION COLLECTED? </h2>
      <p>
        MOTHER’S HUT may use your Personal Information for any of the following
        purposes: to understand the use of the Site and make improvements; to
        fulfill prizes; to register visitors for online activities they choose
        to participate in such as: contests, ` surveys, employment applications,
        comment forms, or any other online interactive activities; to respond to
        specific requests from visitors; to protect the security or integrity of
        the Site if necessary; to send notices ( if consent is provided) of
        special promotions, offers or solicitations; and in general (if consent
        is provided), to promote and market OUR various products.
      </p>
      </div>
      <div>
      <h2>HOW DO I CONTACT MOTHER’S HUT?</h2>

      <p>
        THESE TERMS AND CONDITIONS APPLY TO THE WEB SITE WWW. MOTHER’S HUT.COM
        (THE "SITE"). PLEASE READ THIS STATEMENT CAREFULLY. IF YOU DO NOT WISH
        TO BE BOUND BY THESE TERMS AND CONDITIONS THEN YOU SHOULD NOT ACCESS
        THIS SITE. ACCESS OF THIS SITE BY YOU SHALL BE DEEMED TO BE YOUR
        ACCEPTANCE OF THESE TERMS AND CONDITIONS. Privacy Policy MOTHER’S
        HUT,We, Us,or Our is committed to protecting the privacy of personal
        information you may provide Us on this Website (the Site). We believe it
        is important for you to know how We treat your personal information..
        The terms of this Privacy Policy apply to all users of this Site. If you
        do not agree with the terms of this Privacy Policy, you should
        immediately cease the use of this Site. Below you will find answers to
        several questions regarding Our Privacy Policy.
      </p>
      </div>
      <div>
      <h2>WHAT INFORMATION DOES MOTHER’S HUT COLLECT ABOUT ME ON THIS SITE?</h2>

      <p>
        MOTHER’S HUT collects personally identifiable information that you may
        voluntarily provide on online forms, which may include: user
        registration, contact requests, guest comments, online surveys, and
        other online activities. The personally identifiable information
        (Personal Information) collected on this Site can include some or all of
        the following: your name, address, telephone number, email addresses,
        credit card information, demographic information, and any other
        information you may voluntarily provide. You will have the choice
        whether or not to disclose such Personal Information in the above
        activities; however, some parts of the Site and some services may be
        more difficult or impossible to use if you choose not to disclose
        Personal Information. At the time of registration, you will have the
        option to receive future offers and updates from the MOTHER’S HUT by
        checking the appropriate opt-in box. If you choose to opt-in, your
        Personal Information will be kept in a secured database and We will
        alert you via email to new features, special offers, updated information
        and new services.
      </p>

      <p>
        In addition to the Personal Information identified above, Our Web
        servers automatically identify computers by their IP addresses. MOTHER’S
        HUT may use IP addresses to analyze trends, administer the Site, track
        users movement and gather broad demographic informa;on for aggregate
        use. To emphasize, IP addresses are not linked to Personal Information.
      </p>
      <p>
        Further, Our Site may use technologies such as cookies to provide
        visitors with tailored information upon each visit. Cookies are a common
        part of many commercial Websites that allow small text files to be sent
        by a Website, accepted by a Web browser and then placed on your hard
        drive as recognition for repeat visits to the Site. Every time you visit
        Our Site, Our servers, through cookies, pixels and/or GIF files, collect
        basic technical information such as your domain name, the address of the
        last URL visited prior to clicking through to the Site, and your browser
        and operating system.
      </p>
      <p>
        You do not need to enable cookies to visit Our Site; however, some parts
        of the Site and some services may be more difficult or impossible to use
        if cookies are disabled. To emphasize, cookies are not linked to any
        Personal Information while on this Site. These files enable DoubleClick
        to recognize a unique cookie on your Web browser, which in turn enables
        Us to learn which advertisements bring users to Our Website. The cookie
        was placed by Us, or by another advertiser who works with DoubleClick.
        With both cookies and Spotlight technology, the information We collect
        and share is anonymous and not personally identifiable. It does not
        contain your name, address, telephone number, or email address. For more
        information about DoubleClick, including information on how to opt out
        of these technologies, go
        tohttp://www.doubleclick.net/Us/corporate/privacy
      </p>
      </div>
      <div>

      <h2>WITH WHOM DOES MOTHER’S HUT SHARE THE INFORMATION?</h2>

      <p>
        MOTHER’S HUT may share Personal Information collected on this Site with
        any member of future subsidiaries or affiliates. If you choose to
        provide your Personal Information then the respective Privacy Policies
        will apply. MOTHER’S HUT will not sell, rent, loan, transfer, or
        otherwise disclose any Personal Information to third parties except as
        set forth in this statement. MOTHER’S HUT may share Personal Information
        with third parties under the following circumstances: in connection with
        a court order, subpoena, government investigation, or when otherwise
        required by law; in the event of a corporate sale, merger, acquisition,
        or similar event; or working with third party companies to support the
        Sites technical operation or execute a specific promotion or program
        (such as providing responses to the Guest Comments Page, conduct
        surveys, or maintain a database of visitor information, etc.). If
        Personal Information is shared with any third party for the purposes of
        executing a specific promotion or program, the third party will have no
        right to use the Personal Information for independent purposes. In
        support of this policy, MOTHER’S HUT will enter into written agreements
        with any third party receiving Personal Information requiring the third
        party to comply with MOTHER’S HUT's privacy policies and applicable
        laws.
      </p>
      </div>
      <div>


      <h2>HOW CAN I STOP RECEIVING NOTICES ABOUT FUTURE OFFERS?</h2>

      <p>
        Users, who initially opted-in to receive future offers or promotional
        materials may subsequently opt-out by sending an email to
        unsubscribe@mothershut.com . MOTHER’S HUT will implement the change
        within three days of your request. MOTHER’S HUT will send an
        acknowledgement or confirmation of the request to the email address on
        file.
      </p>
      <h2>HOW DO I CORRECT OR UPDATE MY PERSONAL INFORMATION?</h2>
      <p>
        You control the accuracy of your Personal Information. If your Personal
        Information changes, feel free to update it from View Your Profile.
      </p>
      </div>
      <div>

      <h2>
        HOW DOES MOTHER’S HUT PROTECT THE SECURITY OF MY PERSONAL INFORMATION?
      </h2>
      <p>
        MOTHER’S HUT uses appropriate security measures to protect all Personal
        Information collected in a secure, controlled environment. In addition,
        MOTHER’S HUT configures the list server software to refuse to divulge
        the email addresses of the list subscribers to anyone other than
        authorized MOTHER’S HUT staff members, including other list subscribers.
        In the event of a security breach whereby your Personal Information is
        acquired by an unauthorized person, MOTHER’S HUT will take appropriate
        steps to contain the breach to the extent reasonably possible.
      </p>
      </div>
      <div>

      <h2>DOES MOTHER’S HUT’S SITE LINK TO OTHER SITES?</h2>

      <p>
        Yes. This Site may contain links to other Websites that may not be owned
        or operated by MOTHER’S HUT. MOTHER’S HUT cannot control nor is
        responsible for the privacy practices or content of such other Websites.
        MOTHER’S HUT encourages you to read the privacy statements of each and
        every Website that collects personally identifiable information. This
        Privacy Policy applies solely to Personal Information collected on this
        Site.
      </p>
      </div>
      <div>

      <h2>WHAT IS THE EFFECTIVE DATE OF MOTHER’S HUT PRIVACY POLICY AND HOW</h2>

      <p>
        WILL MOTHER’S HUT NOTIFY ME OF CHANGES TO ITS PRIVACY POLICY? In the
        event that the MOTHER’S HUT goes through a business transition, such as
        a merger, another COMPNY acquires MOTHER’S HUT or its Affiliates, or
        MOTHER’S HUT sells a portion of its assets, your personal information
        will, in most instances, be part of the assets transferred. Thirty days
        prior to the change of ownership or control, you will be informed of
        this exchange and its effects via prominent notice on Our site. As a
        result of a business transition, your personally identifiable
        information may be used in a manner different from that stated at the
        time of collection.
      </p>
      </div>
      <div>

      <h2>COPYRIGHT NOTICE,</h2>

      <p>
        The text, graphics (except the trademarks as noted above) and HTML and
        ASP.NET code contained in this Website are the exclusive property of the
        MOTHER’S HUT. Except where otherwise noted, the text, graphics and
        webpage code contained here may not be modified, copied, distributed,
        displayed, reproduced uploaded or transmitted in any form or by any
        means without the prior written permission of the MOTHER’S HUT. Except
        that you may print and download portions of material from the different
        areas of the site solely for your own non-commercial use provided that
        you agree not to change or delete any copyright or proprietary notices
        from the materials.
      </p>
      </div>
      <div>

      <h2>DOES MOTHER’S HUT HAVE ANY DELIVERY POLICY</h2>

      <p>
        We will deliver food as per your order within Krishnagar, West Bengal
        (INDIA) area at your specified address and within the time slot you have
        selected in order. Mother’s Hut will not be liable if the delivery got
        delayed due to any road congestion or any other cause.
      </p>
      </div>
      <div>

      <h2>Cancellation Policy :</h2>

      <p>
        The customer can cancel the order before shipment by the mode of call
        only, If the food is ready and customer wants to cancel the order then
        he/she needs to call Mother’s Hut and cancel it. In this case, it
        depends completely on Mother’s Hut whether the order is in a state of
        cancellation or not. And within 15 minutes after placing the order the
        customer have to call and cancel the order. Once the cancellation done,
        the amount will be fully refunded via original method of payment
        (deducting the online payment charges if applicable).
      </p>
      </div>
      <div>

      <h2>Return Policy :</h2>
      <p>
        If the food delivered is wrong or remain non delivered then the customer
        will be entertained within 24 hours. For any quality related issue/
        Damage food delivery the return completely depends on Mother’s Hut. It’s
        on Mother’s Hut whether the Food delivered is having quality issue or
        not. Its solely Mother’s Hut responsibility for Non delivery/ wrong
        Delivery of food, quality of food, delay for any reason (not in case of
        road traffic and congestions)
      </p>
      </div>

      <div>

      <h2>Refund Policy :</h2>
      <p>
        If customer paid twice for one transaction, the one transaction amount
        will be refunded via same source within 15 to 20 working days (deducting
        the online payment charges if applicable).
      </p>
      </div>
      <div>
      <h2>Replacement Policy :</h2>
      <p>
        The replacement of Food order depends completely on Mother’s Hut, after
        Mother’s Hut confirms that the Food delivered needs a replacement then
        only it will be done.
      </p>
      </div>
      <div>

      <h2>Delivery / Shipping Policy :</h2>
      <p>
        We try our best to make sure that you get your order within 1 to 2 Hours
        of placing it. It’s important that you remain at the delivery address
        you’ve given us to receive your order. If you’re not in to receive your
        order then we are not able to refund for delivery orders as all of our
        food is freshly cooked and we can’t resell it. Sometimes there may be
        reasons outside of our control that mean we can’t deliver your order
        within our targeted delivery time. Delivery drivers will only deliver to
        the main door/reception when delivering to apartment blocks, flats or
        hotels, so please make sure you come to meet them there. Please be nice
        to your delivery driver - they are bringing you yummy food! We reserve
        the right to blacklist any delivery address if any delivery driver
        reports aggressive or abusive behavior towards them.
      </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
