import React, { useContext, useEffect, useState } from "react";
import MainModalComponent from "../MainModalComponent";
import MainModalComponent2 from "../MainModalComponent2";
import Loader from "../Loader";
import TableAndAddress from "../TableAndAddress";
import MyContext from "../MyContext";

const MobilesSection2 = (props) => {
  const [loading, setLoading] = useState(false);

  const {
    handleServiceChange,
    serviceType,
    inputValues,
    openTable,
    showchange,
    setInputValues
  } = props;
  

  const {selectedService} = useContext(MyContext)

  const hidesevicetype = () => {
    showchange()
    openTable()
  };

  useEffect(()=>{
    setInputValues({
      serviceTypeId: selectedService
    })
  },[])

  return (
    <div className="modal-back">

        <div className="myordercart-mobile-section-2">
          <div className="xmark-ms-2" onClick={props.gotofrist}>
            <i className="fa-solid fa-xmark"></i>
          </div>
          {serviceType && (
            <>
              <h1 className="mycart-service-ty-pe-heading">
                <i
                  style={{ marginRight: "8px" }}
                  className="fa-solid fa-mug-hot f-18"
                ></i>
                Service Type
              </h1>
              <div className="mycart-service-ty-pe">
                <div
                  className="mycart-service-ty-pe-option"
                  onClick={() =>
                    handleServiceChange({
                      target: { id: `${serviceType[1]?.id}` },
                    })
                  }
                >
                  <p className="f-16 fw-600">Home Delivery</p>
                  <p className="f-12 ">
                    Delivery amount must be Rs 0 to deliver item
                  </p>
                  <input
                    type="radio"
                    name="serviceType"
                    value={inputValues.serviceTypeId}
                    checked={serviceType[1]?.id == inputValues.serviceTypeId}
                    onClick={() =>
                      handleServiceChange({
                        target: { id: `${serviceType[1]?.id}` },
                      })
                    }
                  />
                </div>
                <div
                  className="mycart-service-ty-pe-option"
                  onClick={() =>
                    handleServiceChange({ target: { id: serviceType[2]?.id } })
                  }
                >
                  <p className="f-16 fw-600">Pick Up</p>
                  <p className="f-12">
                    You will pick up the order yourself at the restaurant
                  </p>
                  <input
                    type="radio"
                    value={inputValues.serviceTypeId}
                    checked={serviceType[2]?.id == inputValues.serviceTypeId}
                    onClick={() =>
                      handleServiceChange({
                        target: { id: `${serviceType[2]?.id}` },
                      })
                    }
                  />
                </div>
                <div
                  className="mycart-service-ty-pe-option"
                  onClick={() =>
                    handleServiceChange({
                      target: { id: `${serviceType[0]?.id}` },
                    })
                  }
                >
                  <p className="f-15 fw-600">Table Order</p>
                  <p className="f-12">
                    Your order will be delivered to your table
                  </p>
                  <input
                    type="radio"
                    name="serviceType"
                    value={inputValues.serviceTypeId}
                    checked={serviceType[0]?.id == inputValues.serviceTypeId}
                    onClick={() =>
                      handleServiceChange({
                        target: { id: `${serviceType[0]?.id}` },
                      })
                    }
                  />
                </div>
                <button onClick={hidesevicetype}>Continue</button>
              </div>
            </>
          )}
   
        </div>
  
      {loading && <Loader />}
    </div>
  );
};

export default MobilesSection2;
