import React from "react";
import { Link } from "react-router-dom";
import logo1 from "../../Assets/Mothers hut logo.png"

const MobilSideBar = ({ showSidebar, handleClick }) => {
  const initialClass1 = "";
  const additionalClass1 = showSidebar ? "sidebarback" : "";

  const initialClass = "mobile-sidebar";
  const additionalClass = showSidebar ? "show" : "";

  const combinedClasses = `${initialClass} ${additionalClass}`.trim();
  const combinedClasses1 = `${initialClass1} ${additionalClass1}`.trim();
  return (
    <div onClick={handleClick} className={combinedClasses1}>
      <div className={combinedClasses}>
        <i onClick={handleClick} className="fa-solid fa-xmark navbar-close-btn"></i>

        <div className="sidebar-link-box">
          <div className="sidebar-logo">
            <img style={{height:"45px" ,marginRight:"10px"} } src={logo1} alt="" /> 

            Mother's hut
          </div>

          <Link className="navbar-option-link-txt" onClick={handleClick} to="/">
            <i className="sidebar-icon-link fa-solid fa-house"></i> Homepage
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/OurMenu"
          >
            <i className="sidebar-icon-link fa-solid fa-list"></i> Our Menu
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/MyFavorites"
          >
            <i className="sidebar-icon-link fa-regular fa-heart"></i> My
            Favorites
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/MyCart"
          >
            <i className="sidebar-icon-link fa-solid fa-cart-shopping"></i> My
            Cart
          </Link>
          {/* <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/MyCart"
          >
            <i className="sidebar-icon-link fa-solid fa-phone"></i> Contact us
          </Link> */}
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/MyAccountPage"
          >
            <i className="sidebar-icon-link fa-solid fa-bars-staggered"></i> My
            Order
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/MyCart"
          >
            <i className="sidebar-icon-link fa-brands fa-instagram"></i>{" "}
            Instagram
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="https://www.facebook.com/MothersHut/?ref=br_rs"
          >
            <i className="sidebar-icon-link fa-brands fa-facebook-f"></i>{" "}
            facebook
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/MyCart"
          >
            <i
              className="sidebar-icon-link fa-brands fa-youtube"
              onClick={handleClick}
            ></i>
            Youtube
          </Link>
          <Link
            className="navbar-option-link-txt"
            onClick={handleClick}
            to="/termsandconditions"
          >
            <i
              className="sidebar-icon-link fa-brands fa-youtube"
              onClick={handleClick}
            ></i>
            Terms and Conditions
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobilSideBar;
