import React, { useContext, useEffect, useState } from "react";
import ProductCardMenu from "../Component/ProductCardMenu";
import CartProduct from "../Component/CartProduct";
import ItemGroupApi from "../API/API_SERVISES/ItemGroup";
import ItemMasterApi from "../API/API_SERVISES/ItemMaster";
import LoginApi from '../API/API_SERVISES/Login'

import Loader from "../Component/Loader";
import { Link } from "react-router-dom";
import MyContext from "../Component/MyContext";
import TaxCategoryApi from "../API/API_SERVISES/TaxCategory";
// import LoginApi from "../API/API_SERVISES/Login";
import OrderApi from "../API/API_SERVISES/Order";
import Account from "../API/API_SERVISES/Account";
// import MyCart from "./MyCart";

const OurMenu = () => {

  const [loading, setLoading] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);
  const [leftPosition, setLeftPosition] = useState(-500);
  const [CategoryName, setCategoryName] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);

  const {
    setCartData,
    setOrderList3,
    setOrderList,
    cartData,
    orderList3,
    orderList,
    CompanyDetail,
    setProductList,
    productlist,
    setServiceTypeShow,
    setPaymentCart,
    setIsInputDisabled,
    imageBaseUrl
  } = useContext(MyContext);

  // document.addEventListener("DOMContentLoaded", function () {
  //   localStorage.clear();
  // });

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentUrl = window.location.href;
    console.log("Current URL:", currentUrl);
    //localStorage.clear();
    //currentUrl = "localhost:3000/OurMenu?qs101&202&1";

    if (currentUrl.includes("App")) {

      // console.log(JSON.stringify(localStorage));
      // debugger;
      // console.log("Local storage clear-------------");
      //debugger;
      setOrderList3([])
      localStorage.clear();
      setOrderList([])
      localStorage.removeItem('orderList');
      localStorage.removeItem('itemCount');
      localStorage.removeItem('orderList3');
      localStorage.removeItem('cartNumber');
      localStorage.removeItem('cartData');
      localStorage.removeItem('accountLogin');
      // console.log("Local storage end-------------");

      console.log(JSON.stringify(localStorage));

    }

    if (currentUrl.includes("qs")) {
      localStorage.clear();
      localStorage.removeItem("orderList");
      localStorage.removeItem("itemCount");
      localStorage.removeItem("orderList3");
      localStorage.removeItem("cartNumber");
      localStorage.removeItem("cartData");
      localStorage.removeItem("accountLogin");

      const queryString = currentUrl.split("?qs")[1];

      // Splitting the query string to extract individual values
      const loginData = queryString.split("&");

      // Assigning values to variables
      const d1AccountId = loginData[0] ? loginData[0].split('=')[1] : null;
      const d2AccountId = loginData[1] ? loginData[1] : null;
      const ord1Id = loginData[2] ? loginData[2] : null;

      setServiceTypeShow(false);
      setPaymentCart(false);
      setIsInputDisabled(true)
      setCartData([])

      const Accountdata = async () => {
        try {
          setLoading(true)
          const AccountDetail = await Account.getAccountById(d1AccountId);
          if (AccountDetail) {
            const DataForLogin = {
              name_email: AccountDetail.item1[0].mobileNo,
              password: AccountDetail.item1[0].password
            };
            console.log(DataForLogin);
            await LoginFunction(DataForLogin);
          }

        } catch (error) {
          console.error("Error fetching account details:", error);
        }
      };
      Accountdata();
      const LoginFunction = async (DataForLogin) => {
        try {
          const Login = await LoginApi.LogInAccount(DataForLogin);
          if (Login) {
            setLoading(false)
            localStorage.setItem("accountLogin", JSON.stringify(Login.result));
          } else {
            alert("Wrong ID and Password");
          }
        } catch (error) {
          console.error("Error logging in:", error);
        }
      };

      localStorage.setItem("ServiceType", 751)
      const addRunningOrder = async () => {
        const filteredData = [
          {
            fieldName: "ord1Id",
            operatorName: "Equal",
            compareValue: ord1Id,
          }
        ]
        //console.log(cartData)
        const runningOrderCart = await OrderApi.getOrderListFilter(filteredData);
        //console.log("cartData", runningOrderCart);

        const runningData = runningOrderCart.map((runningOrderCartData) => ({
          ord1Id: runningOrderCartData.ord1Id,
          accAddress: runningOrderCartData.accAddress,
          accDOB: runningOrderCartData.accDOB,
          accDOM: runningOrderCartData.accDOM,
          d2Id: runningOrderCartData.accId,
          accMobileNo: runningOrderCartData.accMobileNo,
          accName: runningOrderCartData.accName,
          accReferredById: runningOrderCartData.accReferredById,
          balanceAmt: runningOrderCartData.balanceAmt,
          billTypeId: runningOrderCartData.billTypeId,
          billTypeName: runningOrderCartData.billTypeName,
          cancelAmt: runningOrderCartData.cancelAmt,
          cancelApprovedBy: runningOrderCartData.cancelApprovedBy,
          cancelApprovedOn: runningOrderCartData.cancelApprovedOn,
          cancelQty: runningOrderCartData.cancelQty,
          cancelRemarks: runningOrderCartData.cancelRemarks,
          cancelRequestQty: runningOrderCartData.cancelRequestQty,
          cancelRequestedBy: runningOrderCartData.cancelRequestedBy,
          cancelRequestedOn: runningOrderCartData.cancelRequestedOn,
          cancelStatus: runningOrderCartData.cancelStatus,
          couponCode: runningOrderCartData.couponCode,
          couponCodeAmount: runningOrderCartData.couponCodeAmount,
          d1Id: runningOrderCartData.d1AccId,
          //d1ItemId: runningOrderCartData.d1ItemId,
          //d2ItemId: runningOrderCartData.d2ItemId,
          name: runningOrderCartData.d2ItemName,
          deliveredDate: runningOrderCartData.deliveredDate,
          deliveredTime: runningOrderCartData.deliveredTime,
          deliveryBoyId: runningOrderCartData.deliveryBoyId,
          deliveryBoyName: runningOrderCartData.deliveryBoyName,
          deliveryCharges: runningOrderCartData.deliveryCharges,
          deliveryDate: runningOrderCartData.deliveryDate,
          deliveryStatus: runningOrderCartData.deliveryStatus,
          deliveryTime: runningOrderCartData.deliveryTime,
          deliveryTimeId: runningOrderCartData.deliveryTimeId,
          grandTotal: runningOrderCartData.grandTotal,
          hasRunningOrder: runningOrderCartData.hasRunningOrder,
          iGodownId: runningOrderCartData.iGodownId,
          iGodownName: runningOrderCartData.iGodownName,
          isActive: runningOrderCartData.isActive,
          itemRunningOrderCounter: runningOrderCartData.itemRunningOrderCounter,
          itemRunningQty: runningOrderCartData.itemRunningQty,
          itemSuggestion: runningOrderCartData.itemSuggestion,
          kitchenManagerId: runningOrderCartData.kitchenManagerId,
          kitchenManagerName: runningOrderCartData.kitchenManagerName,
          kotDispatchTargetTime: runningOrderCartData.kotDispatchTargetTime,
          kotDispatchTime: runningOrderCartData.kotDispatchTime,
          latitude: runningOrderCartData.latitude,
          longitude: runningOrderCartData.longitude,
          noAdultPerson: runningOrderCartData.noAdultPerson,
          noChildPerson: runningOrderCartData.noChildPerson,
          noOfPerson: runningOrderCartData.noOfPerson,
          o1CancelledAmt: runningOrderCartData.o1CancelledAmt,
          o1TotalAmt: runningOrderCartData.o1TotalAmt,
          o2KotStatus: runningOrderCartData.o2KotStatus,
          o2OrderTypeStatus: runningOrderCartData.o2OrderTypeStatus,
          o2TotalAmt: runningOrderCartData.o2TotalAmt,
          o3OrderDateTime: runningOrderCartData.o3OrderDateTime,
          o3OrderStatus: runningOrderCartData.o3OrderStatus,
          ord1Id: runningOrderCartData.ord1Id,
          ord2Id: runningOrderCartData.ord2Id,
          ord3Id: runningOrderCartData.ord3Id,
          orderConfirmedById: runningOrderCartData.orderConfirmedById,
          orderConfirmedByName: runningOrderCartData.orderConfirmedByName,
          orderConfirmedOn: runningOrderCartData.orderConfirmedOn,
          orderDate: runningOrderCartData.orderDate,
          orderDoneById: runningOrderCartData.orderDoneById,
          orderDoneByName: runningOrderCartData.orderDoneByName,
          orderDoneOn: runningOrderCartData.orderDoneOn,
          orderNo: runningOrderCartData.orderNo,
          orderRunningQty: runningOrderCartData.orderRunningQty,
          orderSeriesId: runningOrderCartData.orderSeriesId,
          orderSeriesName: runningOrderCartData.orderSeriesName,
          orderSuggestion: runningOrderCartData.orderSuggestion,
          orderTime: runningOrderCartData.orderTime,
          overallFoodRating: runningOrderCartData.overallFoodRating,
          overallOrderStatus: runningOrderCartData.overallOrderStatus,
          overallRestaurantRating: runningOrderCartData.overallRestaurantRating,
          paidAmt: runningOrderCartData.paidAmt,
          paymentAmount: runningOrderCartData.paymentAmount,
          paymentAttemptedCounter: runningOrderCartData.paymentAttemptedCounter,
          paymentBank: runningOrderCartData.paymentBank,
          paymentDoneById: runningOrderCartData.paymentDoneById,
          paymentDoneByName: runningOrderCartData.paymentDoneByName,
          paymentDoneOn: runningOrderCartData.paymentDoneOn,
          paymentMode: runningOrderCartData.paymentMode,
          paymentModeName: runningOrderCartData.paymentModeName,
          paymentStatus: runningOrderCartData.paymentStatus,
          preferenceNo: runningOrderCartData.preferenceNo,
          //quantity: runningOrderCartData.qty,
          //salePrice: runningOrderCartData.rate,
          receivedDate: runningOrderCartData.receivedDate,
          receivedTime: runningOrderCartData.receivedTime,
          remarks: runningOrderCartData.remarks,
          returnAmount: runningOrderCartData.returnAmount,
          runningOrderCounter: runningOrderCartData.runningOrderCounter,
          runningOrderTotalAmount: runningOrderCartData.runningOrderTotalAmount,
          serviceTypeId: runningOrderCartData.serviceTypeId,
          serviceTypeName: runningOrderCartData.serviceTypeName,
          tableGroupId: runningOrderCartData.tableGroupId,
          tableGroupName: runningOrderCartData.tableGroupName,
          tableId: runningOrderCartData.tableId,
          tableName: runningOrderCartData.tableName,
          transction_img: runningOrderCartData.transction_img,
          color: "lightGreen",
        }))
        // console.log(runningData)
        setCartData(runningData);
      };
      addRunningOrder();
      localStorage.removeItem("orderList");
      localStorage.removeItem("cartNumber");
      localStorage.removeItem("itemCount");
    }
    else {
      // alert("else")
    }
    const urlData = currentUrl.split("?");
    if (urlData.length > 1 && urlData[1] === "App") {
      localStorage.setItem("OrderTaker", "Yes");
      localStorage.setItem("OrderTakerId", urlData[2]);
      setCartData([]);
      //alert("hello")
    }
    //}
    // **************************** Get order by ord1Id ****************

  }, []);

  //   useEffect(() => {
  //     // Get the string from local storage

  //     const orderlist = localStorage.getItem("orderList");
  //     const orderlist3 = localStorage.getItem("orderList3");
  //     const storedProductList = localStorage.getItem("productList");
  //     const cartData = localStorage.getItem("cartData");

  //     if (storedProductList) {
  //       const parsedProductList = JSON.parse(storedProductList);
  //       setConstextProduct(parsedProductList);
  //       setProductList(parsedProductList);
  //     }

  //     if (orderlist) {
  //       const parsedProductList1 = JSON.parse(orderlist);
  //       setOrderList(parsedProductList1);
  //     }

  //     if (orderlist3) {
  //       const parsedProductList2 = JSON.parse(orderlist3);
  //       setOrderList3(parsedProductList2);
  //     }

  //     if (cartData) {
  //       const parsedProductList3 = JSON.parse(cartData);
  //       setCartData(parsedProductList3);
  //     }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);   //true
      const filteredData = [
        {
          fieldName: "isActive",
          operatorName: "Equal",
          compareValue: true
        }
      ]
      try {
        //const categoryName = await ItemGroupApi.GetItemGroupFilter(filteredData);
        const categoryName = await ItemGroupApi.GetItemGroup();
        setCategoryName(categoryName);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);  //true
      const filteredData = [
        {
          fieldName: "isActive",
          operatorName: "Equal",
          compareValue: true
        }
      ]
      try {
        //const categoryName = await ItemMasterApi.GetItemMasterFilter(filteredData);
        const categoryName = await ItemMasterApi.GetItemMasterFilterWithPage();
        // console.log("categoryName")
        // console.log(categoryName)
        setProductList(categoryName);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // const handleQuantityChange = (productId, newQuantity) => {
  //   alert("himanshu function")
  //   console.log("on parent page", productId, newQuantity);

  //   // Find the updated product in the productlist array
  //   const updatedProduct = productlist.find(
  //     (product) => product.id === productId
  //   );

  //   // If the product exists in productlist, update its quantity
  //   if (updatedProduct) {
  //     // Update productlist with the new quantity
  //     const updatedProductList = productlist.map((product) => {
  //       if (product.id === productId) {
  //         return { ...product, quantity: newQuantity };
  //       }
  //       return product;
  //     });

  //     console.log(
  //       updatedProductList.find((product) => product.id === productId)
  //         .quantity *
  //         updatedProductList.find((product) => product.id === productId).price
  //     );

  //     // Set the updated productlist
  //     setProductList(updatedProductList);
  //     // localStorage.setItem("productList", JSON.stringify(updatedProductList));

  //     // Remove the array from orderList if the quantity is 0
  //     if (newQuantity === 0) {
  //       setOrderList(orderList.filter((product) => product.id !== productId));
  //     } else {
  //       // Find the product with the matching id in orderList
  //       const existingOrderProduct = orderList.find(
  //         (product) => product.id === productId
  //       );

  //       // If the product exists in orderList, update its quantity
  //       if (existingOrderProduct) {
  //         setOrderList(
  //           orderList.map((product) => {
  //             if (product.id === productId) {
  //               return {
  //                 ...product,
  //                 qty: newQuantity,
  //                 totalAmt: newQuantity * product.salePrice,
  //                 taxableAmount: newQuantity * product.salePrice,
  //                 totalTaxableAmt: newQuantity * product.salePrice,
  //               };
  //             }
  //             return product;
  //           })
  //         );
  //       } else {
  //         // If the product doesn't exist in orderList, add it with the updated quantity
  //         setOrderList([
  //           ...orderList,
  //           {
  //             godownId: 0, // will get from Item Master
  //             d1ItemId: updatedProduct.d1Id, // will get from Item Master
  //             d2ItemId: updatedProduct.d2Id, // will get from Item Master
  //             qty: newQuantity,
  //             rate: updatedProduct.salePrice,
  //             totalTaxableAmt: newQuantity * updatedProduct.salePrice,
  //             taxableAmount: newQuantity * updatedProduct.salePrice,
  //             totalAmt: newQuantity * updatedProduct.salePrice,
  //             kotStatus: "string",
  //             // preferenceNo: updatedProduct.deliveryPreference ,
  //             preferenceNo: "",
  //             itemSuggestion: "",
  //             cgstPer: 0,
  //             cgstValue: 0,
  //             sgstPer: 0,
  //             sgstValue: 0,
  //             igstPer: 0,
  //             igstValue: 0,
  //             cessPer: 0,
  //             cessValue: 0,
  //             isLocal: false,
  //             isInclusive: false,
  //             hasComplementaryItem: false,
  //             cookStatus: "",
  //             itemIncentivePoint: 0,
  //             id: updatedProduct.d1Id,
  //           },
  //         ]);

  //         setOrderList3({
  //           orderStatus: "string",
  //           orderDateTime: "",
  //         });
  //       }
  //     }
  //   }
  // };
  // console.log( "orderlist" ,orderList)

  useEffect(() => {
    // console.log("orderList:", orderList);
    // console.log("orderList3:", orderList3);
    // Save orderList and orderList3 to localStorage whenever they change
    // localStorage.setItem("orderList", JSON.stringify(orderList));
    localStorage.setItem("orderList3", JSON.stringify(orderList3));
  }, [orderList, orderList3]);

  const handleFavouriteChange = (productId, newFavorite) => {
    // console.log(newFavorite);
    const updatedProductList = productlist.map((product) => {
      if (product.id === productId) {
        return { ...product, favourite: newFavorite[0].favourite };
      }
      return product;
    });

    setProductList(updatedProductList);
    // Save the updated array to local storage
    // localStorage.setItem("productList", JSON.stringify(updatedProductList));
  };

  useEffect(() => {
    // console.log(product.quantity)
    const calculateTotalAmount = () => {
      console.log("this is my cartData list", cartData);


      // Calculate the total amount of all items in orderList
      const totalAmount = orderList.reduce((total, item) => {
        return total + (item.qty * item.rate);
      }, 0);

      const totalAmt = totalAmount;
      const taxableAmount = totalAmount;

      console.log('Total amount:', totalAmount);

      setTotalAmount(totalAmount);
      //setTaxableAmount(taxableAmount);
      //setTotalAmt(totalAmt);

    };
    calculateTotalAmount();
  }, [orderList]);



  const handleFavouriteChangeFalse = (productId, newFavorite2) => {
    const updatedProductList2 = productlist.map((product) => {
      if (product.id === productId) {
        return { ...product, favourite: newFavorite2.favourite };
      }
      return product;
    });
    setProductList(updatedProductList2);
    // console.log(productlist);
    // localStorage.setItem("productList", JSON.stringify(updatedProductList2));
  };

  const openmenu = () => {
    if (isAnimated) {
      setIsAnimated(false);
      setLeftPosition(-500);
    } else {
      setIsAnimated(true);
      setLeftPosition(0);
    }
    document.getElementById("body").classList.toggle("overflow-hidden");
  };

  //const mainCategories = CategoryName.filter(item => item.primaryGroup && item !== null && item !== undefined);
  //const subCategories = CategoryName.filter(item => !item.primaryGroup && item !== null && item !== undefined);

  const mainCategories = CategoryName && CategoryName.filter(item => item && item.primaryGroup);
  const subCategories = CategoryName && CategoryName.filter(item => item && !item.primaryGroup);


  // if (mainCategories && mainCategories.length > 0) {
  //   setLoading(false);
  // }
  // if (subCategories && subCategories.length > 0) {
  //   setLoading(false);
  // }


  // Now you can use the mainCategories and subCategories arrays


  // const product =  productlist.filter((item) => item.parentGrpId === subCategories.d2_Id);

  const menuClicked = (id) => {
    const containerDiv = document.getElementById("yourContainerDivId"); // Replace 'yourContainerDivId' with the actual ID of your container div
    const targetElement = document.getElementById(id);

    if (containerDiv && targetElement) {
      containerDiv.scrollTo({
        top: targetElement.offsetTop - 61, // Adjust with the offset from the top
        behavior: "smooth",
      });
    }
  };

  // const RemoveItemInCart = (id) => {
  //   if (quantity === 0) {
  //     setQuantity(0);
  //   } else {
  //     setQuantity(quantity - 1);
  //   }
  //   const itemIndex = cartData.findIndex((item) => item.d2Id === id);
  //   if (cartData[itemIndex].quantity > 1) {
  //     if (itemIndex !== -1) {
  //       const updatedCartData = [...cartData];
  //       updatedCartData[itemIndex].quantity -= 1;
  //       setCartData(updatedCartData);
  //       // changePrice(id, ((cartData[itemIndex].price * cartData[itemIndex].qty) - cartData[itemIndex].price));
  //       // *********************************
  //       const updatedOrderData = [...orderList];
  //       updatedOrderData[itemIndex].qty -= 1;
  //       setOrderList(updatedOrderData);
  //       localStorage.setItem("orderList", JSON.stringify(updatedOrderData));
  //     }
  //     // console.log( "dec", cartData)
  //   } else {
  //     deleteCartItemClick(id);
  //   }
  // };


  const RemoveItemInCart = (id) => {
    // Find the item in the cart
    const itemIndex = cartData.findIndex((item) => item.d2Id === id);

    // Check if the item exists in the cart
    if (itemIndex !== -1) {
      const item = cartData[itemIndex];

      // Decrease quantity by 1 if more than 1 exists, or remove the item entirely if quantity will be 0
      if (item.quantity > 1) {
        // Clone the cart data to maintain immutability
        const updatedCartData = [...cartData];
        updatedCartData[itemIndex].quantity -= 1;

        // Update the cart state
        setCartData(updatedCartData);
        const orderIndex = orderList.findIndex((Order) => Order.d2ItemId === id);
        console.log(orderIndex);
        const updatedOrderData = [...orderList];
        // console.log(updatedOrderData);
        updatedOrderData[orderIndex].qty -= 1;
        updatedOrderData[orderIndex].totalAmt = updatedOrderData[orderIndex].qty * updatedOrderData[orderIndex].rate;

        setOrderList(updatedOrderData);

        // alert('Item quantity decreased');
      } else {
        // Remove the item from the cart entirely
        const updatedCartData = cartData.filter((item, index) => index !== itemIndex);
        setCartData(updatedCartData);

        // Also, update the orderList to remove the item
        // const updatedOrderData = orderList.filter((item, index) => index !== itemIndex);
        // setOrderList(updatedOrderData);

        const updatedOrderData = orderList.filter(
          (order) => order.d2ItemId !== id
        );
        console.log(updatedOrderData);
        setOrderList(updatedOrderData);

        // alert('Item removed from the cart');
      }
    } else {

    }
  };

  const [itemName, setItemName] = useState();
  const [showToast, setShowToast] = useState(false);

  const handleButtonClick = () => {

    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    });
  };

  const addingItemInCart = async (id) => {
    setLoading(true)
    setQuantity(quantity + 1);
    const product = productlist.find((item) => item.d2Id === id);
    const selectedItem = product;
    // console.log(selectedItem)

    if (selectedItem) {

      if (cartData && cartData.length > 0 && cartData[0].ord1Id !== 0 && cartData[0].ord1Id !== undefined) {
        // alert("Run");
        localStorage.removeItem('orderList');
        localStorage.removeItem('itemCount');
        localStorage.removeItem('orderList3');
        localStorage.removeItem('cartNumber');
        localStorage.removeItem('cartData');
      } else {
        // Handle the case where cartData is undefined or the conditions are not met
      }

      const itemIndex = cartData.findIndex((item) => item.d2Id === id);
      // console.log(cartData);
      if (itemIndex !== -1) {
        handleIncrementValue(id);
      } else {
        setCartData((prevCartData) => {
          const updatedOrderStatus = [...prevCartData];
          const updatedItem = {
            ...selectedItem,
            "quantity": 1,
            "deliveryPreference": 0,
            "ord1Id": 0,
          };
          updatedOrderStatus.push(updatedItem);
          return updatedOrderStatus;
        });

        setOrderList3({
          orderStatus: "Received",
          orderDateTime: "2024-02-06T09:54:21.043Z",
        });
        //  console.log( "tax" ,selectedItem.taxCategory1)

        console.log(selectedItem);
        if (selectedItem.taxCategory1 !== 0 && selectedItem.taxCategory1 !== null) {
          // console.log(quantity * selectedItem.salePrice)
          const data = await TaxCategoryApi.getTaxCategoryById(selectedItem.taxCategory1);
          console.log(data)

          setOrderList([
            ...orderList,
            {

              godownId: selectedItem.defaultGodown, //will get from Item Master
              d1ItemId: selectedItem.d1Id, //will get from Item Master
              d2ItemId: selectedItem.d2Id, //will get from Item Master
              qty: 1, //selectedItem.quantity,
              rate: selectedItem.salePrice,
              totalAmt: 1 * selectedItem.salePrice, //selectedItem.quantity
              kotStatus: "Received",
              preferenceNo: 0, //selectedItem.deliveryPreference,
              orderDate: "",
              orderTime: "",
              itemSuggestion: "",
              itemRunningOrderCounter: 0,
              orderRunningQty: 0,
              itemRunningQty: 0,
              taxableAmount: 1 * selectedItem.salePrice,
              cgstPer: data.localTaxCGST || 0,
              cgstValue:
                1 * selectedItem.salePrice * (data.localTaxCGST / 100),
              sgstPer: data.localTaxSGST,
              sgstValue:
                1 * selectedItem.salePrice * (data.localTaxSGST / 100),
              igstPer: data.centralTaxIGST,
              igstValue:
                1 * selectedItem.salePrice * (data.centralTaxIGST / 100),
              cessPer: data.cessTax,
              cessValue:
                1 * selectedItem.salePrice * (data.cessTax / 100),
              totalTaxableAmt: 1 * selectedItem.salePrice,
              isLocal: true, //true for table order
              isInclusive: false,
              hasComplementryItem: selectedItem.addasComplementaryItem,
              cookStatus: "Pending",
              itemIncentivePoint: selectedItem.salesManItemIncentive,
              // ord2Id: 0,
            },
          ]);

          // localStorage.setItem("orderList", JSON.stringify(orderList));
        } else {
          // alert("else")
          // console.log("else tax category")
          console.log(selectedItem)
          setOrderList([
            ...orderList,
            {

              godownId: selectedItem.defaultGodown, //will get from Item Master
              d1ItemId: selectedItem.d1Id, //will get from Item Master
              d2ItemId: selectedItem.d2Id, //will get from Item Master
              qty: 1, //selectedItem.quantity,
              rate: selectedItem.salePrice,
              totalAmt: 1 * selectedItem.salePrice, //selectedItem.quantity
              kotStatus: "Received",
              preferenceNo: 0, //selectedItem.deliveryPreference,
              orderDate: "",
              orderTime: "",
              itemSuggestion: "",
              itemRunningOrderCounter: 0,
              orderRunningQty: 0,
              itemRunningQty: 0,
              taxableAmount: 1 * selectedItem.salePrice,
              cgstPer: 0,
              cgstValue: 0,
              sgstPer: 0,
              sgstValue: 0,
              igstPer: 0,
              igstValue: 0,
              cessPer: 0,
              cessValue: 0,
              totalTaxableAmt: 1 * selectedItem.salePrice,
              isLocal: false,
              isInclusive: false,
              hasComplementryItem: false,
              cookStatus: "Pending",
              itemIncentivePoint: 0,
              ord2Id: 0,
            },
          ]);
          // localStorage.setItem("orderList", JSON.stringify(orderList));
        }
        //}
      }
    }
    setLoading(false)
  };

  const deleteCartItemClick = (id) => {
    const updatedCartData = cartData.filter((item) => item.d2Id !== id);
    setCartData(updatedCartData);
  };


  const handleIncrementValue = (id, itemName) => {
    console.log(orderList)
    console.log(cartData)
    setItemName(itemName)
    handleButtonClick();
    const itemIndex = cartData.findIndex((item) => item.d2Id === id);
    console.log(itemIndex);
    if (itemIndex !== -1) {
      const updatedCartData = [...cartData];
      updatedCartData[itemIndex].quantity += 1;
      //updatedCartData[itemIndex].price = (updatedCartData[itemIndex].quantity + 1) * updatedCartData[itemIndex].price;
      setCartData(updatedCartData);
      // **********************************
      const orderIndex = orderList.findIndex((Order) => Order.d2ItemId === id);
      console.log(orderIndex);
      const updatedOrderData = [...orderList];
      updatedOrderData[orderIndex].qty += 1;
      updatedOrderData[orderIndex].totalAmt = updatedOrderData[orderIndex].qty * updatedOrderData[orderIndex].rate;

      setOrderList(updatedOrderData);
    }
  };

  useEffect(() => {
    console.log("orderlist", orderList);
    console.log("cart data", cartData);
  }, [orderList, cartData]);

  const [currentTimeforService, setCurrentTimeforService] = useState(
    getCurrentTime()
  );

  const [currentDateforService, setCurrentDateforService] = useState(
    getCurrentDate()
  );
  const [filteredArray, setFilteredArray] = useState([]);

  useEffect(() => {
    if (CompanyDetail.isEnblItmAvailTimeChk === true) {
      if (Array.isArray(productlist)) {
        const filteredResult = productlist.filter((item) => {
          var time = item.restoAvailableTime.length;
          // console.log(time);

          if (time > 0) {
            let foundMatch = false;
            for (var i = 0; i < time; i++) {
              // console.log(typeof item.restoAvailableTime[i].availableTimeTo);
              var AvltimeFrom = item.restoAvailableTime[i].availableTimefrom; //? item.restoAvailableTime[i].availableTimeTo === null :""
              var AvltimeTo = item.restoAvailableTime[i].availableTimeTo; // ? item.restoAvailableTime[i].availableTimeTo === null :""
              // console.log(AvltimeTo, "-------", AvltimeFrom);

              const formattedTimeFrom = getCurrentTime1(AvltimeFrom);
              const formattedTimeTo = getCurrentTime1(AvltimeTo);

              // const currentTime = new Date(`1970-01-01T${currentTimeforService}`);
              // console.log(  formattedTimeFrom, formattedTimeTo,currentTime);
              // console.log(
              //   formattedTimeFrom,
              //   "---",
              //   formattedTimeTo,
              //   "---",
              //   currentTimeforService
              // );

              // const availableTimeFrom = new Date(`1970-01-01T${formattedTimeFrom}`);
              // const availableTimeTo = new Date(`1970-01-01T${formattedTimeTo}`);
              // console.log(  availableTimeFrom ,"---", availableTimeTo,"---",currentTime);

              if (
                currentTimeforService >= formattedTimeFrom &&
                currentTimeforService <= formattedTimeTo
              ) {
                foundMatch = true;
                break; // Exit the loop once a match is found
              }
            }

            return foundMatch; // Return true if a match was found, false otherwise
          } else {
            console.log("Else");
            // Handle the case where time is 0 (or any other specific logic)
            return true; // No match if time is 0
          }
        });

        setFilteredArray(filteredResult);
        console.log(filteredArray);
      }
    }
    else {
      setFilteredArray(productlist)
    }
  }, [productlist, currentTimeforService]);



  useEffect(() => {
    if (CompanyDetail.isEnblItmAvailTimeChk === true) {
      if (Array.isArray(productlist)) {
        const filteredResult = productlist.filter((item) => {
          var time = item.restoAvailableTime.length;
          console.log(time);

          if (time > 0) {
            let foundMatch = false;
            for (var i = 0; i < time; i++) {
              // console.log(typeof item.restoAvailableTime[i].availableTimeTo);
              var AvltimeFrom = item.restoAvailableTime[i].availableTimefrom; //? item.restoAvailableTime[i].availableTimeTo === null :""
              var AvltimeTo = item.restoAvailableTime[i].availableTimeTo; // ? item.restoAvailableTime[i].availableTimeTo === null :""
              // console.log(AvltimeTo, "-------", AvltimeFrom);

              const formattedTimeFrom = getCurrentTime1(AvltimeFrom);
              const formattedTimeTo = getCurrentTime1(AvltimeTo);

              // const currentTime = new Date(`1970-01-01T${currentTimeforService}`);
              // // console.log(  formattedTimeFrom, formattedTimeTo,currentTime);
              // console.log(
              //   formattedTimeFrom,
              //   "---",
              //   formattedTimeTo,
              //   "---",
              //   currentTimeforService
              // );

              // const availableTimeFrom = new Date(`1970-01-01T${formattedTimeFrom}`);
              // const availableTimeTo = new Date(`1970-01-01T${formattedTimeTo}`);
              // console.log(  availableTimeFrom ,"---", availableTimeTo,"---",currentTime);

              if (
                currentTimeforService >= formattedTimeFrom &&
                currentTimeforService <= formattedTimeTo
              ) {
                foundMatch = true;
                break; // Exit the loop once a match is found
              }
            }

            return foundMatch; // Return true if a match was found, false otherwise
          } else {
            console.log("Else");
            // Handle the case where time is 0 (or any other specific logic)
            return true; // No match if time is 0
          }
        });

        setFilteredArray(filteredResult);
        console.log(filteredArray);
      }
    }
    else {
      setFilteredArray(productlist)
    }


  }, [productlist, currentTimeforService]);

  //  console.log(filteredArray);
  //  console.log(currentDateforService);

  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }


  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so we add 1
    const day = now.getDate().toString().padStart(2, "0");
    return `${day}-${month}-${year}`;
  }


  // Sample time string
  // const timeString = "09:30:00"; // Format: HH:MM:SS
  function getCurrentTime1(AvalTime) {
    console.log(AvalTime);
    if (typeof AvalTime !== "string") {
      return "Invalid input: AvalTime must be a string";
    }

    // Split the time string by ":" to get individual components
    const timeComponents = AvalTime.split(":");
    const hours = parseInt(timeComponents[0], 10);
    const minutes = parseInt(timeComponents[1], 10);
    const seconds = parseInt(timeComponents[2], 10);

    // Construct a new Date object with today's date and the extracted time components
    const timeObject = new Date();
    timeObject.setHours(hours);
    timeObject.setMinutes(minutes);
    timeObject.setSeconds(seconds);

    return `${hours}:${minutes}:${seconds}`;
  }

  return (
    <div>
      <div className="ourmenu-page">
        <div className="menu-open" onClick={openmenu}></div>
        <div
          className={`ourmenu-left-section displaynone  ${isAnimated ? "move-animation" : "move-animation-2"
            }`}
          style={{
            transform: `translateX(${leftPosition}px)`,
            position: "absolute",
          }}
        >
          <div className="ourmenu-filter">
            <div className="product-category-head">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <label>&nbsp; Product Category</label>
                <label style={{ fontSize: "18px" }} onClick ={openmenu}>x &nbsp; </label>
              </div>
            </div>
            <div style={{ display: "none" }} className="scroll-in-mobile">
              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">BREAKFAST</p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">MOTHERS HUT SPECIAL </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">BASHUDHA বসুধা </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">MOTHER`S HUT BUFFET </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">SOUP </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">VEG STARTER </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">NON VEG STARTER </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">RICE- ROTI- NOODLES </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">RICE- ROTI- NOODLES </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">BIRYANI </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">SALAD - RAITA - PAPAD </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">THALI </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">CONTINENTAL FOODS </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">DHOSA AND UTHAPPAM </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">CHATPATA </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">COLD N HOT BEVERAGE </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">DESSERT </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>

              <div className="catogory-box" onClick={openmenu}>
                <p className="product-category">MOTHERS BAKERY </p>
                <i className="fa-solid fa-chevron-right"></i>
              </div>
            </div>
            {/* <div className="scroll-in-mobile">
              {CategoryName.map((category) => (
                <div
                  className="catogory-box"
                  onClick={openmenu}
                  key={category.id}
                >
                  <p className="product-category">{category.name} </p>
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              ))}
            </div> */}

            <div className="scroll-in-mobile">
              {CategoryName && CategoryName.map((category) => (
                <div
                  className="catogory-box"
                  onClick={openmenu}
                  key={category.id}
                >
                  <p className="product-category">{category.name} </p>
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              ))}
            </div>

          </div>
        </div>

        <div className="ourmenu-left-section displey-none">
          <div className="ourmenu-filter">
            <div className="product-category-head">Product Caregory </div>

            <div className="scrollpart-cart-2">
              {CategoryName && CategoryName.filter(
                (category) => category.primaryGroup === true && category.showInMenu === true
              ).sort((a, b) => a.displayOrder - b.displayOrder).map((category) => (
                <div
                  className="catogory-box"
                  key={category.d2Id}
                  onClick={() => menuClicked(category.d2_Id)}
                >
                  <p className="product-category">{category.name} </p>
                  <i className="fa-solid fa-chevron-right"></i>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="ourmenu-right-section" id="yourContainerDivId">
          <div>
            {/* {
              mainCategories
                .filter((mainCategory) => mainCategory.showInMenu === true)
                .sort((a, b) => a.displayOrder - b.displayOrder) // Sort by display order
                .map((mainCategory) => ( */}

            {mainCategories &&
              mainCategories
                .filter(category => category.primaryGroup === true && category.showInMenu === true)
                .map((mainCategory) => (
                  <>
                    <div className="ourmenu-topdiv" id={mainCategory.d2_Id}>
                      <h4>
                        {mainCategory.name}
                        <i className="fa-solid fa-utensils cart-icon-utensile"></i>{" "}
                      </h4>
                    </div>
                    <div>
                      {subCategories
                        .filter(
                          (subCategory) =>
                            subCategory.parentGrp === mainCategory.d1_Id
                        )
                        .map((subCategory) => (
                          <>
                            <div
                              className="ourmenu-heading-submenu"
                              key={subCategory.d2_Id}
                            >
                              {subCategory.name}
                            </div>
                            <div className="ourmenu-product-box">
                              {filteredArray
                                .filter(
                                  (item) =>
                                    item.parentGrpId === subCategory.d2_Id &&
                                    item.attributeParentId === 0
                                )
                                .map((item) => (
                                  <ProductCardMenu
                                    setOrderList3={setOrderList3}
                                    addingItemInCart={addingItemInCart}
                                    RemoveItemInCart={RemoveItemInCart}
                                    cartData={cartData}
                                    product={item}
                                    productlist1={productlist}
                                    setCartData={setCartData}
                                    orderList3={orderList3}
                                    orderList={orderList}
                                    setOrderList={setOrderList}
                                    onFavChange={handleFavouriteChange}
                                    onFavChangefalse={handleFavouriteChangeFalse}
                                    imageBaseUrl={imageBaseUrl}
                                  />
                                ))}

                            </div>
                          </>
                        ))}
                    </div>
                  </>
                ))}
          </div>

          <div style={{ display: "none" }} className="ourmenu-product-box">
            <ProductCardMenu
              product={productlist}
              onFavChange={handleFavouriteChange}
              onFavChangefalse={handleFavouriteChangeFalse}
            />
          </div>
        </div>

        <div className="ourmenu-cart-section">
          <div className="product-category-head">
            My Cart
            <i
              style={{ marginLeft: "5px", fontSize: "15px" }}
              className="fa-solid fa-cart-shopping"
            ></i>
          </div>
          <div className="scrollpart-cart">
            <CartProduct
              orderList={orderList}
              product={cartData}
              addingItemInCart={addingItemInCart}
              RemoveItemInCart={RemoveItemInCart}
            // onQuantityChange={handleQuantityChange}
            />
          </div>
          <div className="product-category-foot-1">
            <div
              style={{
                width: "98%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontWeight: "600" }}>Subtotal</div>
              <div style={{ fontWeight: "600" }}>
                <i
                  style={{ marginRight: "5px", fontSize: "13px" }}
                  className="fa-solid fa-indian-rupee-sign rupees-icon"
                ></i>
                {totalAmount}
              </div>
              <div style={{ display: "none" }}></div>
            </div>
            <div
              style={{
                width: "98%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontWeight: "600" }}>Extra charges may apply</div>
              <div style={{ fontWeight: "600" }}></div>
            </div>
          </div>
          <Link to="/MyCart" className="product-category-foot">
            Checkout{" "}
          </Link>
        </div>
      </div>

      {loading && <Loader />}
    </div>
  );
};

export default OurMenu;
