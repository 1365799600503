import React, { useContext, useEffect, useState } from "react";
import DeliveryAndTimeSlot from "../API/API_SERVISES/DeliveryAndTimeSlot";
import MiscellaneousMaster from "../API/API_SERVISES/MiscellaneousMaster";
import AddAddress from "../API/API_SERVISES/AddAddress";
import GoogleMap from "./GoogleMap";
import MyContext from "./MyContext";

const padWithZero = (number) => {
  return number < 10 ? `0${number}` : `${number}`;
};


const getNext5Days = () => {
  const today = new Date();
  const next5Days = [];

  for (let i = 0; i < 5; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    const formattedDate = `${padWithZero(date.getDate())}/${padWithZero(
      date.getMonth() + 1
    )}/${date.getFullYear()}`;
    next5Days.push(formattedDate);
  }

  return next5Days;
};

const MainModalComponent2 = (props) => {

  const {selectedService} = useContext(MyContext)
  const next5Days = getNext5Days();

  const [Distance, setDistance] = useState(4);
  const [TimeSlot, setTimeslot] = useState([]);
  const [TimeSlotAccToKM, setTimeSlotAccToKM] = useState({});
  const [addresses, setAddAddresses] = useState([]);


  // useEffect(() => {
  //   const fetchTimeSlot = async () => {
  //     props.setLoading(true)
  //     try {
  //       const data = await DeliveryAndTimeSlot.GetDeliveryAndTimeSlot();
  //       // console.log(data);

  //       if (data) {
  //         setTimeslot(data);
  //         props.setLoading(false)
  //       } else {
  //         props.setLoading(false)
  //         alert("No data found in alloted");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching time slots:", error);
  //       // Handle the error appropriately, e.g., show an error message to the user
  //     }
  //   };

  //   fetchTimeSlot();
  // }, []); // Make sure to provide an empty dependency array to run the effect only once when the component mounts

  useEffect(() => {
    const fetchTime = () => {
      props.setLoading(true)
      // Function to find the array element based on delivery distance
      function findArrayElement(variable) {
        for (const element of TimeSlot) {
          if (
            variable >= element.delvryDistRangeFromKM &&
            variable <= element.delvryDistRangeToKM
          ) {
            return element;
          }
        }
        return null;
      }

      const result = findArrayElement(Distance);
      if (result) {
        props.setLoading(false)
        setTimeSlotAccToKM(result.deliveryTimeSlots);
      } else {
        props.setLoading(false)
        // console.log("No time slot found for the given distance");
      }
    };

    fetchTime(); // Call fetchTime inside the second useEffect
  }, [Distance, TimeSlot]); // Include Distance and TimeSlot in the dependency array

  // Assuming you have a state variable like this
  const [miscellaneousData, setMiscellaneousData] = useState([]);

  // useEffect(() => {
  //   const fetchMiscellaneousData = async (id) => { 
  //     try {
  //       props.setLoading(true)
  //       const miscellaneousTime =
  //         await MiscellaneousMaster.GetMiscellaneousMasterById(id, 170);
  //       // console.log("MiscellaneousTime", miscellaneousTime);
  //       return miscellaneousTime;
  //     } catch (error) {
  //       props.setLoading(false)

  //       console.error("Error fetching miscellaneous data:", error);
  //       return null;
  //     }
  //   };

  //   // Check if TimeSlotAccToKM is an array before using forEach
  //   if (Array.isArray(TimeSlotAccToKM)) {
  //     // Initialize an empty array to store responses
  //     const responses = [];

  //     // Use Promise.all to wait for all API calls to complete
  //     Promise.all(
  //       TimeSlotAccToKM.map(async (id) => {
  //         const response = await fetchMiscellaneousData(id);
  //         responses.push(response);
  //       })
  //     ).then(() => {
  //       // Now responses array contains all the API responses
  //       setMiscellaneousData(responses);
  //       props.setLoading(false)

  //     });
  //   } else {
  //     console.error("TimeSlotAccToKM is not an array");
  //   }
  // }, [TimeSlotAccToKM]);



  const dataForAllotedTime = {
    deliveryPointName: 1,
    orderType: selectedService,
    distance: 0
  }


  useEffect(() => {
    const fetchdata = async () => {
      props.setLoading(true)
      try {
        const data = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(dataForAllotedTime);
        if (data) {
          setMiscellaneousData(data);
          props.setLoading(false)
        } else {
          props.setLoading(false)
          alert("No data found in alloted");
        }
      } catch (error) {
        console.error("Error fetching time slots:", error);
        // Handle the error appropriately, e.g., show an error message to the user
      }
    }

    fetchdata()

  }, [selectedService])
  useState(() => {
    const fetchAddress = async () => {
      var loginDataString = localStorage.getItem("accountLogin");
      var loginData = JSON.parse(loginDataString);
      if (loginData) {
        var addresses = await AddAddress.getAddress(loginData.d1Id);
        // console.log(addresses);
        if (addresses) {
          setAddAddresses(addresses);
        } else {
          alert("Error Occoured");
        }
      }
    }

    fetchAddress();
  }, [addresses]);

  const [selectedOption, setSelectedOption] = useState(0);

  const handleOptionChange = async (index, addressId) => {
    //debugger;
    setSelectedOption(index);

    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);

    var getaddress = await AddAddress.getAddressById(addressId, loginData.d1Id);
    if (getaddress.isDefault == false) {
      const updatedValues = {
        ...getaddress,
        "isDefault": true,
      };
      await AddAddress.updateAddress(updatedValues, loginData.d1Id);
    }
  };

  const [addAddress, setAddAddress] = useState();

  const getLocalStorageData = () => {
    var googleData = localStorage.getItem("googleMapData");
    var googleMap = JSON.parse(googleData);
    // console.log("Google Map data:", googleMap);
    if (googleData !== null) {
      const googleMap = JSON.parse(googleData);
      const address = googleMap.destination_addresses[0];
      // console.log("Address:", address);
      setAddAddress(address);
    }
  }

  const [triggerUseEffect, setTriggerUseEffect] = useState(false)
  useEffect(() => {
    getLocalStorageData();
  });
  useEffect(() => {
    getLocalStorageData();
    // console.log(triggerUseEffect);
    setTriggerUseEffect(false)
  }, [triggerUseEffect]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div className="main-modal-section-2">
        <div style={{ marginBottom: "8px" }} className="">
          Home Delivery Location             {/* {props.heading1} */}
        </div>
        <div className="select-k1" style={{ width: "100%" }}>
          <select name="" id="">
            <option value="">Mother's Hut</option>
            {/* <option value="">Other's</option> */}
          </select>
        </div>
      </div>

      <div className="main-modal-section-4">
        <div style={{ marginBottom: "8px" }} className="">
          {props.heading1}
        </div>

        <div className="select-k1 address-box-formodal" style={{ width: "100%" }}>
          {addAddress && addAddress.length > 0 ? (
            <div>
              <label style={{ color: "white" }}>{addAddress}</label>
            </div>
          ) : (
            <div></div>
          )}

          {addresses && addresses.length > 0 ? (
            addresses.map((address, index) => (
              <div className="addressDiv" onClick={() => handleOptionChange(index, address.id)}>
                <label className="label-1">
                  <input className="input-1"
                    type="radio"
                    value={index}
                    checked={selectedOption === index}
                    onChange={() => handleOptionChange(index, address.id)}
                  />
                  <label style={{ fontSize: "12px", marginLeft: "20px" }}> {address.address}</label>
                  <span className="checkmark"></span>
                </label>
              </div>
            ))
          ) : (
            <div></div>
          )}
          {/* <select>
            {addresses && addresses.length > 0 ? (
              addresses.map((address, index) => (
                <option>
                  <div className="address-box" key={index}>
                    <div className="address-text">{address.locality} </div>
                    <div className="address-text">{address.address} </div>

                  </div>
                </option>
              ))
            ) : (
              <div></div>
            )}
          </select> */}
        </div>
        <div className="text-between-lines">
          <hr className="line2" />
          <span className="text">OR</span>
          <hr className="line2" />
        </div>
        <div>
          <GoogleMap setTriggerUseEffect={setTriggerUseEffect} />
        </div>

      </div>
      <div className="main-modal-section-3">
        <div className=""> {props.heading2} </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div className="select-k1" style={{ width: "50%" }}>
            <label htmlFor="">Delivery Date</label>
            <select name="" id="">
              {next5Days.map((date, index) => (
                <option key={index} value={date}>
                  {date}
                </option>
              ))}
            </select>
          </div>
          <div className="select-k1" style={{ width: "50%" }}>
            <label htmlFor="">Delivery Time</label>
            <select name="" id="" >

              {(() => {
                const AllottedTimeSlot1 = props.AllottedTimeSlot

                if (
                  AllottedTimeSlot1 &&
                  Array.isArray(AllottedTimeSlot1) &&
                  AllottedTimeSlot1.length > 0
                ) {
                  return AllottedTimeSlot1.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.availableFrom} to{" "} {data.availableTo}
                    </option>
                  ));
                } else {
                  return (
                    <option value="">Not available</option>
                  );
                }
              })()}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainModalComponent2;
