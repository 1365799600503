import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const BillSeriesMaster = {

    insertBillSeriesMaster: async (data) => {
        debugger;
        try {
            const apiUrl = `${WebApiUrl}/api/BillSeriesMaster`;
            console.log(apiUrl);
            console.log(data);
            const response = await axios.post(apiUrl, {
                masterType: Number(data.masterType),
                billSeriesType: true,
                billSeriesName: data.billSeriesName,
                isActive: true,
                isDefault: Boolean(data.isDefault),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    getBillSeriesMaster: async () => {

        try {
            const apiUrl = `${WebApiUrl}/api/BillSeriesMaster/GetBillSeriesMasters`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Get bill series Filtered ***************************
    getBillSeriesMasterFiltered: async (filterData) => {
        const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
        }));
        try {
            const apiUrl = `${WebApiUrl}/api/BillSeriesMaster/GetBillSeriesMasters`;
            const response = await axios.post(apiUrl, {
                filter: filteredData,
                order: [
                    {
                        propertyName: "string",
                        ascending: true
                    }
                ],
                pageNumber: 0,
                pageSize: 0
            }, {
                withCredentials: true,
            });
            console.log('Response:', response);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // ************************* Delete ****************************
    deleteBillSeriesMaster: async (billSeriesId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/BillSeriesMaster?billSeriesId=${billSeriesId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Get By Id ****************************
    getbillSeriesId: async (billSeriesId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/BillSeriesMaster?billSeriesId=${billSeriesId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    // *************************** Update ***************************

    updateBillSeriesMaster: async (data) => {
        console.log(data.id)
        try {
            const apiUrl = `${WebApiUrl}/api/BillSeriesMaster?billSeriesId=${data.id}`;
            console.log(apiUrl);
            const response = await axios.put(apiUrl, {
                id: data.id,
                masterType: Number(data.masterType),
                billSeriesType: Number(data.billSeriesType),
                billSeriesName: data.billSeriesName,
                isActive: Boolean(data.isActive),
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data.isSuccess;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
};

export default BillSeriesMaster;
