import React, { useState } from 'react'

const LoginPanel = (props) => {
    const { handleInputChangeSignIn,
        signupshow1,
        LoginAccount,
        forgotPaggword,
        inputValuesForLogin,
        handleInputChangeLogin,
    } = props

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = (val) => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="form-container">
            <p className="title">Login</p>
            <form className="form">
                <div className="input-group">
                    <label htmlFor="username">Email/Mob.No</label>
                    <input
                        type="text"
                        id="username"
                        placeholder=""
                        onChange={handleInputChangeLogin}
                        value={inputValuesForLogin.name_email}
                        name="name_email"
                    />
                </div>
                {/* <div className="input-group">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        placeholder=""
                        onChange={handleInputChangeLogin}
                        value={inputValuesForLogin.password}
                        name="password"
                    />
                    <div className="forgot" onClick={forgotPaggword}>
                        <div rel="noopener noreferrer">Forgot Password ?</div>
                    </div>
                </div> */}
                <div className="input-group image-input-container" style={{ width: "100%" }}>
                <label htmlFor="password">Password</label>
                    <div className="input-box-mok1">
                        <input className="modify_im_input mode_category1" type={showPassword ? 'text' : 'password'}
                            onChange={handleInputChangeLogin}
                            name="password" value={inputValuesForLogin.password} required />
                        <div className="forgot" onClick={forgotPaggword}>
                            <div rel="noopener noreferrer">Forgot Password ?</div>
                        </div>
                    </div>
                    <div className="input-image" onClick={() => togglePasswordVisibility('password')}>{showPassword ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}</div>
                </div>
                <div onClick={LoginAccount} className="sign">
                    Log in
                </div>
            </form>
            <p className="signup">
                Don't have an account?
                <div
                    style={{
                        cursor: "pointer",
                        marginTop: "5px",
                        fontSize: "14px",
                    }}
                    // onClick={props.removeaccount}
                    rel="noopener noreferrer"
                    className=""
                    onClick={signupshow1}
                >
                    Sign up
                </div>
            </p>
        </div>
    )
}

export default LoginPanel