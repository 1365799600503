
import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Payment = {

    getPaymentOptions: async (ruppees) => {
        debugger;
        const options = {
            key: 'rzp_test_6gzv7z3KTwC4Gp',
            amount: ruppees + "00",
            currency: 'INR',
            name: 'Techcherry',
            description: 'Software Company',
            handler: function (response) {
                console.log("Payment is Successful\n" +
                    "razorpay_payment_id:", response.razorpay_payment_id,
                    "Status:", response.status,
                    "razorpay_order_id:", response.razorpay_order_id,
                    "razorpay_signature:", response.razorpay_signature);
                alert("Payment is Successful\n" +
                    "razorpay_payment_id: " + response.razorpay_payment_id +
                    "\nStatus: " + response.status +
                    "\nrazorpay_order_id: " + response.razorpay_order_id +
                    "\nrazorpay_signature: " + response.razorpay_signature);
            },
            prefill: {
                name: 'Customer Name',
                email: 'customer@example.com',
                contact: '8109833688'
            },
            theme: {
                color: '#3009cc'
            }
        };
        try {
            const apiUrl = `${WebApiUrl}/api/PaymentGateway/getRazorpayOptions`;
            const response = await axios.post(apiUrl, options, {
                withCredentials: true,
                credentials: 'include',
            });
            //debugger;
            console.log('Response:', response.data.data);
            return response.data.data;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

};

export default Payment;