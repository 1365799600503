import { useEffect, useState } from "react";
import Order from "../API/API_SERVISES/Order";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import OrderAPI from "../API/API_SERVISES/Order";
import Payment from "../API/API_SERVISES/Payment";
import Table from "../API/API_SERVISES/Table";
import Loader from "../Component/Loader";

const TrackOrderStatus = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const { orderNo } = location.state || {};
    //alert(orderNo);
    const [loading, setLoading] = useState(false);

    const [orderStatus, setOrderStatus] = useState([
        {
            id: 1,
            icon: "fa-solid fa-basket-shopping",
            statusName: "Your order is submitted in Restaurant",
            date: "",
            color: "green",
            paymentStatus: "Pending",
            display: "block",
        },
        {
            id: 2,
            icon: "fa-solid fa-square-check",
            statusName: "",
            date: "31/10/2023 13:10",
            color: "dimgrey",
            paymentStatus: "Pending",
            display: "block",
        },
        {
            id: 3,
            icon: "fa-solid fa-motorcycle",
            statusName: "Your Delivery Boy is on the way",
            date: "",
            color: "dimgrey",
            paymentStatus: "Pending",
            display: "none",
        },
        {
            id: 4,
            icon: "fa-solid fa-cake-candles",
            statusName: "Mothers are cooking your Food",
            date: "",
            color: "dimgrey",
            paymentStatus: "Pending",
            display: "block",
        },
        {
            id: 5,
            icon: "fa-solid fa-bag-shopping",
            statusName: "Your food has been delivered",
            date: "",
            color: "dimgrey",
            paymentStatus: "Pending",
            display: "block",
        },
    ]);
    const [orderedData, setOrderedData] = useState();

    useEffect(() => {
        let isMounted = true; // Flag to track whether the component is mounted

        const fetchData = async () => {
            setLoading(true);
            const data = localStorage.getItem("OrderDataToTrack");
            const parsedData = JSON.parse(data);

            if (parsedData === null && parsedData === undefined) {
                return <label>No Order to Track</label>
            }
            else {
                //const orderId = parsedData.orderNo;
                const filterData = [
                    {
                        fieldName: "orderNo",
                        operatorName: "Equal",
                        compareValue: orderNo,
                    },
                ];
                try {
                    const orderItemData = await Order.getOrderListFilter(filterData);
                    if (isMounted) {
                        console.log("orderItemData", orderItemData);
                        setOrderedData(orderItemData);

                        // *************** Change Status **********************
                        const orderDate = moment(orderItemData[0].orderDate).format('DD-MM-YYYY');

                        const updatedStatus = [...orderStatus];

                        if (orderItemData[0].orderDate !== null && orderItemData[0].orderDate !== "") {
                            updatedStatus[0] = {
                                ...updatedStatus[0],
                                color: "Green",
                                date: orderDate + " " + orderItemData[0].orderTime,
                            };
                        }

                        if (orderItemData[0].paymentStatus == "Done") {
                            updatedStatus[1] = {
                                ...updatedStatus[1],
                                color: "Green",
                                paymentStatus: orderItemData[0].paymentStatus,
                                date: orderDate + " " + orderItemData[0].orderTime
                            };
                            //alert(orderItemData[0].paymentStatus)
                        }
                        else {
                            updatedStatus[1] = {
                                ...updatedStatus[1],
                                date: orderDate + " " + orderItemData[0].orderTime
                            };
                        }

                        if (orderItemData[0].serviceTypeId === 752) {
                            updatedStatus[2] = {
                                ...updatedStatus[2],
                                display: "block"
                            };
                        }
                        setOrderStatus(updatedStatus);
                        console.log("updatedStatus", updatedStatus)
                    }
                } catch (error) {
                    console.error("Error fetching order item data:", error);
                }
            }
            setLoading(false);
        };
        fetchData();
        return () => {
            isMounted = false;
        };
    }, []);

    const [options, setOptions] = useState();

    const fetchPayOptions = async (rupee) => {
        try {
            const data = await Payment.getPaymentOptions(rupee);
            console.log("options1", data);
            setOptions(data);
            return data;
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handlePaymentBtn = async () => {
        const orderNo = orderedData[0].orderNo;
        const ord1Id = orderedData[0].ord1Id;
        navigate("/Request", {
            state: {
                orderNo,
                ord1Id
            }
        });

        // if (!window.Razorpay) {
        //     const script = document.createElement('script');
        //     script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        //     script.onload = () => {
        //         setIsRazorpayLoaded(true);
        //     };
        //     document.body.appendChild(script);
        // } else {
        //     setIsRazorpayLoaded(true);
        // }

        // if (!isRazorpayLoaded) {
        //     console.error('Razorpay SDK is not loaded yet.');
        //     return;
        // }

        // const amountToPay = orderedData[0].grandTotal - orderedData[0].paymentAmount;
        // const orderNo = orderedData[0].orderNo;

        // const data = await fetchPayOptions(amountToPay);

        // const options = {
        //     key: data.key,
        //     amount: data.amount,
        //     currency: data.currency,
        //     name: data.name,
        //     description: data.description,
        //     handler: function (response) {
        //         console.log(response)
        //         console.log("Payment is Successful\n" +
        //             "razorpay_payment_id:", response.razorpay_payment_id,
        //             "Order No:", orderNo,
        //             "Amount:", amountToPay);
        //         alert("Payment is Successful\n" +
        //             "razorpay_payment_id: " + response.razorpay_payment_id +
        //             "\nOrder No: " + orderNo +
        //             "\nAmount: " + amountToPay);
        //     },
        //     prefill: {
        //         name: 'Customer Name',
        //         email: 'customer@example.com',
        //         contact: '9039024004'
        //     },
        //     theme: {
        //         color: '#3009cc'
        //     }
        // };
        // console.log("options2", options);
        // const rzp1 = await new window.Razorpay(options);
        // rzp1.open();

        // *******************************
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString();

        const storedArrayString = localStorage.getItem('loginAccountUserData');
        const loginUser = JSON.parse(storedArrayString);

        const updatedValues = [
            {
                operationType: 0,
                path: "overallOrderStatus",
                op: "Add",
                from: "string",
                value: "Confirm"
            },
            {
                operationType: 0,
                path: "orderConfirmedBy",
                op: "Add",
                from: "string",
                value: 0
            },
            {
                operationType: 0,
                path: "orderConfirmedOn",
                op: "Add",
                from: "string",
                value: formattedDate
            },
            {
                operationType: 0,
                path: "paymentStatus",
                op: "Add",
                from: "string",
                value: "Confirm"
            },
            {
                operationType: 0,
                path: "paymentConfirmedBy",
                op: "Add",
                from: "string",
                value: 0
            },
            {
                operationType: 0,
                path: "paymentConfirmedOn",
                op: "Add",
                from: "string",
                value: formattedDate
            },
        ]
        await Order.updateOrderPartially(updatedValues, orderedData[0].ord1Id);
        //fetchData();
    }

    const [isRazorpayLoaded, setIsRazorpayLoaded] = useState(false);

    useEffect(() => {
    }, []);

    const CancelOrderBtn = async () => {
        //alert(orderedData[0].overallOrderStatus)
        //alert("Are you sure ? You want to cancel this order", "Yes", "No")

        if (window.confirm("Are you sure you want to cancel this order?")) {

            const filteredData = [{
                operationType: 0,
                path: "tableStatus",
                op: "Add",
                from: "string",
                value: "Free"
            }]
            const data = cancelOrder();
            if (data === true)
                if (orderedData[0].serviceTypeId === 751)
                    await Table.updateTablePartially(filteredData, orderedData[0].tableId);
        }
    }
    const [cancelBtn, setCancelBtn] = useState(true);

    const cancelOrder = async () => {
        if (orderedData[0].overallOrderStatus === "Pending") {
            const updatedValues = [
                {
                    operationType: 0,
                    path: "overallOrderStatus",
                    op: "Add",
                    from: "string",
                    value: "cancel"
                }
            ];
            const isSuccess = await OrderAPI.updateOrderPartially(updatedValues, orderedData[0].ord1Id);
            if (isSuccess)
                alert("Your order has been Cancelled");
            setCancelBtn(false);
            return true;
            //navigate("/MyAccountPage");
        }
        else if (orderedData[0].overallOrderStatus === "cancel") {
            alert("Your Order is already Cancelled");
            return false;
        }
        else {
            alert("Your Order is Confirm, Cannot Cancel this Order");
            return false;
        }
    }

    return (
        <div>
            <div className="heading">
                {/* <div className="head-display space-between">
                    <div className="space-evenly">
                        <Link to="/OrderTakerView">
                            <i className="fa-solid fa-arrow-left icon-white" style={{ cursor: "pointer" }}></i>
                        </Link>
                        <h2 className="head-title">Payment of (931703)</h2>
                    </div>
                    <label style={{color:"orange"}}>FEEDBACK</label>
                    <i className="fa-solid fa-star" style={{ color: "orange" }}></i>
                </div> */}
            </div>
            <div style={{ backgroundColor: "whitesmoke", height: "75%", marginBottom: "10px", marginTop: "0px" }}>
                <div className="flex-center">
                    <label className="ques">Track your Order Status</label><br />
                </div>
                <div className="mt-2 flex-center">
                    <img src="https://c8.alamy.com/comp/2GWXAFW/little-girl-tries-to-be-a-chef-serving-food-2GWXAFW.jpg" alt="DeliveryBoyImage" style={{ height: "100px", width: "100px" }}></img><br />
                </div>
                <div className="" style={{ marginLeft: "10px", display: "flex", flexDirection: "column", alignItems: "center" }}>

                    {/*eslint-disable-next-line array-callback-return*/}
                    {orderStatus.map((status, index) => (
                        <div style={{ display: status.display, width: "90%" }}>
                            <div className="flex-start" key={index} style={{ marginTop: "20px", }}>
                                <div className="iconDiv flex-center" style={{ backgroundColor: status.color, alignItems: "center" }}>
                                    <i className={status.icon} style={{}}></i>
                                </div>
                                <div className="statusDiv">
                                    <label className="ques">{status.statusName}</label>
                                    {(() => {
                                        if (index === 1) {
                                            return (
                                                <div>
                                                    <label className="ques">Your Order is {status.paymentStatus}</label><br />
                                                    {status.paymentStatus === "Pending" && (
                                                        <div>
                                                            <label className="ques"> to confirm order call 9039024004. </label><br />
                                                            <label className="ques"> ---- OR ---- </label><br />
                                                            <label className="ques"> Pay now to auto confirm this order</label><br />
                                                            <label className="ques"> Your Payment is pending <span className="ques" style={{ color: "red" }} disabled={!isRazorpayLoaded} onClick={() => handlePaymentBtn()}>Click Here</span> to pay Online</label>
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        }
                                        return null;
                                    })()}

                                    <label className="ques" style={{ color: "green", fontWeight: "bold", display: "flex" }}> {status.date}</label>
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className="mt-4" style={{ backgroundColor: "white", borderRadius: "5px", marginTop: "15px", marginRight: "10px", padding: "10px 10px", width: "90%", marginBottom: "20px" }}>
                        <div style={{ backgroundColor: "whitesmoke", padding: "10px 15px", marginBottom: "10px" }}>
                            <div className="flex-start">
                                <label className="ques">Order No : &nbsp;</label>
                                <label className="ans">{orderedData && orderedData.length > 0 && orderedData[0].orderNo}</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques">Order Date : &nbsp;</label>
                                <label className="ans">{orderedData && orderedData.length > 0 && moment(orderedData[0].orderDate).format("DD-MM-YYYY")}</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques">Service Type : &nbsp;</label>
                                <label className="ans">{orderedData && orderedData.length > 0 && orderedData[0].serviceTypeName}</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques">Delivery Time Slot : &nbsp;</label>
                                <label className="ans">{orderedData && orderedData.length > 0 && orderedData[0].deliveryTime}</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques">KDTT : &nbsp;</label>
                                <label className="ans">{orderedData && orderedData.length > 0 && orderedData[0].kotDispatchTargetTime}</label>
                            </div>
                            <div className="flex-start">
                                <label className="ques">Confirm Time : &nbsp;</label>
                                <label className="ans">
                                    {orderedData && orderedData.length > 0 &&
                                        moment(orderedData[0].orderConfirmedOn).format("DD-MM-YYYY hh:mm A")
                                    }
                                </label>
                            </div>
                        </div>
                        {(() => {
                            //if (parsedData !== null) {
                            //    return parsedData.map((cart, index) => (
                            //        <div>
                            //            <div className="row" style={{ margin: "5%", display: "flex", justifyContent: "space-evenly" }}>
                            //                <div className="col-sm-2 ">
                            //                    <img src={cart.imageSource} alt={cart.imageName} style={{ width: "70px", height: "70px", borderRadius: "5px" }}></img>
                            //                </div>
                            //                <div className="col-sm-6 ">
                            //                    <div className="row">
                            //                        <label><strong>{cart.imageName}</strong></label>
                            //                        <label>Qty: {cart.quantity} x Rs. {cart.price} /-</label>
                            //                    </div>
                            //                </div>
                            //                <div className="col-sm-3 " style={{ display: "flex", justifyContent: "end" }}>
                            //                    <h6>Rs <label >{cart.quantity * cart.price}</label> /-</h6>
                            //                </div>
                            //            </div>
                            //            <hr />
                            //        </div>
                            //    ))
                            //}
                        })()}
                        {/*<hr style={{ height: "3px", backgroundColor: "grey", opacity: "inherit" }} />*/}
                        {orderedData && orderedData.map((data, index) => (
                            <div key={index}>
                                <div className="space-between" style={{ padding: "5px", borderBottom: "1px solid #cecece" }}>
                                    <div className="flex-start" style={{ alignItems: "center" }}>
                                        <div className="">
                                            <img src="https://img.freepik.com/free-photo/top-view-baked-potatoes-with-minced-meat-greens-inside-plate-dark-blue-desk_140725-49961.jpg?size=626&ext=jpg&ga=GA1.1.1700460183.1712102400&semt=ais"
                                                alt="Butter Naan" style={{ width: "50px", height: "50px", borderRadius: "5px", marginTop: "8px" }}></img>
                                        </div>
                                        <div className="flex-start" style={{ marginLeft: "10px" }}>
                                            <div className="p-1" style={{ fontSize: "14px" }}>
                                                <label style={{}}>{data.d2ItemName}</label><br />
                                                <label>Qty: {data.qty} x Rs.{data.rate}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="ques">Rs.{data.qty * data.rate}</label>
                                </div>
                            </div>
                        ))}

                        <div style={{ borderRadius: "5px", backgroundColor: "#f9f9f9", display: "flex", justifyContent: "flex-end", alignItems: "flex-end", flexDirection: "column", padding: "10px", margin: "10px" }}>
                            <p style={{ margin: "0px" }}>Sub Amount: {orderedData && orderedData.length > 0 && orderedData[0].o1TotalAmt}</p>
                            <p style={{ margin: "0px" }}>Delivery Amount: 0.00</p>
                            <p style={{ margin: "0px" }}>Total Amount: {orderedData && orderedData.length > 0 && orderedData[0].o1TotalAmt}</p><br />
                        </div>

                        {(() => {
                            if (orderedData && orderedData.length > 0 && orderedData[0].serviceTypeId === 752)
                                return (
                                    <div style={{ borderRadius: "5px", backgroundColor: "#f9f9f9", display: "flex", justifyContent: "flex-start", alignItems: "flex-start", flexDirection: "column", padding: "10px", margin: "10px" }}>
                                        <p style={{ margin: "0px", display: orderedData && orderedData.length > 0 ? "none" : "block" }} >Deliveries on this trip</p>
                                        <p style={{ margin: "0px" }}>
                                            {orderedData && orderedData.length > 0 ? (
                                                orderedData[0].accAddress || ""
                                            ) : (
                                                ""
                                            )}
                                        </p>

                                    </div>
                                )
                        })()}
                        <div className="flex-center">
                            {cancelBtn ?
                                <button className="redBtn" onClick={CancelOrderBtn}>Cancel Order</button>
                                :
                                <label className="redBtn">Order has been Cancelled</label>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {loading && <Loader />}
        </div>
    );
};

export default TrackOrderStatus;
