import React, { useContext, useEffect, useState } from "react";
import MainModalComponent from "./MainModalComponent";
import MainModalComponent2 from "./MainModalComponent2";
import order from "../API/API_SERVISES/Order";
import Loader from "./Loader";
import MainModalComponent3 from "./MainModalComponent3";
import DeliveryAndTimeSlot from "../API/API_SERVISES/DeliveryAndTimeSlot";
import MyContext from "./MyContext";

const MainModal = (props) => {
  const [serviceType, setServiceType] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [selectedService, setSelectedService] = useState();
  const [AllottedTimeSlot, setAllottedTimeSlot] = useState([]);
  const { CompanyDetail, selectedService, setSelectedService, setOrderType } = useContext(MyContext);


  const [getDeliveryAndTimeSlotId, setDeliveryAndTimeSlotId] = useState({
    deliveryPointName: 0,
    orderType: 751,
    distanceTo: 0,
    distanceFrom: 0,
  });

  useEffect(() => {
    // console.log("Updated deliveryAndTimeSlotId:", getDeliveryAndTimeSlotId);
  }, [getDeliveryAndTimeSlotId]);

  // console.log(selectedService);
  useEffect(() => {
    const fetchdata = async () => {
      const data = await order.getServiceType();
      // console.log(data);
      setServiceType(data);
      // If you want to set the initial selectedService based on the first item, do it here
      setLoading(false)
      if (data.length > 0 && selectedService === undefined) {
        setSelectedService(`${data[0].id}`);
      }
      else if (selectedService === 752) {
        setSelectedService(752);
        // alert("else")
      }

      const updatedValues = {
        ...getDeliveryAndTimeSlotId,
        orderType: selectedService,
        deliveryPointName: 1,
      };
      getDeliveryTimeSlots(updatedValues)
    };
    fetchdata();
  }, []);
 
  const handleServiceChange = async (event) => {
    const newServiceId = event.target.id;
    console.log(typeof(newServiceId))
    setSelectedService(newServiceId);
    localStorage.setItem("ServiceType", newServiceId);
    if (newServiceId == 752) {
      setOrderType("Home delivery")
    } else if (newServiceId == 753) {
      setOrderType("Pick Up")
    } else if (newServiceId == 751) {
      setOrderType("Table Order")
    } else if (newServiceId == undefined) {
      setOrderType("")
      // setSelectedService()
    }

    setDeliveryAndTimeSlotId((prevState) => ({
      ...prevState,
      orderType: newServiceId,
      deliveryPointName: 1,
    }));

    const updatedValues = {
      ...getDeliveryAndTimeSlotId,
      orderType: newServiceId,
      deliveryPointName: 1,
    };
    getDeliveryTimeSlots(updatedValues)
    //console.log(getDeliveryAndTimeSlotId);
  };
  const getDeliveryTimeSlots =async(updatedValues)=>{
    try {
      setLoading(true);
      const data = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(updatedValues);
      console.log(data);

      if (data) {
        setAllottedTimeSlot(data);
        setLoading(false);
      } else {
        setLoading(false);
        setAllottedTimeSlot("");
        //alert("No data found");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching time slots:", error);
      // Handle the error appropriately, e.g., show an error message to the user
    }
  }

  const getServiceTypeId = () => {
    localStorage.setItem("ServiceType", selectedService);
    props.closemainmodal();
    props.fetchData()
  };
  console.log(selectedService)

  return (
    <div className="modal-back">
      <div className="main-modal-mothers-hut">
        <div className="xmark-ms-2" onClick={props.closemainmodal}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <i
          onClick={props.closemainmodal}
          style={{ color: "white", cursor: "pointer" }}
          className="fa-solid fa-xmark card-menu-favorite dis-non"
        ></i>
        <div className="main-modal-section-1">
          <div className="">Service Type</div>
          <div className="section-1-flex">
            {serviceType &&
              Array.isArray(serviceType) &&
              serviceType.length > 0 ? (
              serviceType
                .filter(type => {
                  return (
                    (type.name !== "Home Delivery" || CompanyDetail.isEnableHomeDelivery !== false) &&
                    (type.name !== "Pick up" || CompanyDetail.isEnablePickUp !== false) &&
                    (type.name !== "Table Order" || CompanyDetail.isEnableTblOrder !== false)
                  );
                })
                .map((type) => (
                  <div
                    key={type.id}
                    onClick={() =>
                      handleServiceChange({ target: { id: `${type.id}` } })
                    }
                  >
                    <input
                      type="radio"
                      name=""
                      id={`${type.id}`}
                      checked={selectedService == `${type.id}`}
                      onChange={handleServiceChange}
                    />
                    {type.name}
                  </div>
                ))
            ) : (
              <></>
            )}
          </div>


        </div>

        {serviceType.length > 0 &&
          selectedService == serviceType[1]?.id && (
            <MainModalComponent2
              heading1={"Delivery address"}
              heading2={"Delivered On"}
              loading={loading}
              setLoading={setLoading}
              AllottedTimeSlot={AllottedTimeSlot}
            />
          )}

        {serviceType.length > 1 &&
          selectedService == serviceType[2]?.id && (
            <MainModalComponent3
              heading1={"Pickup Location"}
              heading2={"Pick-up On"}
              loading={loading}
              setLoading={setLoading}
              AllottedTimeSlot={AllottedTimeSlot}
            />
          )}

        {serviceType.length > 2 &&
          selectedService == serviceType[0]?.id && (
            <MainModalComponent
              heading1={" Table Order Location"}
              heading2={"Book Table On"}
              loading={loading}
              setLoading={setLoading}
              AllottedTimeSlot={AllottedTimeSlot}
            />
          )}

        <button onClick={getServiceTypeId} className="main-modal-button-1">
          DONE
        </button>
      </div>
      {loading && <Loader />}{" "}
    </div>
  );
};

export default MainModal;
